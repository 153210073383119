/**
 * This module is responsible for importing the Google reCAPTCHA script.
 *
 * Different types of reCAPTCHA can be used on one page. In such cases,
 * multiple script versions need to be loaded. Various scripts are identified
 * by their public keys (reCAPTCHA Site Key).
 */

/**
 * @typedef reCAPTCHAScriptInfo
 * @property {STATUS} status
 * @property {Promise} loadingPromise
 * @property {string} callbackName
 */

/* global grecaptcha */

/**
 * Script loading status.
 */
export const STATUS = Object.freeze({
    MISSING: Symbol("no script"),
    LOADING: Symbol("loading"),
    LOADED: Symbol("loaded")
});

/**
 * Map that associates public keys with script information objects.
 * @type {Object<string, reCAPTCHAScriptInfo>}
 */
const scriptMap = Object.create(null);

/**
 * Counter for callback functions for scripts.
 * @type {number}
 */
let callbackCounter = 0;

/**
 * Adds a new script to the registry.
 * @param {string} siteKey
 * @returns {reCAPTCHAScriptInfo}
 */
export function registerScript(siteKey) {
    if (siteKey in scriptMap) {
        throw new Error(`reCAPTCHA script '${siteKey}' already registered.`);
    }

    const callbackName = `reCAPTCHA_callback_${callbackCounter++}`;
    const scriptInfo = {
        status: STATUS.MISSING,
        loadingPromise: null,
        callbackName: callbackName
    };

    if (callbackName in window) {
        console.warn(`Function '${callbackName}' already exists. It will be overwritten!`);
    }

    window[callbackName] = function () {
        scriptInfo.status = STATUS.LOADED;
        console.debug(`reCAPTCHA '${siteKey}': reCAPTCHA ready.`);

        document.dispatchEvent(
            new CustomEvent("recaptcha-ready", {
                detail: {
                    siteKey: siteKey
                }
            })
        );

        delete window[callbackName];
    };

    scriptMap[siteKey] = scriptInfo;
    return scriptInfo;
}

/**
 * Returns the global reCAPTCHA site key specified in the html tag attribute.
 * This key is used as the default key.
 * @returns {string}
 */
export function getGlobalSiteKey() {
    return document.documentElement.dataset.recaptchaKey;
}

/**
 * Gets information about the loading state of the script.
 * @param {string} siteKey
 * @returns {reCAPTCHAScriptInfo | undefined}
 */
export function getScriptInfo(siteKey) {
    if (siteKey in scriptMap) {
        return scriptMap[siteKey];
    }
}

/**
 * Adds the Google reCAPTCHA script to the page.
 * @param {string} siteKey
 * @returns {Promise}
 */
export function importScript(siteKey) {
    let scriptInfo = getScriptInfo(siteKey);
    if (!scriptInfo) {
        scriptInfo = registerScript(siteKey);
    }

    switch (scriptInfo.status) {
        case STATUS.MISSING:
            break;
        case STATUS.LOADING:
            return scriptInfo.loadingPromise;
        default:
            return new Promise(resolve => {
                queueMicrotask(() => {
                    resolve();
                });
            });
    }

    scriptInfo.status = STATUS.LOADING;
    console.debug(`reCAPTCHA '${siteKey}': Adding script...`);

    return (scriptInfo.loadingPromise = new Promise((resolve, reject) => {
        document.addEventListener("recaptcha-ready", event => {
            if (event.detail.siteKey === siteKey) {
                resolve();
            }
        });

        const script = document.createElement("script");
        script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}&onload=${scriptInfo.callbackName}`;
        script.async = true;
        script.onerror = () => {
            reject();
        };

        document.head.append(script);
        console.debug(`reCAPTCHA '${siteKey}': Script added.`);
    }));
}
