import { PopupBase } from "components/popup/classes.js";

/**
 * Компонент боковой панели.
 */
export class SidePanel extends PopupBase {
    get Defaults() {
        return Object.assign(super.Defaults, {
            // Максимальная ширина экрана, превысив которое, боковая панель закроется.
            breakpoint: 0
        });
    }

    constructor(root, options) {
        super(root, options);

        if (!this.root.id) {
            throw new Error("id attribute required");
        }

        this._addBreakpoint();
    }

    _addEventListeners() {
        super._addEventListeners();

        this.on(document, "click", event => {
            const target = event.target;
            if (target.closest(`[aria-controls="${this.root.id}"]`)) {
                this.toggle();
            }
        });
    }

    _addBreakpoint() {
        if (this.options.breakpoint) {
            const mql = window.matchMedia(`(min-width: ${this.options.breakpoint}px)`);
            mql.addEventListener("change", mql => {
                if (this.opened && mql.matches) {
                    this.close(true);
                }
            });
        }
    }

    _updateTriggers() {
        const toggles = document.querySelectorAll(`[aria-controls="${this.root.id}"]`);
        toggles.forEach(toggle => {
            toggle.setAttribute("aria-expanded", this.opened ? "true" : "false");
        });
    }

    _open() {
        super._open();
        this._updateTriggers();
    }

    _close() {
        super._close();
        this._updateTriggers();
    }
}
