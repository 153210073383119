import "./staff.pcss";

const staff = Array.from(document.querySelectorAll(".staff"));
if (staff.length) {
    import(/* webpackChunkName: "fancybox" */ "components/fancybox/fancybox.js").then(module => {
        module.Fancybox.bind('[data-fancybox^="staff-"]', {
            Carousel: {
                transition: "crossfade"
            }
        });
    });

    document.addEventListener("click", event => {
        if (event.target.closest(".staff__show-more")) {
            event.preventDefault();
            event.stopPropagation();
            const staff = event.target.closest(".staff");
            const description = staff.querySelector(".staff__description");
            description && description.classList.remove("staff__description--short");
        }
    });

    function checkStaffDescriptionHeight() {
        staff.forEach(element => {
            const description = element.querySelector(".staff__description");
            if (description.scrollHeight > 256) {
                queueMicrotask(() => {
                    description.classList.add("staff__description--short");
                });
            }
        });
    }

    if (window.innerWidth >= 1000) {
        checkStaffDescriptionHeight();
    } else {
        const matchMedia = window.matchMedia("(min-width: 1000px)");
        matchMedia.addEventListener(
            "change",
            event => {
                if (event.matches) {
                    checkStaffDescriptionHeight();
                }
            },
            { once: true }
        );
    }
}
