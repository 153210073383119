import Mustache from "mustache";
import XClass from "data-xclass";
import { debounce } from "lodash-es";
import { displayToast } from "components/toast/toast.js";
import { CheckoutForm } from "bem/common.forms/checkout-form/checkout-form.js";

import "./checkout-page.pcss";

const checkoutFormNode = document.getElementById("checkout-form");
const deliveryPreloader = checkoutFormNode && checkoutFormNode.querySelector(".checkout-form__delivery-preloader");
const emptyDeliveryMessage =
    checkoutFormNode && checkoutFormNode.querySelector(".checkout-form__empty-delivery-message");

let fetchShippingDataController = new AbortController();
let checkoutForm;

const checkoutPage = document.querySelector(".checkout-page");
if (checkoutPage) {
    setTimeout(() => {
        checkoutForm = CheckoutForm.getInstance(checkoutFormNode);
    });

    fetchSummaryData(checkoutPage.dataset.uuid).then(data => {
        renderSummary(data);
        disableCheckoutButton();
        showDeliveryPreloader();

        updateShippingData(true)
            .then(() => {
                enableCheckoutButton();
            })
            .catch(() => {});
    });

    updateShippingOnFormChange();

    // Обновление стоимости доставки в блоке Summary при выборе службы в списке.
    checkoutFormNode.addEventListener("change", event => {
        if (event.target.name !== "shipping-delivery") {
            return;
        }

        const deliveryFieldNode = XClass.findClosest(event.target, "radiobox-option");
        const summaryDataNode = deliveryFieldNode?.querySelector(".delivery-item__summary-data");
        if (!summaryDataNode) {
            return;
        }

        let summaryData;
        try {
            summaryData = JSON.parse(summaryDataNode.textContent);
        } catch (e) {
            return;
        }

        const summaryShippingCostNode = document.querySelector("[data-shipping-cost]");
        summaryShippingCostNode.textContent = summaryData.shipping_cost.repr;

        const summaryTaxAmountNode = document.querySelector("[data-tax-amount]");
        summaryTaxAmountNode.textContent = summaryData.tax_amount.repr;

        const summaryTotalPriceNode = document.querySelector("[data-total-price]");
        summaryTotalPriceNode.textContent = summaryData.total.repr;
    });
}

/**
 * Обновление блока доставки при изменении адреса полчателя.
 */
function updateShippingOnFormChange() {
    const fieldsToListen = ["shipping-city", "shipping-state", "shipping-zip_code"];

    // Флаг для предотвращения обновления блока доставки дважды для одного
    // и того же поля - в ответ на событие input и на событие change
    // при потере фокуса.
    let preventOnChangeOnField = "";

    const debouncedUpdateShippingData = debounce(fieldName => {
        preventOnChangeOnField = "";

        updateShippingData()
            .then(() => {
                preventOnChangeOnField = fieldName;
                enableCheckoutButton();
            })
            .catch(() => {});
    }, 500);

    checkoutFormNode.addEventListener("input", event => {
        const fieldName = event.target.name;
        if (fieldsToListen.includes(fieldName)) {
            disableCheckoutButton();
            showDeliveryPreloader();
            debouncedUpdateShippingData(fieldName);
        }
    });

    checkoutFormNode.addEventListener("change", event => {
        const fieldName = event.target.name;
        if (fieldsToListen.includes(fieldName) && preventOnChangeOnField !== fieldName) {
            disableCheckoutButton();
            showDeliveryPreloader();
            debouncedUpdateShippingData.cancel();

            preventOnChangeOnField = "";

            updateShippingData()
                .then(() => {
                    enableCheckoutButton();
                })
                .catch(() => {});
        }
    });
}

/**
 * Получение с сервера информации для блока Summary.
 *
 * @param {string} uuid
 * @return {Promise<Object>}
 */
export function fetchSummaryData(uuid) {
    const requestUrl = window.ajax_views.checkout.get_summary + "?uuid=" + uuid;

    disableCheckoutButton();
    return fetch(requestUrl, {})
        .then(response => {
            if (!response.ok) {
                throw new CheckoutError(response.statusText, response.status);
            }
            return response.json();
        })
        .then(data => {
            if (data.success !== true) {
                throw new CheckoutError(data.message);
            }
            return data;
        })
        .catch(exception => {
            displayToast({
                message: exception.message,
                code: exception.code
            });
        })
        .finally(() => {
            enableCheckoutButton();
        });
}

/**
 * Рендеринг блока Summary с указанными данными.
 *
 * @param {Object} data
 */
export function renderSummary(data) {
    const summaryTemplateNode = document.getElementById("checkout-summary-template");
    const checkoutSummary = render(summaryTemplateNode, data.summary);
    const summaryContainer = document.querySelector(".checkout-summary");
    if (summaryContainer) {
        summaryContainer.after(checkoutSummary);
        summaryContainer.remove();
    }
}

/**
 * Обновление блока с информацией о доставке.
 *
 * @param {boolean} [isInitial]
 * @return {Promise<void>}
 */
function updateShippingData(isInitial = false) {
    if (!checkoutForm) {
        return Promise.reject();
    }

    const country = "US";
    const stateField = checkoutForm.getField("shipping-state");
    const state = stateField && stateField.value;
    const cityField = checkoutForm.getField("shipping-city");
    const city = cityField && cityField.value;
    const postalCodeField = checkoutForm.getField("shipping-zip_code");
    const postalCode = postalCodeField && postalCodeField.value;

    // Город не обязателен для получения данных о доставке.
    if (country && state && postalCode && postalCode.length >= 5) {
        return fetchShippingData(checkoutPage.dataset.uuid, country, postalCode, state, city)
            .then(data => {
                renderDeliveryChoices(data);
                showDeliveryContainer();
                checkFirstDeliveryOption(isInitial);
            })
            .catch(exception => {
                if (exception.name === "AbortError") {
                    throw exception;
                }

                if (!(exception instanceof CheckoutError)) {
                    console.error(exception);
                }

                showDeliveryMessage();
                displayToast({
                    message: exception.message,
                    code: exception.code
                });
            });
    } else {
        showDeliveryMessage();
        return Promise.reject();
    }
}

/**
 * Получение с сервера информации о доставке.
 *
 * @param {string} uuid
 * @param {string} country
 * @param {string} postalCode
 * @param {string} state
 * @param {string} city
 * @return {Promise<unknown>}
 */
function fetchShippingData(uuid, country, postalCode, state, city) {
    fetchShippingDataController.abort();
    fetchShippingDataController = new AbortController();
    const requestUrl = window.ajax_views.shipping.list_rates;
    return fetch(requestUrl, {
        method: "POST",
        body: JSON.stringify({
            uuid: uuid,
            country: country,
            postal_code: postalCode,
            state: state,
            city: city
        }),
        signal: fetchShippingDataController.signal
    })
        .then(response => {
            if (!response.ok) {
                throw new CheckoutError(response.statusText, response.status);
            }
            return response.json();
        })
        .then(data => {
            if (data.success !== true) {
                throw new CheckoutError(data.message);
            }
            return data;
        });
}

/**
 * Отключение кнопки отправки формы в блоке Summary.
 */
function disableCheckoutButton() {
    const summaryButton = document.querySelector('[type="submit"][name="checkout"]');
    summaryButton && (summaryButton.disabled = true);
}

/**
 * Включение кнопки отправки формы в блоке Summary.
 */
function enableCheckoutButton() {
    const summaryButton = document.querySelector('[type="submit"][name="checkout"]');
    summaryButton && (summaryButton.disabled = false);
}

/**
 * Рендеринг опций доставки.
 *
 * @param {Object} data
 * @property {Object[]} data.rates
 */
function renderDeliveryChoices(data) {
    const deliveryList = document.querySelector(".delivery-field__list");
    const deliveryItemTemplateNode = document.getElementById("checkout-delivery-item-template");
    deliveryList.innerHTML = "";
    data.rates.forEach(rate => {
        const context = Object.assign({}, rate, {
            // Сериализация данных для блока Summary.
            summaryData: JSON.stringify({
                shipping_cost: rate.shipping_cost,
                tax_amount: rate.tax_amount,
                total: rate.total
            })
        });

        deliveryList.append(render(deliveryItemTemplateNode, context));
    });
}

/**
 * Выбор первой опции доставки из списка.
 * Если isInitial == true, то выбирается не первый попавшийся сервис,
 * а тот, который указан в атрибуте data-initial-delivery.
 *
 * @param {boolean} [isInitial]
 */
function checkFirstDeliveryOption(isInitial) {
    let optionToSelect;

    if (isInitial) {
        const deliveryCode = checkoutFormNode.dataset.initialDelivery;
        optionToSelect = deliveryCode && document.getElementById(deliveryCode);
    }

    if (!optionToSelect) {
        // Выбор первой попавшейся опции.
        optionToSelect = checkoutFormNode.querySelector('[name="shipping-delivery"]');
    }

    optionToSelect.checked = true;

    // Откладываем событие change, чтобы x-class успел инициализироваться.
    setTimeout(() => {
        optionToSelect.dispatchEvent(
            new CustomEvent("change", {
                cancelable: true,
                bubbles: true
            })
        );
    });
}

function showDeliveryPreloader() {
    deliveryPreloader.toggleAttribute("hidden", false);
    emptyDeliveryMessage.toggleAttribute("hidden", true);

    const deliveryList = document.querySelector(".delivery-field__list");
    deliveryList.innerHTML = "";
}

function showDeliveryContainer() {
    deliveryPreloader.toggleAttribute("hidden", true);
    emptyDeliveryMessage.toggleAttribute("hidden", true);
}

function showDeliveryMessage() {
    deliveryPreloader.toggleAttribute("hidden", true);
    emptyDeliveryMessage.toggleAttribute("hidden", false);

    const deliveryList = document.querySelector(".delivery-field__list");
    deliveryList.innerHTML = "";
}

/**
 * Вспомогательная функция для рендера шаблонов.
 *
 * @param {HTMLScriptElement} templateNode
 * @param {Object} context
 * @return {Element}
 */
function render(templateNode, context = {}) {
    const wrapper = document.createElement("div");
    wrapper.innerHTML = Mustache.render(templateNode.innerHTML, context).trim();
    return wrapper.firstElementChild;
}

class CheckoutError extends Error {
    constructor(message, code) {
        super(message);
        this.name = this.constructor.name;
        this.message = message;
        this.code = code;
    }
}
