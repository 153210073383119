import { BaseComponent } from "js/abstracts/baseComponent.js";

// Symbols representing different states of the UTMForm component
const STATUS = Object.freeze({
    FIELD_MISSING: Symbol("field missing"),
    FIELD_ADDED: Symbol("field added")
});

// UTM labels to check for in the URL
const UTM_LABELS = ["utm_source", "utm_medium", "utm_campaign", "utm_term", "utm_content"];

// Key for storing UTM parameters in localStorage
const STORAGE_KEY_NAME = "utmParams";

/**
 * Component that adds a hidden field with UTM tags to a form,
 * capturing the UTM parameters with which the user came to the site.
 */
export class UTMForm extends BaseComponent {
    // Symbol used for storing the form state
    static formStatusSymbol = Symbol("UTM");

    /**
     * Gets default options for the UTMForm component.
     * @returns {Object} - Object with default options.
     */
    get Defaults() {
        return {
            fieldName: "utm"
        };
    }

    /**
     * Gets the current state of the UTM form.
     * @returns {Symbol} - The UTM form state.
     */
    get status() {
        return this.formElement[this.constructor.formStatusSymbol] || STATUS.FIELD_MISSING;
    }

    /**
     * Sets the state of the UTM form.
     * @param {Symbol} value - The new state of the form.
     */
    set status(value) {
        this.formElement[this.constructor.formStatusSymbol] = value;
    }

    /**
     * Gets the field name for the UTM data from the data attribute or default options.
     * @returns {string} - The UTM field name.
     */
    get fieldName() {
        return this.formElement.getAttribute("data-utm-field-name") || this.options.fieldName;
    }

    /**
     * Constructor for the UTMForm class.
     * @param {HTMLFormElement} formElement - The HTML form element.
     * @param {Object} [options] - Additional options for the form.
     */
    constructor(formElement, options) {
        super(options);
        this.formElement = formElement;

        // Event listener for the form's submit event
        this.on(
            this.formElement,
            "submit",
            () => {
                console.debug("UTM: Captured 'submit' event.");

                switch (this.status) {
                    case STATUS.FIELD_ADDED:
                        console.debug("UTM: Field already exists. Continue...");
                        break;
                    default:
                        this.addField();
                        console.debug("UTM: Field has been added.");
                }
            },
            { capture: true }
        );
    }

    /**
     * Adds a field with UTM data to the form.
     */
    addField() {
        this._removeField();
        this._insertField(loadUTM());
        this.status = STATUS.FIELD_ADDED;
    }

    /**
     * Removes the field with UTM data from the form and resets the status.
     */
    removeField() {
        this._removeField();
        this.status = STATUS.FIELD_MISSING;
    }

    /**
     * Inserts a hidden field with JSON data into the form.
     * @param {Object} data - UTM data to be inserted.
     */
    _insertField(data) {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = this.fieldName;
        input.value = JSON.stringify(data);
        this.formElement.prepend(input);
    }

    /**
     * Removes the field with UTM data from the form.
     */
    _removeField() {
        const csrfField = this.formElement.querySelector(`input[name="${this.fieldName}"]`);
        csrfField && csrfField.remove();
    }
}

/**
 * Returns an object containing UTM labels found in the GET parameters
 * of the current page's URL.
 * @returns {Object} - Object with UTM parameters.
 */
function parseUTMParams() {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const params = new Proxy(urlSearchParams, {
        get: (searchParams, prop) => searchParams.get(prop)
    });

    const utmParams = {};
    UTM_LABELS.forEach(key => {
        const value = params[key];
        if (value !== null) {
            utmParams[key] = value;
        }
    });

    return utmParams;
}

/**
 * Saves UTM parameters to localStorage.
 */
export function saveUTM() {
    const utmParams = parseUTMParams();
    if (Object.keys(utmParams).length !== 0) {
        window.localStorage.setItem(STORAGE_KEY_NAME, JSON.stringify(utmParams));
    }
}

/**
 * Reads UTM parameters from localStorage.
 * @returns {Object} - Object with UTM parameters.
 */
export function loadUTM() {
    const utmParams = window.localStorage.getItem(STORAGE_KEY_NAME);
    try {
        return JSON.parse(utmParams || "{}");
    } catch {
        return {};
    }
}
