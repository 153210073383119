import "./share.pcss";

const APP_ID = "229798780479496";

function initFacebookSDK(container) {
    const wjs = document.getElementById("facebook-wjs");
    if (wjs) {
        return Promise.resolve();
    }

    // <div class="fb-root"> не обязателен.

    return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = `https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v18.0&appId=${APP_ID}`;
        script.id = "facebook-wjs";
        script.crossOrigin = "anonymous";
        script.onload = resolve;
        script.onerror = function () {
            reject(`Script not loaded: ${this.src}`);
        };
        container.append(script);
    }).catch(reason => {
        console.error(reason);
    });
}

function initTwitterSDK(container) {
    const wjs = document.getElementById("twitter-wjs");
    if (wjs) {
        return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
        const script = document.createElement("script");
        script.src = "https://platform.twitter.com/widgets.js";
        script.id = "twitter-wjs";
        script.crossOrigin = "anonymous";
        script.onload = resolve;
        script.onerror = function () {
            reject(`Script not loaded: ${this.src}`);
        };
        container.append(script);

        const api = window.twttr || {};
        api._e = [];
        api.ready = function (f) {
            api._e.push(f);
        };
        window.twttr = api;
    })
        .then(() => {
            const button = document.querySelector(".twitter-share-button");
            button.setAttribute("data-url", window.location.href);
        })
        .catch(reason => {
            console.error(reason);
        });
}

const shareRoot = document.querySelector(".share");
if (shareRoot) {
    initFacebookSDK(shareRoot);
    initTwitterSDK(shareRoot);
}
