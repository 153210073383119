import { BaseComponent } from "js/abstracts/baseComponent.js";
import { FormField } from "bem/common.fields/form-field/form-field.js";

/**
 * @typedef {Object} FormFieldRecord
 * @property {HTMLElement} element - The DOM element of the form field.
 * @property {FormField} instance - The instance of the FormField class associated with the field.
 * @property {string|null} name - The name of the form field.
 * @property {*} value - The current value of the form field.
 */

/**
 * Base class for forms.
 * Provides access to instances of form fields and triggers a custom
 * `form-reset` event on form fields when the form is reset using `form.reset()`.
 */
export class FormBase extends BaseComponent {
    /**
     * Get the instance of the form for the given element.
     * @param {HTMLFormElement} formElement - The HTML form element.
     * @returns {FormBase|null} - The instance of the FormBase class or null if not found.
     */
    static getInstance(formElement) {
        return formElement._formInstance || null;
    }

    /**
     * Default configuration options for the FormField class.
     *
     * @returns {Object} - Default configuration options.
     */
    get Defaults() {
        return {
            invalidClassName: "invalid",
            errorContainerSelector: ".form-errors"
        };
    }

    /**
     * FormBase constructor.
     * @param {HTMLFormElement} formElement - The HTML form element.
     * @param {Object} [options] - Options for initializing the form.
     */
    constructor(formElement, options) {
        super(options);
        this.formElement = formElement;

        // Attach a reference to the form instance to the form element.
        this.formElement._formInstance = this;

        // Update field states when the form is reset using a custom `form-reset` event.
        this.on(this.formElement, "reset", () => {
            setTimeout(() => {
                this.getFields().forEach(field => {
                    field.root.dispatchEvent(new CustomEvent("form-reset"));
                });
            });
        });
    }

    /**
     * Destroy the form instance.
     */
    destroy() {
        super.destroy();
        if (typeof this.formElement._formInstance !== "undefined") {
            this.formElement._formInstance = null;
        }
    }

    /**
     * Get the form action.
     * @returns {string} - The form action URL.
     */
    get action() {
        return this.formElement.action;
    }

    /**
     * Get the form method.
     * @returns {string} - The form method (GET or POST).
     */
    get method() {
        return this.formElement.method;
    }

    /**
     * Returns true if the form field is invalid.
     *
     * @returns {boolean} - True if the form field is invalid, false otherwise.
     */
    get invalid() {
        return this.formElement.classList.contains(this.options.invalidClassName);
    }

    /**
     * Sets the invalid status of the form field.
     *
     * @param {boolean} value - True to mark the form field as invalid, false otherwise.
     */
    set invalid(value) {
        this.formElement.classList.toggle(this.options.invalidClassName, Boolean(value));
    }

    /**
     * Gets an array of errors associated with the form.
     *
     * @returns {string[]} - An array of error messages.
     */
    get errors() {
        const errorContainer = this.formElement.querySelector(this.options.errorContainerSelector);
        if (!errorContainer) {
            return [];
        }

        const formErrors = errorContainer.querySelectorAll(".error-list__item");
        return Array.from(formErrors)
            .map(element => {
                return element.textContent.trim();
            })
            .filter(Boolean);
    }

    /**
     * Sets the errors for the form.
     *
     * @param {string|string[]|Object<message: string>[]} value - The error messages to set.
     */
    set errors(value) {
        let errors = [];

        if (typeof value === "string") {
            errors.push(value);
        } else if (Array.isArray(value)) {
            errors = errors.concat(value);
        } else {
            throw new Error("The value must be either an array of strings or an array of objects.");
        }

        // Convert `errors` to array of trimmed strings.
        errors = errors
            .map(item => {
                if (typeof item === "string") {
                    return item.trim();
                } else if (typeof item.message === "string") {
                    return item.message.trim();
                } else {
                    throw new Error(`Unsupported error format: ${item}`);
                }
            })
            .filter(Boolean);

        const errorContainer = this.formElement.querySelector(this.options.errorContainerSelector);
        if (errorContainer) {
            errorContainer.innerHTML = "";

            // Remove current errors.
            if (!errors.length) {
                return;
            }

            const fragment = document.createDocumentFragment();
            const errorList = document.createElement("ul");
            errorList.classList.add("error-list");
            fragment.appendChild(errorList);
            errors.forEach(
                /** string */ error => {
                    const errorItem = document.createElement("li");
                    errorItem.classList.add("error-list__item");
                    errorItem.textContent = error;
                    errorList.appendChild(errorItem);
                }
            );

            errorContainer.appendChild(errorList);
        }
    }

    /**
     * Get an array of FormField instances.
     *
     * Example:
     * form.getFields().forEach(field => {
     *    console.log(field.name, "=", field.value)
     * })
     *
     * @returns {FormField[]} - An array of FormField instances.
     */
    getFields() {
        const fieldRootNodes = this.formElement.querySelectorAll(".form-field");
        const fieldRootArray = Array.from(fieldRootNodes);
        return fieldRootArray
            .map(fieldNode => {
                return FormField.getInstance(fieldNode);
            })
            .filter(Boolean);
    }

    /**
     * Get a FormField instance by name.
     *
     * @param {string} name
     * @return {FormField|null}
     */
    getField(name) {
        const control = this.formElement.querySelector(`[name="${name}"]`);
        if (control) {
            const fieldRoot = control && control.closest(".form-field");
            return fieldRoot && FormField.getInstance(fieldRoot);
        } else {
            const fieldRoot = this.formElement.querySelector(`.form-field[data-field-name="${name}"]`);
            return fieldRoot && FormField.getInstance(fieldRoot);
        }
    }

    /**
     * Display form errors received from the server in the form of a JSON object.
     * The object keys are field names (or "__all__" for form-level errors).
     * Values are arrays of objects containing the error message in the `message` property.
     * Example:
     * {
     *     "__all__": [{message: "Form is not filled"}],
     *     "name": [{message: "Name required"}],
     *     "email": [{message: "Invalid email address"}]
     * }
     * @param {Object} errorObject - The object containing form errors.
     */
    displayErrors(errorObject) {
        // Form-level errors
        this.errors = errorObject["__all__"] || "";
        this.invalid = true;

        // Field-level errors
        this.getFields().forEach(field => {
            if (Object.hasOwn(errorObject, field.name)) {
                field.errors = errorObject[field.name];
                field.invalid = true;
            } else {
                field.errors = "";
                field.invalid = false;
            }
        });
    }

    /**
     * Hide all form errors.
     */
    hideErrors() {
        // Form-level errors
        this.errors = [];
        this.invalid = false;

        // Field-level errors
        this.getFields().forEach(field => {
            field.errors = [];
            field.invalid = false;
        });
    }
}
