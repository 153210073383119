import "./cart-counter.pcss";

// Имя ключа в localStorage, хранящего количество товаров в корзине.
const COUNTER_KEY = "cart-counter";

/**
 * Форматирует значение для хранения в localStorage.
 * Допустимым значением является неотрицательное число, либо его строковый
 * эквивалент. Возвращает строковое представление числа, либо null во всех
 * остальных случаях.
 *
 * @param {number|string} value
 * @return {null|string}
 */
function formatValue(value) {
    if (typeof value === "string") {
        value = parseInt(value);
    }

    if (typeof value === "number" && !isNaN(value)) {
        return Math.max(0, Math.floor(value)).toString();
    }

    return null;
}

/**
 * Возвращает текущее значение счётчика.
 *
 * @return {number}
 */
export function getValue() {
    let currentValue = localStorage.getItem(COUNTER_KEY);
    currentValue = formatValue(currentValue);
    if (currentValue === null) {
        return 0;
    } else {
        return parseInt(currentValue, 10);
    }
}

/**
 * Отображение текущего значения счётчика.
 */
export function displayCurrentValue() {
    const currentValue = getValue();
    if (currentValue === null || currentValue === 0) {
        document.querySelectorAll(".cart-counter__value").forEach(counter => {
            counter.innerText = "";
        });
    } else {
        document.querySelectorAll(".cart-counter__value").forEach(counter => {
            counter.innerText = currentValue >= 100 ? "99+" : currentValue;
        });
    }
}

/**
 * Установка значения счётчика товаров в корзине.
 *
 * @param {number|string|null} newValue
 */
export function setValue(newValue) {
    newValue = formatValue(newValue);
    if (newValue === null || newValue === "0") {
        localStorage.removeItem(COUNTER_KEY);
        displayCurrentValue();
        return;
    }

    localStorage.setItem(COUNTER_KEY, newValue);
    displayCurrentValue();
}

displayCurrentValue();

window.addEventListener("storage", event => {
    if (event.key !== COUNTER_KEY) {
        return;
    }
    displayCurrentValue();
});
