import "./photo-gallery-list.pcss";

const photoGallery = document.querySelectorAll(".photo-gallery-list");
if (photoGallery.length) {
    import(/* webpackChunkName: "fancybox" */ "components/fancybox/fancybox.js").then(module => {
        photoGallery.forEach(gallery => {
            const jsonId = gallery.dataset.jsonId;
            const jsonElement = document.getElementById(jsonId);

            let galleryData;
            try {
                galleryData = JSON.parse(jsonElement.textContent);
            } catch (e) {
                return;
            }

            gallery.addEventListener("click", event => {
                const currentLink = event.target.closest(".photo-gallery-list__link");
                if (currentLink) {
                    event.preventDefault();

                    const currentHref = currentLink.getAttribute("href");
                    new module.Fancybox(galleryData, {
                        startIndex: galleryData.findIndex(obj => obj.src === currentHref),
                        Carousel: {
                            transition: "crossfade"
                        }
                    });
                }
            });
        });
    });
}
