/*
 * Механизм избавления от необходимости кук для упрощения кэширования страниц
 * с Django-формами.
 *
 * Проблема:
 * 1) NGINX не кэширует страницы, в ответе которых есть заголовок Set-Cookie.
 *    А CSRF-модуль Django генерирует и отдаёт новую CSRF-куку при каждом обращении
 *    к странице, на которой есть тэг {% csrftoken %}.
 *
 * Решение:
 * 1) Убираем со страницы все тэги {% csrftoken %}.
 * 2) Будем вставлять <input> с CSRF-токеном в форму непосредственно перед отправкой.
 *    Для этого, при событии submit отправим AJAX-запрос на сервер, который
 *    одновременно установит пользователю CSRF-куку и отдаст в ответе CSRF-токен.
 *
 * Подводные камни:
 * 1) Из-за заголовка "Vary: Cookie" пользователь с уникальной кукой (такой как CSRF)
 *    будет генерировать в кэше персональные, предназначенные только для него, данные.
 *
 *    Решение:
 *    Можно удалять CSRF-куку в ответе на POST-запрос при УСПЕШНО заполненной форме.
 *
 *    Удалять куку при неверно заполненной форме НЕЛЬЗЯ, т.к. пользователь может
 *    нажать F5, тем самым снова отправив старый CSRF-токен на сервер. Итогом
 *    станет 403-я ошибка.
 *
 *    Таким образом, если пользователь успешно заполнил форму, он не сгенерирует
 *    ни одной мусорной страницы в кэше. Если же пользователь заполнил форму неверно,
 *    а затем решил её вообще не заполнять, то CSRF-кука останется в его браузере
 *    до тех пор, пока не протухнет, либо пока пользователь не заполнит любую другую
 *    форму на сайте, работающую по такому же принципу.
 *
 * 2) Удаление CSRF-куки при успешно заполненной форме приведёт к 403-й ошибке
 *    на формах в других вкладках, если для них ранее добавлялся CSRF-токен.
 *
 * Можно изменить имя добавляемого поля с помощью атрибута `data-csrf-field-name`:
 *  <form method="post" data-xclass="csrf-form" data-csrf-field-name="csrf-token">
 *      // ...
 *  </form>
 *
 * Т.к. CSRF-токены одноразовые, необходимо инвалидировать токен сразу после его
 * использования. По умолчанию это происходит автоматически при отправке формы.
 * Это поведение можно отключить, установив атрибут "data-csrf-no-invalidate".
 */

import Cookies from "js-cookie";
import XClass from "data-xclass";
import { CSRFForm } from "js/abstracts/forms/csrfForm.js";

/**
 * Удаление CSRF-куки.
 */
export function removeCookie(name = "csrftoken") {
    Cookies.remove(name);
}

/**
 * Аннулирование CSRF-токена.
 * Поскольку CSRF-токены одноразовые, необходимо аннулировать
 * токен сразу после его использования.
 */
export function reset(formElement) {
    if (formElement._csrfForm) {
        formElement._csrfForm.invalidateToken();
    }
}

XClass.register("csrf-form", {
    dependencies: ["replay-button"],
    init: function (element) {
        element._csrfForm = new CSRFForm(element, {
            url: window.ajax_views.csrf.token
        });
    },
    destroy: function (element) {
        if (element._csrfForm) {
            element._csrfForm.destroy();
            delete element._csrfForm;
        }
    }
});
