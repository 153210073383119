import XClass from "data-xclass";
import { FormField } from "bem/common.fields/form-field/form-field.js";

import "./recaptcha-form-field.pcss";

/**
 * Класс для поля формы с Google reCAPTCHA.
 */
export class ReCaptchaField extends FormField {
    get Defaults() {
        return Object.assign(super.Defaults, {
            controlSelector: "textarea"
        });
    }

    get value() {
        const control = this.root.querySelector(this.options.controlSelector);
        return control.value;
    }
}

// ===========================================================
//  Автоматическое создание экземпляров класса ReCaptchaField
// ===========================================================
XClass.register("recaptcha-field", {
    init: function (element) {
        new ReCaptchaField(element, {});
    },

    destroy: function (element) {
        const fieldInstance = FormField.getInstance(element);
        if (fieldInstance) {
            fieldInstance.destroy();
        }
    }
});
