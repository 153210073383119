import "./tabs.pcss";

function initTabs(root) {
    const tabs = root.querySelectorAll("[role='tab']");
    const tabList = root.querySelector("[role='tablist']");

    tabs.forEach(tab => {
        tab.addEventListener("click", changeTabs);
    });

    // Enable arrow navigation between tabs in the tab list
    let tabFocus = 0;

    tabList.addEventListener("keydown", e => {
        // Move right
        if (e.key === "ArrowRight" || e.key === "ArrowLeft") {
            tabs[tabFocus].setAttribute("tabindex", -1);
            if (e.key === "ArrowRight") {
                tabFocus++;
                // If we're at the end, go to the start
                if (tabFocus >= tabs.length) {
                    tabFocus = 0;
                }
                // Move left
            } else if (e.key === "ArrowLeft") {
                tabFocus--;
                // If we're at the start, move to the end
                if (tabFocus < 0) {
                    tabFocus = tabs.length - 1;
                }
            }

            tabs[tabFocus].setAttribute("tabindex", 0);
            tabs[tabFocus].focus();
        }
    });
}

function changeTabs(e) {
    e.preventDefault();
    e.stopPropagation();

    const target = e.target;
    const tabList = target.closest("[role='tablist']");
    const root = tabList && tabList.closest(".tabs");

    // Remove all current selected tabs
    tabList.querySelectorAll("[aria-selected='true']").forEach(t => t.setAttribute("aria-selected", "false"));

    // Set this tab as selected
    target.setAttribute("aria-selected", true);

    // Hide all tab panels
    root.querySelectorAll("[role='tabpanel']").forEach(p => p.setAttribute("hidden", "true"));

    // Show the selected panel
    root.querySelector(`#${target.getAttribute("aria-controls")}`).removeAttribute("hidden");
}

document.querySelectorAll(".tabs").forEach(initTabs);
