import { BaseComponent } from "js/abstracts/baseComponent.js";

import "./form-field.pcss";

/**
 * The `FormField` class is an abstract class that serves as a foundation
 * for implementing various form fields. It extends the `BaseComponent` class
 * and introduces common properties and methods for managing form fields.
 *
 * To use this class, you should extend it and implement the abstract methods
 * `name` and `value`. Additionally, you can customize the behavior by overriding
 * other methods or properties as needed.
 */
export class FormField extends BaseComponent {
    /**
     * Retrieves the instance of the field for a given element.
     *
     * @param {HTMLElement} element - The HTML element representing the form field.
     * @returns {FormField|null} - The instance of the form field or null if not found.
     */
    static getInstance(element) {
        return element._fieldInstance || null;
    }

    /**
     * Default configuration options for the FormField class.
     *
     * @returns {Object} - Default configuration options.
     */
    get Defaults() {
        return {
            disabledClassName: "disabled",
            requiredClassName: "required",
            invalidClassName: "invalid",
            errorContainerSelector: ".form-field__errors"
        };
    }

    /**
     * Constructor for the FormField class.
     *
     * @param {HTMLElement} element - The HTML element representing the form field.
     * @param {Object} options - Configuration options for the form field.
     */
    constructor(element, options) {
        super(options);

        this.root = element;
        if (!this.root.classList.contains("form-field")) {
            throw new Error(
                `${this.constructor.name} must have a CSS class "form-field": ${this.root.cloneNode().outerHTML}`
            );
        }

        this.root._fieldInstance = this;
    }

    /**
     * Destroys the form field instance.
     */
    destroy() {
        super.destroy();
        if (this.root._fieldInstance) {
            this.root._fieldInstance = null;
        }
    }

    /**
     * Abstract method to get the name of the field.
     *
     * @abstract
     * @returns {string} - The name of the form field.
     */
    get name() {
        return this.root.dataset.fieldName;
    }

    /**
     * Abstract method to get the value of the field.
     *
     * @abstract
     * @returns {string} - The value of the form field.
     */
    get value() {}

    /**
     * Returns true if the form field is disabled.
     *
     * @returns {boolean} - True if the form field is disabled, false otherwise.
     */
    get disabled() {
        return this.root.classList.contains(this.options.disabledClassName);
    }

    /**
     * Sets the disabled status of the form field.
     *
     * @param {boolean} value - True to disable the form field, false to enable it.
     */
    set disabled(value) {
        this.root.classList.toggle(this.options.disabledClassName, Boolean(value));
    }

    /**
     * Returns true if the form field is required.
     *
     * @returns {boolean} - True if the form field is required, false otherwise.
     */
    get required() {
        return this.root.classList.contains(this.options.requiredClassName);
    }

    /**
     * Sets the required status of the form field.
     *
     * @param {boolean} value - True to make the form field required, false otherwise.
     */
    set required(value) {
        this.root.classList.toggle(this.options.requiredClassName, Boolean(value));
    }

    /**
     * Returns true if the form field is invalid.
     *
     * @returns {boolean} - True if the form field is invalid, false otherwise.
     */
    get invalid() {
        return this.root.classList.contains(this.options.invalidClassName);
    }

    /**
     * Sets the invalid status of the form field.
     *
     * @param {boolean} value - True to mark the form field as invalid, false otherwise.
     */
    set invalid(value) {
        this.root.classList.toggle(this.options.invalidClassName, Boolean(value));
    }

    /**
     * Gets an array of errors associated with the form field.
     *
     * @returns {string[]} - An array of error messages.
     */
    get errors() {
        const fieldErrors = this.root.querySelectorAll(".error-list__item");
        return Array.from(fieldErrors)
            .map(element => {
                return element.textContent.trim();
            })
            .filter(Boolean);
    }

    /**
     * Sets the errors for the form field.
     *
     * @param {string|string[]|Object<message: string>[]} value - The error messages to set.
     */
    set errors(value) {
        /** @type {string[]|Object<message: string>[]} */
        let errors = [];

        if (typeof value === "string") {
            errors.push(value);
        } else if (Array.isArray(value)) {
            errors = errors.concat(value);
        } else {
            throw new Error("The value must be either an array of strings or an array of objects.");
        }

        // Convert `errors` to array of trimmed strings.
        errors = errors
            .map(item => {
                if (typeof item === "string") {
                    return item.trim();
                } else if (typeof item.message === "string") {
                    return item.message.trim();
                } else {
                    throw new Error(`Unsupported error format: ${item}`);
                }
            })
            .filter(Boolean);

        const errorContainer = this.root.querySelector(this.options.errorContainerSelector);
        if (errorContainer) {
            errorContainer.innerHTML = "";

            // Remove current errors.
            if (!errors.length) {
                return;
            }

            const fragment = document.createDocumentFragment();
            const errorList = document.createElement("ul");
            errorList.classList.add("error-list");
            fragment.appendChild(errorList);
            errors.forEach(
                /** string */ error => {
                    const errorItem = document.createElement("li");
                    errorItem.classList.add("error-list__item");
                    errorItem.textContent = error;
                    errorList.appendChild(errorItem);
                }
            );

            errorContainer.appendChild(errorList);
        }
    }
}
