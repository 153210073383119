import XClass from "data-xclass";
import { FieldOption } from "js/abstracts/fieldOption.js";

import "./radiobox-option.pcss";

class RadioboxOption extends FieldOption {}

// ===========================================================
//  Автоматическое создание экземпляров класса RadioboxOption
// ===========================================================
XClass.register("radiobox-option", {
    onRegister: function () {
        // наблюдатель за изменением атрибута disabled на <input> элементе
        this.observer = new MutationObserver(mutationsList => {
            for (const mutation of mutationsList) {
                if (mutation.type === "attributes") {
                    const widgetRoot = XClass.findClosest(mutation.target, "radiobox-option");
                    const optionInstance = widgetRoot && FieldOption.getInstance(widgetRoot);
                    if (optionInstance) {
                        switch (mutation.attributeName) {
                            case "disabled":
                                optionInstance._updateDisabledState();
                                break;
                            case "required":
                                optionInstance._updateRequiredState();
                                break;
                        }
                    }
                }
            }
        });
    },

    init: function (element) {
        const instance = new RadioboxOption(element, {
            controlSelector: ".radiobox-option__control",
            iconSelector: ".radiobox-option__icon",
            iconCheckedClassName: "radiobox--checked",
            iconDisabledClassName: "radiobox--disabled",
            disabledClassName: "radiobox-option--disabled",
            requiredClassName: "radiobox-option--required",
            invalidClassName: "radiobox-option--invalid"
        });

        // добавление <input> элемента в перечень отслеживания
        // изменений атрибутов disabled и required.
        this.observer.observe(instance.control, {
            attributes: true,
            attributeFilter: ["disabled", "required"]
        });
    },

    destroy: function (element) {
        const optionInstance = FieldOption.getInstance(element);
        if (optionInstance) {
            optionInstance.destroy();
        }
    }
});
