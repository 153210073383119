import Mustache from "mustache";
import Swal from "sweetalert2";
import { debounce } from "lodash-es";
import { CartStorage } from "components/cart/storage.js";
import { CouponStorage } from "components/coupons/storage.js";
import { NumericField } from "bem/common.fields/numeric-form-field/numeric-form-field.js";
import { displayToast } from "components/toast/toast.js";
import * as api from "components/cart/api.js";

import "./cart-page.pcss";

/**
 * @type {HTMLElement}
 */
let container;

const cartPage = document.querySelector(".cart-page");
if (cartPage) {
    container = document.getElementById("cart-container");
    if (!container) {
        throw new Error("Container not found.");
    }

    // Загрузка контента страницы корзины.
    if (document.startViewTransition) {
        document.startViewTransition(() => {
            validateCart().then(data => {
                if (data) {
                    displayCart(data);
                }
            });
        });
    } else {
        validateCart().then(data => {
            if (data) {
                displayCart(data);
            }
        });
    }

    // Клик на кнопку удаления товара.
    container.addEventListener("click", event => {
        const product = event.target.closest(".cart-product");
        const removeProductButton = event.target.closest(".cart-product__remove-button");
        if (product && removeProductButton) {
            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: `<span class="button__text">Yes, delete it!</span>`,
                reverseButtons: true,
                customClass: {
                    popup: "cart-confirmation-popup",
                    confirmButton: "button button--animate-skew",
                    cancelButton: "button button--outline"
                },
                buttonsStyling: false
            }).then(result => {
                if (result.isConfirmed) {
                    removeProduct(product);
                }
            });
        }
    });

    // Изменение количества товара.
    const updateCart = debounce(function () {
        validateCart()
            .then(data => {
                if (data) {
                    displayCart(data);
                }
            })
            .finally(() => {
                enableCheckoutButton();
            });
    }, 600);

    container.addEventListener("input", event => {
        if (!event.target.name.endsWith("-quantity")) {
            return;
        }

        const product = event.target.closest(".cart-product");
        const tokenField = product?.querySelector('[name$="-token"]');
        const token = tokenField?.value;
        const quantity = event.target.value;
        if (token && quantity) {
            const cart = new CartStorage();

            try {
                cart.setQuantity(token, quantity);
            } catch (e) {
                displayToast({
                    message: e.message
                });
                return;
            }

            cart.save();
            cart.syncWithCounter();

            disableCheckoutButton();
            updateCart();
        }
    });

    // Обновление контента страницы корзины,
    // если в ней что-то изменили на другой вкладке.
    window.addEventListener("storage", event => {
        if (event.key === CartStorage.KEY) {
            disableCheckoutButton();

            validateCart()
                .then(data => {
                    if (data) {
                        displayCart(data);
                    }
                })
                .finally(() => {
                    enableCheckoutButton();
                });
        }
    });

    // Применение/удаление промокода.
    document.addEventListener("coupon", () => {
        disableCheckoutButton();

        validateCart()
            .then(data => {
                if (data) {
                    displayCart(data);
                }
            })
            .finally(() => {
                enableCheckoutButton();
            });
    });

    // Показ ошибки, пришедшей из checkout.
    const url = new URL(window.location.href);
    const errorMessage = url.searchParams.get("error");
    if (errorMessage) {
        displayToast({
            message: errorMessage
        });

        url.searchParams.delete("error");
        history.replaceState(null, null, url.toString());
    }
}

let validateCartPromise = Promise.resolve();
let validateCartController = new AbortController();

/**
 * Валидация корзины на бэкенде.
 * Каждый новый вызов прерывает предыдущий.
 *
 * @return {Promise<Object>}
 */
function validateCart() {
    validateCartController.abort();

    window.queueMicrotask(() => {
        disableButtons();
    });

    validateCartController = new AbortController();
    validateCartPromise = api
        .fetchValidatedCart(validateCartController)
        .then(data => {
            // Удаление невалидных товаров.
            if (data?.invalidItems?.length) {
                const cart = new CartStorage();
                data.invalidItems.forEach(token => {
                    cart.removeByToken(token);
                });
                cart.save();
                cart.syncWithCounter();
            }

            // Удаление невалидного купона.
            if (!data?.couponCode) {
                const coupon = new CouponStorage();
                coupon.reset();
            }

            return data;
        })
        .catch(exception => {
            if (exception.name === "AbortError") {
                return;
            }

            displayToast({
                message: exception.message,
                code: exception.code
            });
        })
        .finally(() => {
            enableButtons();
        });

    return validateCartPromise;
}

/**
 * Отображение корзины в соответствии с данными,
 * полученными от бэкенда.
 *
 * @param {Object} data
 */
function displayCart(data) {
    if (!data.items?.length) {
        showEmptyCart();
    } else {
        showCart(data);
    }
}

/**
 * Показывает на странице корзины блок с ообщением
 * о том, что корзина пуста.
 */
function showEmptyCart() {
    container.innerHTML = "";
    const templateNode = document.getElementById("empty-cart-template");
    container.append(render(templateNode));
}

/**
 * Показывает на странице корзины товары и информацию о заказе.
 *
 * @param {Object} data
 */
function showCart(data) {
    container.innerHTML = "";

    const enabledItems = data.items.filter(item => item.enabled === true);
    const totalFormCount = enabledItems.length;

    const layoutTemplateNode = document.getElementById("cart-layout-template");
    const cartLayout = render(layoutTemplateNode, {
        totalFormCount,
        couponCode: data.couponCode
    });

    const productTemplateNode = document.getElementById("cart-product-template");
    data.items.forEach(item => {
        const context = Object.assign({}, item, {
            totalFormCount,
            maxQuantity: data.maxQuantity
        });
        const cartProduct = render(productTemplateNode, context);
        cartLayout.querySelector(".cart-product-list")?.append(cartProduct);
    });

    const summaryTemplateNode = document.getElementById("cart-summary-template");
    const cartSummary = render(summaryTemplateNode, data.summary);
    cartLayout.querySelector(".cart-page__summary")?.append(cartSummary);

    container.append(cartLayout);
}

/**
 * Удаление товара из корзины.
 *
 * @param {HTMLElement} product
 * @return {Promise<Object>}
 */
function removeProduct(product) {
    const tokenField = product?.querySelector('[name$="-token"]');
    let token = tokenField?.value;

    if (!token) {
        // У disabled-товаров токен в data-атрибуте.
        token = product.dataset.token;
    }

    if (!token) {
        // Если токен не найден, значит со страницей что-то не так.
        // Обновляем страницу.
        disableCheckoutButton();

        return validateCart()
            .then(data => {
                if (data) {
                    displayCart(data);
                }
            })
            .finally(() => {
                enableCheckoutButton();
            });
    }

    const cart = new CartStorage();
    cart.removeByToken(token);
    cart.save();

    disableCheckoutButton();
    return Promise.allSettled([
        new Promise(resolve => {
            product.classList.add("cart-product--removing");
            setTimeout(() => {
                product.remove();
                resolve();
            }, 400);
        }),
        validateCart()
    ]).then(([_, result]) => {
        if (result.status === "fulfilled") {
            if (result.value) {
                displayCart(result.value);
            }
        }

        enableCheckoutButton();
    });
}

/**
 * Отключение всех кнопок у продуктов.
 */
function disableButtons() {
    container.querySelectorAll(".cart-product").forEach(product => {
        const isEnabled = !product.classList.contains("cart-product--disabled");
        if (isEnabled) {
            const quantityField = product.querySelector(".cart-product__quantity");
            const quantityInstance = quantityField && NumericField.getInstance(quantityField);
            quantityInstance && (quantityInstance.disabled = true);
        }

        const removeButton = product.querySelector(".cart-product__remove-button");
        removeButton && removeButton.setAttribute("disabled", "");
    });
}

/**
 * Отключение кнопки Checkout.
 */
function disableCheckoutButton() {
    const checkoutButton = container.querySelector('[name="checkout"]');
    if (!checkoutButton) {
        return;
    }
    checkoutButton.setAttribute("disabled", "");
}

/**
 * Включение всех кнопок у продуктов.
 */
function enableButtons() {
    container.querySelectorAll(".cart-product").forEach(product => {
        const isEnabled = !product.classList.contains("cart-product--disabled");
        if (isEnabled) {
            const quantityField = product.querySelector(".cart-product__quantity");
            const quantityInstance = quantityField && NumericField.getInstance(quantityField);
            quantityInstance && (quantityInstance.disabled = true);
        }

        const removeButton = product.querySelector(".cart-product__remove-button");
        removeButton && removeButton.removeAttribute("disabled");
    });
}

/**
 * Включение кнопки Checkout.
 */
function enableCheckoutButton() {
    const checkoutButton = container.querySelector('[name="checkout"]');
    if (!checkoutButton) {
        return;
    }
    checkoutButton.removeAttribute("disabled");
}

/**
 * Вспомогательная функция для рендера шаблонов.
 *
 * @param {HTMLScriptElement} templateNode
 * @param {Object} context
 * @return {Element}
 */
function render(templateNode, context = {}) {
    const wrapper = document.createElement("div");
    wrapper.innerHTML = Mustache.render(templateNode.innerHTML, context).trim();
    return wrapper.firstElementChild;
}
