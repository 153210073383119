import "./guestbook.pcss";

const guestBookList = document.querySelector(".guestbook-list");
if (guestBookList) {
    import(/* webpackChunkName: "fancybox" */ "components/fancybox/fancybox.js").then(module => {
        module.Fancybox.bind('[data-fancybox="guestbook"]', {
            Carousel: {
                transition: "crossfade"
            }
        });
    });
}
