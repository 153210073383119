/**
 * Класс Draggable предоставляет функциональность для перемещения элемента
 * с использованием жестов мыши или сенсорного ввода. Он поддерживает
 * опциональное добавление инерции к движению элемента после завершения
 * жеста перемещения.
 *
 * Пример:
 *
 * class DraggableSquare extends Draggable {
 *     get left() {
 *         const transformMatch = /\(([-.\d]+)/.exec(this.root.style.transform);
 *         return transformMatch ? parseFloat(transformMatch[1]) : 0;
 *     }
 *
 *     set left(value) {
 *         const maxValue = document.documentElement.clientWidth - this.root.offsetWidth;
 *         value = Math.max(0, Math.min(value, maxValue));
 *         this.root.style.transform = `translate(${value}px, ${this.top}px)`;
 *     }
 *
 *     get top() {
 *         const transformMatch = /\([^,]+,\s*([-.\d]+)/.exec(this.root.style.transform);
 *         return transformMatch ? parseFloat(transformMatch[1]) : 0;
 *     }
 *
 *     set top(value) {
 *         const maxValue = document.documentElement.clientHeight - this.root.offsetHeight;
 *         value = Math.max(0, Math.min(value, maxValue));
 *         this.root.style.transform = `translate(${this.left}px, ${value}px)`;
 *     }
 *
 *     onDragStart() {
 *         super.onDragStart();
 *         this.initialLeft = this.left;
 *         this.initialTop = this.top;
 *     }
 *
 *     onDrag(dx, dy) {
 *         super.onDrag(dx, dy);
 *         this.left = this.initialLeft + dx;
 *         this.top = this.initialTop + dy;
 *     }
 * }
 *
 * const square = document.createElement("div");
 * square.style.position = "absolute";
 * square.style.zIndex = 1000;
 * square.style.width = "100px";
 * square.style.height = "100px";
 * square.style.background = "red";
 * document.body.prepend(square);
 * const dragger = new DraggableSquare(square);
 *
 * @class
 */

const browser = calcBrowser();
const focusableElements = "input, select, option, textarea, button, video, label";

export class Draggable {
    get Defaults() {
        return {
            // Величина смещения от начального положения,
            // в пределах которого элемент не должен двигаться.
            // Параметр необходим для того, чтобы различать сдвиг
            // от небрежного клика.
            threshold: 10,

            // Для добавления инерции к движению необходимо учесть
            // среднюю скорость сдвига. Этот параметр определяет,
            // что при расчете скорости будут использованы только
            // данные за последние maxHistoryTime миллисекунд.
            maxHistoryTime: 100,

            // Должен ли элемент продолжать движение по инерции
            // после завершения жеста.
            momentum: true,

            // Параметр, отвечающий за замедление движения по
            // инерции с течением времени. В физическом смысле равен
            // коэффициенту, который умножается на начальную скорость.
            // Результат вычисления используется в качестве ускорения,
            // направленного в противоположную движению сторону.
            momentumRatio: 1
        };
    }

    constructor(root, options) {
        this.root = root;
        this.options = Object.assign({}, this.Defaults, options);

        // Начальная координата курсора.
        // Движение в рамках options.threshold не учитывается.
        this._initialPosX = null;
        this._initialPosY = null;

        // Флаг, означающий, что курсор начал жест сдвига.
        // Этот флаг (в отличие от _dragStarted) учитывает движение
        // в рамках options.threshold.
        this._moveStarted = false;

        // Флаг, означающий, что сдвиг превысил options.threshold
        // и элемент начал движение.
        this._dragStarted = false;

        // Идентификатор касания, для которого мы считаем сдвиг.
        // Он необходим для события touchend.
        this._touchId = null;
        this._pointerId = null;

        // История перемещений курсора.
        this._movementHistory = [];

        // Флаг для блокировки события click на короткий период времени
        // после события pointerup. В противном случае, даже долгое
        // движение над ссылкой, после окончания движения, приведёт
        // к клику.
        this._allowClick = true;

        // Величина сдвига элемента на момент начала движения по инерции.
        this._momentumInitialDeltaX = null;
        this._momentumInitialDeltaY = null;

        // Время начала движения по инерции.
        this._momentumStartTime = null;

        // Начальная скорость при движении по инерции.
        this._momentumInitialSpeedX = null;
        this._momentumInitialSpeedY = null;

        // Замедление (обратное ускорение) движения по инерции.
        this._momentumDecelerationX = null;
        this._momentumDecelerationY = null;

        // Флаг, означающий, что проигрывается анимация движения по инерции.
        this._momentumPlaying = false;

        this.addEventListeners();
    }

    addEventListeners() {
        const onPointerDown = event => {
            let e = event;
            if (e.originalEvent) {
                e = e.originalEvent;
            }

            if (e.type === "pointerdown") {
                if (this._pointerId !== null && this._pointerId !== e.pointerId) {
                    return;
                }
                this._pointerId = e.pointerId;
            } else if (e.type === "touchstart" && e.targetTouches.length === 1) {
                this._touchId = e.targetTouches[0].identifier;
            }

            if (e.type === "touchstart") return;
            if ("which" in e && e.which === 3) return;
            if ("button" in e && e.button > 0) return;
            if (this._moveStarted) return;

            // Предотвращение перетаскивания ссылок и картинок.
            if (!e.target.matches(focusableElements)) {
                e.preventDefault();
            }

            if (
                document.activeElement &&
                document.activeElement.matches(focusableElements) &&
                document.activeElement !== e.target()
            ) {
                document.activeElement.blur();
            }

            this._initialPosX = e.pageX;
            this._initialPosY = e.pageY;
            this._moveStarted = true;
            this._allowClick = true;
            this.stopMomentum();
            this.onPointerDown();
        };

        const onPointerMove = event => {
            let e = event;
            if (e.originalEvent) {
                e = e.originalEvent;
            }

            // Пропускаем события, не относящиеся к процессу перемещения
            // текущего элемента.
            if (!this._moveStarted) return;

            let posX, posY;
            if (e.type === "pointermove") {
                if (this._touchId !== null) return;
                if (e.pointerId !== this._pointerId) return;

                posX = e.pageX;
                posY = e.pageY;
            } else if (e.type === "touchmove") {
                // Для touchmove ищем тот Touch, с которого началось перемещение.
                const targetTouch = Array.from(e.changedTouches).find(touch => {
                    return touch.identifier === this._touchId;
                });
                if (!targetTouch) return;

                posX = targetTouch.pageX;
                posY = targetTouch.pageY;
            }

            let dx = posX - this._initialPosX;
            let dy = posY - this._initialPosY;
            const distance = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));

            // Движение в пределах threshold ничего не делает.
            if (distance < this.options.threshold) return;

            // Как только threshold был превышен, мы корректируем
            // начальное положение курсора с учётом направления сдвига.
            // Величина сдвига также корректируется.
            if (!this._dragStarted) {
                if (this.canStartDrag(dx, dy) === false) {
                    this.abort();
                    return;
                }

                this._initialPosX = this._initialPosX + (this.options.threshold / distance) * dx;
                this._initialPosY = this._initialPosY + (this.options.threshold / distance) * dy;

                this._dragStarted = true;
                this._allowClick = false;
                dx = posX - this._initialPosX;
                dy = posY - this._initialPosY;
                this.onDragStart();
            }

            this._addMovementHistoryRecord(posX, posY);
            this.onDrag(dx, dy);
        };

        const onPointerUp = event => {
            let e = event;
            if (e.originalEvent) {
                e = e.originalEvent;
            }

            // Пропускаем события, не относящиеся к процессу перемещения
            // текущего элемента.
            if (!this._moveStarted) return;

            let targetTouch;
            if (e.type !== "touchend" && e.type !== "touchcancel") {
                if (this._touchId !== null) return;
                if (e.pointerId !== this._pointerId) return;

                targetTouch = e;
            } else {
                targetTouch = Array.from(e.changedTouches).find(touch => {
                    return touch.identifier === this._touchId;
                });
                if (!targetTouch) return;
            }

            if (["pointercancel", "pointerout", "pointerleave"].includes(e.type)) {
                const proceed = ["pointercancel"].includes(e.type) && (browser.isSafari || browser.isWebView);
                if (!proceed) {
                    return;
                }
            }

            const posX = targetTouch.pageX;
            const posY = targetTouch.pageY;
            this._addMovementHistoryRecord(posX, posY);
            this.onDragEnd();

            if (this.options.momentum) {
                const dx = posX - this._initialPosX;
                const dy = posY - this._initialPosY;
                const [speedX, speedY] = this._calculateSpeed();
                this.startMomentum({
                    initialDeltaX: dx,
                    initialDeltaY: dy,
                    initialSpeedX: speedX,
                    initialSpeedY: speedY
                });
            }

            this.abort();
        };

        this.root.addEventListener("pointerdown", onPointerDown, { passive: false });
        this.root.addEventListener("pointermove", onPointerMove, { passive: false });
        this.root.addEventListener("pointerup", onPointerUp, { passive: true });
        this.root.addEventListener("pointercancel", onPointerUp, { passive: true });
        this.root.addEventListener("pointerout", onPointerUp, { passive: true });
        this.root.addEventListener("pointerleave", onPointerUp, { passive: true });

        this.root.addEventListener("touchstart", onPointerDown, { passive: false });
        this.root.addEventListener("touchmove", onPointerMove, { passive: false });
        this.root.addEventListener("touchend", onPointerUp, { passive: true });
        this.root.addEventListener("touchcancel", onPointerUp, { passive: true });

        this.root.addEventListener("click", event => {
            if (!this._allowClick) {
                event.preventDefault();
                event.stopPropagation();
                event.stopImmediatePropagation();
            }
        });
    }

    /**
     * Проверка, вызываемя перед началом перемещения элемента.
     * Если вернёт false, весь жест перемещения будет отменён.
     *
     * Применяется для создания горизонтальных слайдеров.
     * В этом случае метод должен вернуть false, если был
     * зафиксирован сдвиг по вертикали, чтобы браузер произвёл
     * стандартный скролл страницы.
     *
     * @param {number} dx
     * @param {number} dy
     */
    canStartDrag(dx, dy) {
        return true;
    }

    /**
     * Прерывание жеста перемещения.
     * Метод не прерывает начатое движение по инерции.
     */
    abort() {
        this._initialPosX = null;
        this._initialPosY = null;
        this._moveStarted = false;
        this._dragStarted = false;
        this._touchId = null;
        this._pointerId = null;
        this._movementHistory.length = 0;

        // Разрешаем клик после обработки текущих событий
        setTimeout(() => {
            this._allowClick = true;
        });
    }

    /**
     * Начало движения по инерции.
     *
     * @param {number} initialDeltaX
     * @param {number} initialDeltaY
     * @param {number} initialSpeedX
     * @param {number} initialSpeedY
     * @private
     */
    startMomentum({ initialDeltaX, initialDeltaY, initialSpeedX, initialSpeedY }) {
        this._momentumStartTime = performance.now();
        this._momentumInitialDeltaX = initialDeltaX;
        this._momentumInitialDeltaY = initialDeltaY;
        this._momentumInitialSpeedX = initialSpeedX;
        this._momentumInitialSpeedY = initialSpeedY;
        this._momentumDecelerationX = -this._momentumInitialSpeedX * this.options.momentumRatio;
        this._momentumDecelerationY = -this._momentumInitialSpeedY * this.options.momentumRatio;
        this._momentumPlaying = true;

        // console.log(`Momentum initial offset: ${initialDeltaX}px`);
        // console.log(`Momentum initial speedX: ${this._momentumInitialSpeedX}px per second`);
        // console.log(`Momentum initial speedY: ${this._momentumInitialSpeedY}px per second`);
        // console.log(`Momentum decelerationX: ${this._momentumDecelerationX}`);
        // console.log(`Momentum decelerationY: ${this._momentumDecelerationY}`);

        this.onStartMomentum();
        window.requestAnimationFrame(() => {
            this._callMomentumFrame();
        });
    }

    /**
     * Прерывание движения по инерции.
     * Может применяться при клике на элемент или при достижении
     * максимальной границы перемещения.
     */
    stopMomentum() {
        this._momentumPlaying = false;
        this.onStopMomentum();
    }

    /**
     * Обработчик события клика на элементе
     */
    onPointerDown() {}

    /**
     * Обработчик события начала движения элемента.
     */
    onDragStart() {}

    /**
     * Обработчик события движения элемента.
     *
     * @param {number} dx - величина сдвига по оси X от началоного положения.
     * @param {number} dy- величина сдвига по оси Y от началоного положения.
     */
    onDrag(dx, dy) {}

    /**
     * Обработчик события завершения движения элемента.
     */
    onDragEnd() {}

    /**
     * Обработчик события начала движения по инерции.
     */
    onStartMomentum() {}

    /**
     * Обработчик события прекращения движения по инерции.
     */
    onStopMomentum() {}

    /**
     * Добавление записи в историю перемещений курсора.
     *
     * @param {number} posX
     * @param {number} posY
     * @private
     */
    _addMovementHistoryRecord(posX, posY) {
        const currentTime = performance.now();

        // Удаляем из истории все записи, которые старше maxHistoryTime.
        this._movementHistory = this._movementHistory.filter(entry => {
            return currentTime - entry.timestamp <= this.options.maxHistoryTime;
        });

        this._movementHistory.push({
            x: posX,
            y: posY,
            timestamp: currentTime
        });
    }

    /**
     * Рассчет средней скорости перемещения элемента
     * для движения по инерции.
     *
     * @return {number[]}
     * @private
     */
    _calculateSpeed() {
        const movementHistory = this._filterMovementHistory();
        if (movementHistory.length <= 1) {
            return [0, 0];
        }

        const firstRecord = movementHistory[0];
        const lastRecord = movementHistory[movementHistory.length - 1];
        const deltaTime = lastRecord.timestamp - firstRecord.timestamp;
        return [
            deltaTime > 0 ? ((lastRecord.x - firstRecord.x) / deltaTime) * 1000 : 0,
            deltaTime > 0 ? ((lastRecord.y - firstRecord.y) / deltaTime) * 1000 : 0
        ];
    }

    /**
     * Фильтрация истории движений курсора, оставляющая
     * записи только о последнем направлении движения.
     *
     * @return {Object[]}
     * @private
     */
    _filterMovementHistory() {
        if (this._movementHistory.length <= 1) {
            return this._movementHistory;
        }

        const lastRecord = this._movementHistory[this._movementHistory.length - 1];
        const secondToLastRecord = this._movementHistory[this._movementHistory.length - 2];
        let directionX = Math.sign(lastRecord.x - secondToLastRecord.x);
        let directionY = Math.sign(lastRecord.y - secondToLastRecord.y);

        const movementHistory = [secondToLastRecord, lastRecord];
        for (let i = this._movementHistory.length - 3; i >= 0; i--) {
            const nextRecord = this._movementHistory[i + 1];
            const currentRecord = this._movementHistory[i];

            const deltaX = Math.sign(nextRecord.x - currentRecord.x);
            const deltaY = Math.sign(nextRecord.y - currentRecord.y);
            directionX = directionX || deltaX;
            directionY = directionY || deltaY;

            if ((directionX && deltaX && directionX !== deltaX) || (directionY && deltaY && directionY !== deltaY)) {
                break;
            }

            movementHistory.unshift(currentRecord);
        }

        return movementHistory;
    }

    _callMomentumFrame() {
        if (!this._momentumPlaying) {
            return;
        }

        const currentTime = performance.now();
        const deltaTime = (currentTime - this._momentumStartTime) / 1000;

        const momentumDuration = Math.abs(this._momentumInitialSpeedX) / Math.abs(this._momentumDecelerationX);
        const boundedDeltaTime = Math.min(deltaTime, momentumDuration);

        const deltaX =
            this._momentumInitialSpeedX * boundedDeltaTime +
            (this._momentumDecelerationX * boundedDeltaTime * boundedDeltaTime) / 2;
        const deltaY =
            this._momentumInitialSpeedY * boundedDeltaTime +
            (this._momentumDecelerationY * boundedDeltaTime * boundedDeltaTime) / 2;

        this.onDrag(this._momentumInitialDeltaX + deltaX, this._momentumInitialDeltaY + deltaY);

        if (deltaTime < momentumDuration) {
            window.requestAnimationFrame(() => {
                this._callMomentumFrame();
            });
        } else {
            this.stopMomentum();
        }
    }
}

/**
 * @see https://github.com/nolimits4web/swiper/blob/master/src/shared/get-browser.mjs#L8
 * @return {{isWebView: boolean, isSafari: *}}
 */
function calcBrowser() {
    let needPerspectiveFix = false;

    function isSafari() {
        const ua = window.navigator.userAgent.toLowerCase();
        return ua.indexOf("safari") >= 0 && ua.indexOf("chrome") < 0 && ua.indexOf("android") < 0;
    }

    if (isSafari()) {
        const ua = String(window.navigator.userAgent);
        if (ua.includes("Version/")) {
            const [major, minor] = ua
                .split("Version/")[1]
                .split(" ")[0]
                .split(".")
                .map(num => Number(num));
            needPerspectiveFix = major < 16 || (major === 16 && minor < 2);
        }
    }
    return {
        isSafari: needPerspectiveFix || isSafari(),
        isWebView: /(iPhone|iPod|iPad).*AppleWebKit(?!.*Safari)/i.test(window.navigator.userAgent)
    };
}
