/*
 * Захват клавиатурной навигации с целью её ограничения заданной областью.
 * Применяется для модальных окон и прочих попапов.
 */

import subscribe from "subscribe-event";

const instances = [];

/**
 * @example
 *   let hook = new TabHook(element);
 *   ...
 *   hook.destroy();
 */
class TabHook {
    constructor(root) {
        this.root = root;
        if (!this.root) {
            throw new Error("root element not found");
        }

        this.unsubscribe = subscribe(this.root, "keydown", this.onKeyDown.bind(this));
        this.init();

        instances.push(this);
        this.enable();
    }

    enable() {
        instances.forEach(obj => {
            obj.enabled = obj === this;
        });
    }

    onKeyDown(event) {
        if (!this.enabled) {
            return;
        }

        if (event.key === "Tab") {
            if (event.shiftKey) {
                // SHIFT + TAB
                if (document.activeElement === this._firstTabStop) {
                    event.preventDefault();
                    this._lastTabStop.focus();
                }
            } else {
                // TAB
                if (document.activeElement === this._lastTabStop) {
                    event.preventDefault();
                    this._firstTabStop.focus();
                }
            }
        }
    }

    init() {
        this.focusedBefore = document.activeElement;
        const focusableElements = Array.from(
            this.root.querySelectorAll(`
            a[href],
            area[href],
            input:not([disabled]):not([type="hidden"]):not([aria-hidden]),
            select:not([disabled]):not([aria-hidden]),
            textarea:not([disabled]):not([aria-hidden]),
            button:not([disabled]):not([aria-hidden]),
            iframe,
            object,
            embed,
            [contenteditable],
            [tabindex]:not([tabindex^="-"])`)
        );

        this._firstTabStop = focusableElements[0];
        this._lastTabStop = focusableElements[focusableElements.length - 1];

        if (this._firstTabStop) {
            this._firstTabStop.focus();
        }
    }

    destroy() {
        this.focusedBefore && this.focusedBefore.focus();
        this.unsubscribe();

        this.root = null;
        this.focusedBefore = null;
        this._firstTabStop = null;
        this._lastTabStop = null;

        instances.splice(instances.indexOf(this), 1);
        if (instances.length) {
            instances[instances.length - 1].enable();
        }
    }
}

export default TabHook;
