import deepmerge from "deepmerge";
import subscribe from "subscribe-event";
import { isPlainObject } from "is-plain-object";

/**
 * BaseComponent class.
 *
 * This class provides a foundation for components with the following features:
 * 1) Allows specifying default component options and merging them
 *    with the options provided during initialization.
 * 2) Facilitates attaching event handlers to DOM elements, automatically removing them
 *    when the `destroy()` method is called.
 */
export class BaseComponent {
    /**
     * Get the default options for the component.
     * @returns {Object} - Default options object.
     */
    get Defaults() {
        return {};
    }

    /**
     * Constructor for the BaseComponent class.
     * @param {Object} [options] - Options to initialize the component with.
     */
    constructor(options) {
        this.options = deepmerge(this.Defaults, options || {}, {
            arrayMerge: (destinationArray, sourceArray) => sourceArray,
            isMergeableObject: isPlainObject
        });
        this._events = new Map();
    }

    /**
     * Destroy the component, cleaning up event handlers.
     */
    destroy() {
        for (const handleObjects of this._events.values()) {
            handleObjects.forEach(handleObj => {
                handleObj.unsubscribeFunc();
            });
        }
        this._events.clear();
    }

    /**
     * Attach an event handler to a DOM element.
     * Event handlers added using this method are automatically removed
     * when the component instance is destroyed.
     * @param {HTMLElement|Document} element - DOM element.
     * @param {string|Symbol|Array.<string|Symbol>} event - Event name or an array of event names.
     * @param {Function} handler - Event handler function.
     * @param {Object} [options] - Event handler options.
     * @returns {BaseComponent} - The current BaseComponent instance.
     */
    on(element, event, handler, options) {
        if (typeof event === "symbol") {
            this._bindEvent(element, event, handler, options);
            return this;
        }

        if (typeof event === "string") {
            event = event.split(/[,\s]+/);
        }

        event.forEach(eventName => {
            this._bindEvent(element, eventName, handler, options);
        });

        return this;
    }

    /**
     * Bind an event to a DOM element.
     * @param {HTMLElement|Document} element - DOM element.
     * @param {string|Symbol} event - Event name.
     * @param {Function} handler - Event handler function.
     * @param {Object} [options] - Event handler options.
     * @private
     */
    _bindEvent(element, event, handler, options) {
        const handleObj = {
            event: event,
            handler: handler,
            unsubscribeFunc: subscribe(element, event, handler, options),
            capture: options?.capture || false,
            passive: options?.passive || false,
            once: options?.once || false
        };

        let handleObjects = this._events.get(element);
        if (typeof handleObjects === "undefined") {
            handleObjects = [];
            this._events.set(element, handleObjects);
        }
        handleObjects.push(handleObj);
    }

    /**
     * Remove an event handler from a DOM element.
     * Handlers with modifiers (capture / passive / once) are only removed
     * if the exact modifiers are provided.
     * @param {HTMLElement|Document} element - DOM element.
     * @param {string|Symbol|Array.<string|Symbol>} event - Event name or an array of event names.
     * @param {Function} [handler] - Event handler function.
     * @param {Object} [options] - Event handler options.
     */
    off(element, event, handler, options) {
        if (typeof event === "symbol") {
            this._unbindEvent(element, event, handler, options);
            return this;
        }

        if (typeof event === "string") {
            event = event.split(/[,\s]+/);
        }

        event.forEach(eventName => {
            this._unbindEvent(element, eventName, handler, options);
        });

        return this;
    }

    /**
     * Unbind an event from a DOM element.
     * @param {HTMLElement|Document} element - DOM element.
     * @param {string|Symbol} event - Event name.
     * @param {Function} [handler] - Event handler function.
     * @param {Object} [options] - Event handler options.
     * @private
     */
    _unbindEvent(element, event, handler, options) {
        const handlerObjects = this._events.get(element);
        if (!Array.isArray(handlerObjects)) {
            return;
        }

        if (typeof handler === "object" && typeof options === "undefined") {
            options = handler;
            handler = undefined;
        }

        let index = handlerObjects.length;
        while (index--) {
            const handleObj = handlerObjects[index];
            if (
                handleObj.event === event &&
                (!handler || handleObj.handler === handler) &&
                handleObj.capture === !!options?.capture &&
                handleObj.passive === !!options?.passive &&
                handleObj.once === !!options?.once
            ) {
                const handleObj = handlerObjects.splice(index, 1)[0];
                handleObj.unsubscribeFunc && handleObj.unsubscribeFunc();
            }
        }
    }
}
