import "./map-block.pcss";

const MAP_THEMES = {
    "spring-n-summer": "9eeec3f4c43e26be",
    "autumn": "5b07fdd4f972397f",
    "winter": "74c6fa1571306fde"
};
let theme = document.documentElement.dataset.theme;
if (!MAP_THEMES.hasOwnProperty(theme)) {
    theme = "spring-n-summer";
}

function loadScript(apikey) {
    return new Promise(resolve => {
        // prettier-ignore
        (g=>{
            var h,a,k,p="The Google Maps JavaScript API",c="google",l="importLibrary",q="__ib__",m=document,b=window;b=b[c]||(b[c]={});
            var d=b.maps||(b.maps={}),r=new Set,e=new URLSearchParams,u=()=>h||(h=new Promise(async(f,n)=>{await (a=m.createElement("script"));e.set("libraries",[...r]+"");for(k in g)e.set(k.replace(/[A-Z]/g,t=>"_"+t[0].toLowerCase()),g[k]);e.set("callback",c+".maps."+q);a.src=`https://maps.${c}apis.com/maps/api/js?`+e;d[q]=f;a.onerror=()=>h=n(Error(p+" could not load."));a.nonce=m.querySelector("script[nonce]")?.nonce||"";m.head.append(a)}));
            if(d[l]){console.warn(p+" only loads once. Ignoring:",g);resolve()}else{d[l]=(f,...n)=>r.add(f)&&u().then(()=>d[l](f,...n));resolve()}
        })({
            key: apikey,
            v: "weekly"
            // Use the 'v' parameter to indicate the version to use (weekly, beta, alpha, etc.).
            // Add other bootstrap parameters as needed, using camel case.
        });
    });
}

/**
 * @param {HTMLElement} block
 * @returns {Promise<void>}
 */
async function initMap(block) {
    const { Map } = await google.maps.importLibrary("maps");
    const { LatLngBounds } = await google.maps.importLibrary("core");

    const mapElement = block.querySelector(".map-block__map");
    const map = new Map(mapElement, {
        center: {
            lat: 38.8976763,
            lng: -77.0365298
        },
        zoom: 17,
        mapId: MAP_THEMES[theme],
        mapTypeControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false
    });

    const markersElement = block.querySelector(".map-block__markers");
    const markersArray = JSON.parse(markersElement.textContent);
    const markers = await addMarkers(map, markersArray);

    if (markers.length === 1) {
        map.setCenter(markers[0].getPosition());
    } else if (markers.length > 1) {
        const bounds = new LatLngBounds();
        markers.forEach(marker => {
            bounds.extend(marker.getPosition());
        });
        map.fitBounds(bounds);
    }
}

/**
 * @param {Object} map
 * @param {Object[]} markersArray
 * @returns {Promise<Object[]>}
 */
async function addMarkers(map, markersArray) {
    const { InfoWindow } = await google.maps.importLibrary("maps");
    const { Point } = await google.maps.importLibrary("core");
    const { Marker } = await google.maps.importLibrary("marker");

    return markersArray.map(markerData => {
        const marker = new Marker({
            map: map,
            position: markerData.position,
            icon: {
                url: `/static/img/map/marker/${theme}.png`,
                anchor: new Point(27, 55)
            },
            title: markerData.title
        });

        if (markerData.infoWindowLabel || markerData.infoWindowContent) {
            const infowindow = new InfoWindow({
                // prettier-ignore
                content:
                    `<div style="font-family:Roboto,Arial,sans-serif">
                       <div style="font-weight:500;font-size:14px;width:180px">${markerData.infoWindowLabel}</div>` +
                  (markerData.infoWindowContent ? `<div style="margin-top:4px;font-size:13px">${markerData.infoWindowContent}</div>` : "") +
                  `</div>`,
                ariaLabel: markerData.infoWindowLabel
            });

            marker.addListener("click", () => {
                infowindow.open({
                    anchor: marker,
                    map
                });
            });
        }

        return marker;
    });
}

async function initBlock(block) {
    const apikey = block.dataset.apikey;
    await loadScript(apikey);
    await initMap(block);
}

document.addEventListener("DOMContentLoaded", () => {
    const block = document.querySelector(".map-block");
    if (block) {
        initBlock(block);
    }
});
