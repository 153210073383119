import { BaseComponent } from "js/abstracts/baseComponent.js";
import { addClass, removeClass } from "js/utilities/domUtils.js";
import TabHook from "js/utilities/tabHook.js";
import * as trackers from "./trackers.js";

/**
 * Базовый класс всплывающего окна.
 * Предоставляет методы для показа и скрытия корневого элемента
 * с учётом анимации. Вызывает события `opened` и `closed` на
 * корневом элементе после завершения соответствующих анимаций.
 *
 * Открыть / закрыть окно можно двумя способами:
 * 1) Вызвать соответствующий метод экземпляра:
 *      const popup = Popup();
 *      popup.open();
 * 2) Вызвать соответствующее событие в DOM-дереве окна:
 *      const popupRoot = document.getElementById("my-popup");
 *      popupRoot.dispatchEvent(new CustomEvent("popup.open"));
 */
export class PopupBase extends BaseComponent {
    get Defaults() {
        return {
            // Класс или экземпляр класса, отвечающего за отслеживание
            // окончания анимации всплывающего окна.
            tracker: trackers.TransitionTracker,

            // Позволяет закрыть всплывающее окно при нажатии клавиши Esc.
            closeOnEscape: true,

            // CSS-класс, добавляемый перед запуском анимации показа или скрытия
            // всплывающего окна и удаляемый после завершения анимации.
            transitioningClassName: "",
            transitioningOpenClassName: "",
            transitioningCloseClassName: "",

            // CSS-класс, добавляемый перед запуском анимации показа всплывающего окна
            // и удаляемый перед запуском анимации скрытия.
            openedClassName: "",

            // То же, что openedClassName, но применяется к элементу <html>.
            // Может использоваться в тех случаях, когда от состояния всплывающего окна
            // зависят стили страницы или сторонних элементов.
            globalOpenedClassName: "",

            // Функция, вызываемая перед открытием окна.
            open: function () {},

            // Функция, вызываемая после завершения анимации открытия окна.
            opened: function () {},

            // Функция, вызываемая перед закрытием окна.
            close: function () {},

            // Функция, вызываемая после завершения анимации закрытия окна.
            closed: function () {}
        };
    }

    /**
     * Возвращает true, если окно находится в процессе анимирования.
     *
     * @return {boolean}
     */
    get transitioning() {
        return this.root.classList.contains(this.options.transitioningClassName);
    }

    /**
     * Установка состояния анимирования.
     * Может принимать одно из трех значений:
     * 1) "open" - окно в состоянии анимации открытия.
     * 2) "close" - окно в состоянии анимации закрытия.
     * 3) false - анимация завершена.
     *
     * @param {("open"|"close"|false)} value
     */
    set transitioning(value) {
        this.root.classList.toggle(this.options.transitioningClassName, Boolean(value));
        if (this.options.transitioningOpenClassName) {
            this.root.classList.toggle(this.options.transitioningOpenClassName, value === "open");
        }
        if (this.options.transitioningCloseClassName) {
            this.root.classList.toggle(this.options.transitioningCloseClassName, value === "close");
        }
    }

    /**
     * Проверка состояния открытия окна.
     *
     * @return {boolean}
     */
    get opened() {
        return this.root.classList.contains(this.options.openedClassName);
    }

    constructor(root, options) {
        super(options);

        this.root = root;
        if (!this.root) {
            throw new Error("root element not found");
        }

        this.tracker = this._createTracker();
        this.tracker.attachHandler(() => {
            this._onTransitionEnd();
        });

        this._addEventListeners();
    }

    destroy() {
        super.destroy();
        this.tracker.destroy();
        this.root = null;
    }

    /**
     * Создание экземпляра класса трекера для отслеживания
     * окончания анимации.
     *
     * @return {TrackerBase}
     * @private
     */
    _createTracker() {
        if (!this.options.tracker) {
            throw new Error("tracker class required");
        }

        let tracker;
        if (typeof this.options.tracker.prototype !== "undefined") {
            tracker = new this.options.tracker(this.root);
        } else {
            tracker = this.options.tracker;
        }

        tracker.popup = this;
        return tracker;
    }

    _addEventListeners() {
        this.on(document, "keydown", event => {
            switch (event.key) {
                case "Esc": // IE/Edge specific value
                case "Escape":
                    if (this.options.closeOnEscape) {
                        this.close();
                    }
                    break;
                default:
                    // Quit when this doesn't handle the key event.
                    return;
            }
        });

        this.on(this.root, "click", event => {
            const target = event.target;
            if (target.closest('a[href^="#"]')) {
                this.close();
            }
        });

        this.on(this.root, "popup.open", () => {
            this.open();
        });

        this.on(this.root, "popup.close", () => {
            this.close();
        });

        this.on(this.root, "popup.opened", () => {
            this._opened();
        });

        this.on(this.root, "popup.closed", () => {
            this._closed();
        });
    }

    /**
     * Действия, выполняемые при завершении анимации окна.
     */
    _onTransitionEnd() {
        this.transitioning = false;

        if (this.opened) {
            this.root.dispatchEvent(new CustomEvent("popup.opened"));
        } else {
            this.root.dispatchEvent(new CustomEvent("popup.closed"));
        }
    }

    /**
     * Показ всплывающего окна.
     *
     * @return {Promise}
     */
    open() {
        if (this.transitioning) {
            if (this.opened) {
                // Окно в процессе открытия.
                return this._openingPromise;
            } else {
                // Окно в процессе закрытия.
                return Promise.reject(new Error("popup is closing"));
            }
        } else if (this.opened) {
            // Окно уже открыто
            return Promise.resolve();
        }

        this.root.hidden = false;
        this.activeElement = document.activeElement;

        addClass(document.documentElement, this.options.globalOpenedClassName);

        // Почему-то CSS transitions не всегда срабатывают
        // при нулевом таймауте или requestAnimationFrame.
        setTimeout(() => {
            this._open();
        }, 10);

        this._openingPromise = new Promise(resolve => {
            const onOpened = () => {
                resolve();
                this.off(this.root, "popup.opened", onOpened);
            };
            this.on(this.root, "popup.opened", onOpened);
        });
        return this._openingPromise;
    }

    _open() {
        this.options.open.call(this);

        this.root.classList.add(this.options.openedClassName);
        this.transitioning = "open";
        this.tracker.start(true);

        this._hook = new TabHook(this.root);
    }

    _opened() {
        this.tracker.stop();
        this.options.opened.call(this);
    }

    /**
     * Скрытие всплывающего окна.
     *
     * @param {boolean} force - скрытие окна даже если оно в состоянии анимации.
     * @return {Promise}
     */
    close(force = false) {
        if (!force && this.transitioning) {
            if (this.opened) {
                // Окно в процессе открытия.
                return Promise.reject(new Error("popup is opening"));
            } else {
                // Окно в процессе закрытия.
                return this._closingPromise;
            }
        } else if (!this.opened) {
            // Окно уже закрыто
            return Promise.resolve();
        }

        this._close();

        this._closingPromise = new Promise(resolve => {
            const onClosed = () => {
                resolve();
                this.off(this.root, "popup.closed", onClosed);
            };
            this.on(this.root, "popup.closed", onClosed);
        });
        return this._closingPromise;
    }

    _close() {
        this.options.close.call(this);

        this.root.classList.remove(this.options.openedClassName);
        this.transitioning = "close";
        this.tracker.start(false);

        if (this._hook) {
            this._hook.destroy();
        }
    }

    _closed() {
        this.tracker.stop();

        removeClass(document.documentElement, this.options.globalOpenedClassName);

        this.root.hidden = true;
        this.activeElement && this.activeElement.focus && this.activeElement.focus();

        this.options.closed.call(this);
    }

    /**
     * Изменение состояния окна на противоположное.
     */
    toggle() {
        if (this.opened) {
            this.close();
        } else {
            this.open();
        }
    }
}

export class Popup extends PopupBase {
    get Defaults() {
        return Object.assign(super.Defaults, {
            // CSS-класс, добавляемый к всплывающему окну.
            // Чтобы добавить несколько классов - укажите их в виде массива.
            classes: "",

            // Содержимое всплывающего окна.
            content: "",

            // Добавление DOM-элемента, отвечающего за фон.
            backdrop: true,

            // Закрывать окно при клике на фон.
            closeOnBackdropClick: true,

            tracker: new trackers.TransitionTracker({
                getElement: function () {
                    return this.popup.content;
                }
            }),

            closeOnEscape: true,
            transitioningClassName: "popup--transitioning",
            transitioningOpenClassName: "popup--transitioning-open",
            transitioningCloseClassName: "popup--transitioning-close",
            openedClassName: "popup--opened",
            globalOpenedClassName: "overflow-hidden"
        });
    }

    constructor(options) {
        // Создание корневого элемента.
        const root = document.createElement("DIV");
        root.classList.add("popup");
        root.hidden = true;
        root.role = "dialog";
        root.setAttribute("aria-modal", "true");
        document.body.append(root);

        const wrapper = document.createElement("DIV");
        wrapper.classList.add("popup__wrapper");
        root.append(wrapper);

        const content = document.createElement("DIV");
        content.classList.add("popup__content");
        wrapper.append(content);

        super(root, options);

        addClass(this.root, this.options.classes);

        this.wrapper = wrapper;
        this.content = content;

        if (typeof this.options.content === "string") {
            this.content.insertAdjacentHTML("beforeend", this.options.content);
        } else {
            this.content.append(this.options.content);
        }

        if (this.options.backdrop) {
            this.backdrop = document.createElement("DIV");
            this.backdrop.classList.add("popup__backdrop");
            this.root.prepend(this.backdrop);
        }
    }

    destroy() {
        this.close()
            .catch(() => {})
            .then(() => {
                this.root.remove();
                this.wrapper = null;
                this.content = null;
                this.backdrop = null;
                super.destroy();
            });
    }

    _addEventListeners() {
        super._addEventListeners();

        // Закрытие окна при клике на кнопку
        // с атрибутом data-close-popup.
        this.on(this.root, "click", event => {
            if (event.target.closest("[data-close-popup]")) {
                this.close();
            }
        });

        // Закрытие окна при клике на оверлей.
        if (this.options.backdrop && this.options.closeOnBackdropClick) {
            const wrapper = this.root.querySelector(".popup__wrapper");
            this.on(wrapper, "click", event => {
                if (event.target === wrapper) {
                    this.close();
                }
            });
        }
    }

    _open() {
        super._open();

        if (this.backdrop) {
            this.backdrop.classList.add("popup__backdrop--visible");
        }

        // Установка фокуса на само окно, если в нем нет фокусируемых элементов.
        if (!this._hook._firstTabStop) {
            this.content.setAttribute("tabindex", "0");
            this._hook._firstTabStop = this.content;
            this._hook._lastTabStop = this.content;
            this.content.focus();
        }
    }

    _close() {
        super._close();

        if (this.backdrop) {
            this.backdrop.classList.remove("popup__backdrop--visible");
        }
    }
}
