import "./awards-block.pcss";

const awardsBlocks = document.querySelectorAll(".awards-block");
awardsBlocks.forEach(block => {
    const swiperRoot = block.querySelector(".swiper");
    import(/* webpackChunkName: "swiper" */ "components/swiper/swiper.js").then(module => {
        new module.Swiper(swiperRoot, {
            spaceBetween: 20,
            slidesPerView: "auto",
            modules: [module.Scrollbar, module.FreeMode],
            freeMode: true,
            scrollbar: {
                el: ".swiper-scrollbar"
            }
        });
    });
});
