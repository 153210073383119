/*
 * Модуль сохраняет в localStorage UTM-метки, с которыми пользователь приходит на сайт,
 * а позднее позволяет вставить поле с этими метками в форму, отправляемую с сайта.
 *
 * Чтобы указать форму, в которую необходимо добавить поле с UTM-метками, необходимо
 * добавить форме атрибут `data-xclass="utm-form"`:
 *   <form data-xclass="utm-form">
 *     ...
 *   </form>
 *
 * Можно изменить имя поля с UTM-данными, указав атрибут `data-utm-field-name`:
 *   <form data-xclass="utm-form" data-utm-field-name="utm-data">
 *     ...
 *   </form>
 */

import XClass from "data-xclass";
import { saveUTM, UTMForm } from "js/abstracts/forms/utmForm.js";

XClass.register("utm-form", {
    onRegister: function () {
        saveUTM();
    },
    init: function (element) {
        element._utmForm = new UTMForm(element);
    },
    destroy: function (element) {
        if (element._utmForm) {
            element._utmForm.destroy();
            delete element._utmForm;
        }
    }
});
