import "./experience.pcss";

const experience = document.querySelector(".experience");
if (experience) {
    import(/* webpackChunkName: "fancybox" */ "components/fancybox/fancybox.js").then(module => {
        module.Fancybox.bind('[data-fancybox="experience"]', {
            Carousel: {
                transition: "crossfade"
            }
        });
    });
}
