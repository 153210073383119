import { BaseComponent } from "js/abstracts/baseComponent.js";

/**
 * Base class for a single element within the
 * `RadioSelect` or `CheckboxSelectMultiple` widget.
 */
export class FieldOption extends BaseComponent {
    /**
     * Gets the field instance for a given element.
     * @param {HTMLElement} element - The element for which to get the field instance.
     * @returns {FieldOption|null} - The field instance or null if not found.
     */
    static getInstance(element) {
        return element._optionInstance || null;
    }

    /**
     * Get the default options for the field option.
     * @returns {Object} - Default options object.
     */
    get Defaults() {
        return {
            controlSelector: "input",
            iconSelector: ".radiobox",
            iconCheckedClassName: "radiobox--checked",
            iconDisabledClassName: "radiobox--disabled",
            disabledClassName: "field-option--disabled",
            requiredClassName: "field-option--required",
            invalidClassName: "field-option--invalid"
        };
    }

    /**
     * Constructor for the FieldOption class.
     * @param {HTMLElement} element - The HTML element representing the field option.
     * @param {Object} options - Options for configuring the field option.
     */
    constructor(element, options) {
        super(options);

        /** @type {HTMLElement} */
        this.root = element;
        this.root._optionInstance = this;

        this.control = this.root.querySelector(this.options.controlSelector);
        this.icon = this.root.querySelector(this.options.iconSelector);

        this._addEventListeners();
        this._updateDisabledState();
        this._updateRequiredState();
        this._updateCheckedState();
    }

    /**
     * Destructor for cleanup when destroying the FieldOption instance.
     */
    destroy() {
        super.destroy();
        if (typeof this.root._optionInstance !== "undefined") {
            this.root._optionInstance = null;
        }
    }

    /**
     * Gets the name of the option.
     * @returns {string}
     */
    get name() {
        return this.control.name;
    }

    /**
     * Gets the value of the option.
     * @returns {string}
     */
    get value() {
        return this.control.value;
    }

    /**
     * Gets a boolean indicating if the option is disabled.
     * @returns {boolean}
     */
    get disabled() {
        return this.control.disabled;
    }

    /**
     * Sets the disabled state of the option.
     * @param {boolean} value - The value to set for the disabled state.
     */
    set disabled(value) {
        this.control.disabled = value;
    }

    /**
     * Gets a boolean indicating if the option is required.
     * @returns {boolean}
     */
    get required() {
        return this.control.required;
    }

    /**
     * Sets the required state of the option.
     * @param {boolean} value - The value to set for the required state.
     */
    set required(value) {
        this.control.required = value;
    }

    /**
     * Gets a boolean indicating if the option is invalid.
     * @returns {boolean}
     */
    get invalid() {
        return this.root.classList.contains(this.options.invalidClassName);
    }

    /**
     * Sets the invalid state of the option.
     * @param {boolean} value - The value to set for the invalid state.
     */
    set invalid(value) {
        this.root.classList.toggle(this.options.invalidClassName, value);
    }

    /**
     * Gets a boolean indicating if the option is checked.
     * @returns {boolean}
     */
    get checked() {
        return this.control.checked;
    }

    /**
     * Sets the checked state of the option.
     * @param {boolean} value - The value to set for the checked state.
     */
    set checked(value) {
        if (typeof value === "undefined") {
            throw new Error("value required");
        }

        if (this.disabled) {
            return;
        }

        const initialValue = this.control.checked;
        this.control.checked = Boolean(value);

        this.control.dispatchEvent(
            new Event("input", {
                bubbles: true,
                cancelable: true
            })
        );

        if (this.control.checked !== initialValue) {
            this.control.dispatchEvent(
                new Event("change", {
                    bubbles: true,
                    cancelable: true
                })
            );
        }
    }

    /**
     * Toggles the checked state of the option.
     */
    toggle() {
        this.checked = !this.checked;
    }

    /**
     * Adds event listeners for input and keydown events.
     * @private
     */
    _addEventListeners() {
        this.on(this.root, "input,form-reset", () => {
            this._updateCheckedState();
        });

        this.on(this.root, "keydown", event => {
            if (event.code === "Space") {
                this._onSpacePressed(event);
                event.stopPropagation();
                event.preventDefault();
            }
        });
    }

    /**
     * Handles the Space key press event.
     * @param {KeyboardEvent} event - The keyboard event object.
     * @private
     */
    _onSpacePressed(event) {
        this.checked = true;
    }

    /**
     * Updates the `disabled` state of the option based on the input element.
     * @private
     */
    _updateDisabledState() {
        this.root.classList.toggle(this.options.disabledClassName, this.disabled);
        this.icon.classList.toggle(this.options.iconDisabledClassName, this.disabled);
    }

    /**
     * Updates the `required` state of the option based on the input element.
     * @private
     */
    _updateRequiredState() {
        this.root.classList.toggle(this.options.requiredClassName, this.required);
    }

    /**
     * Updates the `checked` state of the option based on the input element.
     * @private
     */
    _updateCheckedState() {
        this.icon.classList.toggle(this.options.iconCheckedClassName, this.checked);
    }
}
