/*
 * Компонент, (опционально) добавляющий CSS-класс к элементу,
 * когда он становится видимым. В момент появления элемента в области видимости,
 * на элементе генерируется событие "in-view.appear", к которыму можно добавить
 * пользовательские обработчики.
 * Если указан атрибут "data-in-view-reverse", то observer следит также за исчезновением
 * элемента из области видимости. Когда это происходит, указанный CSS-класс удаляется,
 * а на элементе генерируется событие "in-view.disappear".
 *
 * Пример:
 *   Добавляет класс "header--visible" элементу спустя 2 секунды после того,
 *   как он станет видимым. После того, как элемент перестанет быть виден,
 *   CSS-класс удаляется (тоже с 2-хсекундной задержкой).
 *
 *   <div data-xclass="in-view"
 *        data-in-view-class="header--visible"
 *        data-in-view-margin="20px 0px"
 *        data-in-view-threshold="0.25 0.5 1"
 *        data-in-view-delay="2000"
 *        data-in-view-reverse>
 *   </div>
 */

import XClass from "data-xclass";


XClass.register("in-view", {
    init: function (element) {
        let { inViewClass, inViewMargin, inViewThreshold, inViewDelay, inViewReverse } = element.dataset;

        // Формирование массива CSS-классов, которые необходимо добавить
        // целевому DOM-элементу.
        const classToAdd = inViewClass.split(/\s+/).filter(Boolean);

        // Форматирование параметра rootMargin IntersectionObserver.
        const margin = (inViewMargin || "0px").replace(/\b0\b/g, '0px');

        // Форматирование параметра threshold IntersectionObserver.
        let threshold;
        if (inViewThreshold) {
            const values = inViewThreshold.split(/\s+/).filter(Boolean).map(value => parseFloat(value));
            threshold = values.length === 1 ? values[0] : values;
        } else {
            threshold = 0;
        }

        // Задержка перед добавлением CSS-классов.
        const delay = parseInt(inViewDelay);

        // Флаг, означающий, что необходимо удалить CSS-класс
        // после того, как элемент перестаёт быть видимым.
        const reverse = inViewReverse === "";

        const handleInView = () => {
            if (classToAdd.length) {
                element.classList.add(...classToAdd);
            }

            element.dispatchEvent(new CustomEvent("in-view.appear"));

            // Если следить за выходом из зоны видимости не нужно,
            // то observer больше не нужен.
            if (!reverse) {
                this.reset(element);
            }
        }

        const handleOutOfView = () => {
            if (classToAdd.length) {
                element.classList.remove(...classToAdd);
            }

            element.dispatchEvent(new CustomEvent("in-view.disappear"));
        }

        element._inViewObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    if (!isNaN(delay)) {
                        setTimeout(handleInView, delay);
                    } else {
                        handleInView();
                    }
                } else if (reverse) {
                    if (!isNaN(delay)) {
                        setTimeout(handleOutOfView, delay);
                    } else {
                        handleOutOfView();
                    }
                }
            });
        }, {
            rootMargin: margin,
            threshold: threshold,
        });

        element._inViewObserver.observe(element);
    },

    destroy: function (element) {
        this.reset(element);
    },

    reset: function (element) {
        if (element._inViewObserver) {
            element._inViewObserver.unobserve(element);
            delete element._inViewObserver;
        }
    }
});
