/* global grecaptcha */

import subscribe from "subscribe-event";
import { BaseComponent } from "js/abstracts/baseComponent.js";
import { importScript, getGlobalSiteKey, STATUS } from "./library.js";

let observer;
let userActionUnsubscribeCallbacks;
let scriptLoaded = false;
let onScriptLoadedCallbacks = [];


/**
 * Component for Google reCAPTCHA v2 with checkbox.
 */
export class reCaptchaV2Form extends BaseComponent {
    get Defaults() {
        return {
            fieldSelector: ".g-recaptcha-v2"
        };
    }

    /**
     * @param {HTMLFormElement} formElement
     * @param {Object} [options]
     */
    constructor(formElement, options) {
        super(options);
        this.formElement = formElement;

        if (scriptLoaded) {
            this.setupForm();
        } else {
            this._prefetch("preconnect", "https://www.google.com");
            this._prefetch("preconnect", "https://www.gstatic.com");
            this._prefetch("preconnect", "https://fonts.gstatic.com");

            listenUserActions();

            setTimeout(() => {
                getObserver().observe(this.formElement);
            });

            onScriptLoadedCallbacks.push(this.setupForm.bind(this));
        }

        this.on(document, "submit", event => {
            if (event.target === this.formElement && this.status === STATUS.FETCHED) {
                queueMicrotask(() => {
                    this.resetField();
                });
            }
        });
    }

    /**
     * Find the DOM element of the reCAPTCHA field.
     * @returns {HTMLDivElement}
     */
    getField() {
        return this.formElement.querySelector(this.options.fieldSelector);
    }

    /**
     * Reset the state of the reCAPTCHA field.
     */
    resetField() {
        const field = this.getField();
        if (field) {
            grecaptcha.reset(field.dataset.widgetId);
        }
    }

    /**
     * Get the public key for reCAPTCHA.
     * @returns {string}
     */
    getSiteKey() {
        const field = this.getField();
        return (field && field.dataset.sitekey) || getGlobalSiteKey();
    }

    /**
     * Initialize reCAPTCHA on the form.
     */
    setupForm() {
        const field = this.getField();
        const widgetID = grecaptcha.render(field, {
            sitekey: this.getSiteKey()
        });
        field.setAttribute("data-widget-id", widgetID);
    }

    _prefetch(rel, href, as) {
        const linkExists = document.head.querySelector(`link[href="${href}"]`);
        if (linkExists) {
            return
        }

        const linkEl = document.createElement("link");
        linkEl.rel = rel;
        linkEl.href = href;
        if (as) {
            linkEl.as = as;
        }
        document.head.append(linkEl);
    }
}


/**
 * Creating an IntersectionObserver that will load the reCAPTCHA script
 * when at least one of the forms appears in the viewport.
 * @return {IntersectionObserver}
 */
function getObserver() {
    if (!observer) {
        observer = new IntersectionObserver(entries => {
            for (let i = 0; i < entries.length; i++) {
                const entry = entries[i];
                if (entry.isIntersecting) {
                    loadScript();
                    break;
                }
            }
        });
    }
    return observer;
}

/**
 * Adding event handlers for user interaction events on the page
 * to initiate the loading of the reCAPTCHA script.
 */
function listenUserActions() {
    if (!userActionUnsubscribeCallbacks) {
        userActionUnsubscribeCallbacks = ["keydown", "mousedown", "touchstart"].map(eventName => {
            return subscribe(document, eventName, () => {
                loadScript();
            }, { capture: true });
        });
    }
}


/**
 * Loads the Google reCAPTCHA script if it hasn't been loaded yet,
 * and initializes the forms once the script is loaded.
 */
function loadScript() {
    observer && observer.disconnect();
    userActionUnsubscribeCallbacks && userActionUnsubscribeCallbacks.forEach(unsubscribe => unsubscribe());

    importScript("explicit").then(() => {
        scriptLoaded = true;
        onScriptLoadedCallbacks.forEach(callback => callback());
    });
}
