import { FormBase } from "js/abstracts/forms/formBase.js";
import { isReplayEvent } from "js/components/forms/replayButton.js";
import { displayToast } from "components/toast/toast.js";
import * as CSRF from "js/components/forms/csrf.js";

// Enumeration for form statuses
const STATUS = Object.freeze({
    READY: Symbol("ready"),
    CAPTURED: Symbol("captured"),
    SENDING: Symbol("sending")
});

/**
 * Base class for a form that sends data via AJAX requests when a submit event occurs.
 * Captures the first `submit` event and blocks subsequent ones until the form is sent.
 * URL and method for submission are taken from HTML attributes `data-ajax-action`
 * and `data-ajax-method` of the form. If not present, values from the `action`
 * and `method` attributes are used.
 *
 * AJAX Form Lifecycle:
 *  - Captures the `submit` event during the capture phase.
 *    - If the form is ready, change status to CAPTURED and call `onSubmit()`.
 *    - If the form is already captured or sending, block the event (if not a replay).
 *  - Captures the `submit` event during the bubbling phase.
 *    - Prevents the default form submission.
 *    - Changes the form status to SENDING.
 *    - Sends an AJAX request and handles responses.
 *    - Calls onConnectionError, onResponse, and onFinalize accordingly.
 *
 *                         `submit` event
 *                         (capture stage)
 *                                │
 *                            onSubmit
 *                                │
 *                         `submit` event
 *                         (bubble stage)
 *                                │
 *                           sendRequest
 *                                │
 *                           onBeforeSend
 *                                │
 *                             [fetch]
 *                  ┌─────────────┴─────────────┐
 *          onConnectionError               onResponse
 *                  │             ┌─────────────┴─────────────┐
 *                  │     onValidationError               onSuccess
 *                  │             └─────────────┬─────────────┘
 *                  └─────────────┬─────────────┘
 *                            onFinalize
 */
export class AjaxFormBase extends FormBase {
    // Symbol used for storing form status
    static formStatusSymbol = Symbol("AJAX");

    /**
     * Gets the current status of the form.
     * @returns {Symbol} The form status.
     */
    get status() {
        return this.formElement[this.constructor.formStatusSymbol] || STATUS.READY;
    }

    /**
     * Sets the form status.
     * @param {Symbol} value - The new form status.
     */
    set status(value) {
        this.formElement[this.constructor.formStatusSymbol] = value;
    }

    /**
     * Gets the URL for form submission.
     * @returns {string} The form submission URL.
     */
    get action() {
        return this.formElement.dataset.ajaxAction || super.action;
    }

    /**
     * Gets the HTTP method for form submission.
     * @returns {string} The form submission method.
     */
    get method() {
        return this.formElement.dataset.ajaxMethod || super.method;
    }

    /**
     * Constructor for the AjaxFormBase class.
     * @param {HTMLFormElement} formElement - The HTML form element.
     * @param {Object} [options] - Additional options for the form.
     */
    constructor(formElement, options) {
        super(formElement, options);

        // Event listener for the capture phase of the `submit` event
        this.on(
            this.formElement,
            "submit",
            event => {
                console.debug("AJAX: Captured 'submit' event.");

                switch (this.status) {
                    case STATUS.READY:
                        this.status = STATUS.CAPTURED;
                        this.onSubmit();
                        break;
                    case STATUS.CAPTURED:
                        // Block all `submit` events except those initialized
                        // by the "replay" button.
                        if (!isReplayEvent(event)) {
                            console.debug("AJAX: Form is sending. Event will be aborted.");
                            event.preventDefault();
                            event.stopPropagation();
                        }
                        break;
                    case STATUS.SENDING:
                        // Block all `submit` events.
                        console.debug("AJAX: Form is sending. Event will be aborted.");
                        event.preventDefault();
                        event.stopPropagation();
                        break;
                }
            },
            { capture: true }
        );

        // Event listener for the bubbling stage of the submit event
        this.on(this.formElement, "submit", event => {
            console.debug("AJAX: Captured 'submit' event on bubbling stage.");

            event.preventDefault();

            this.status = STATUS.SENDING;

            // Send the AJAX request
            this.sendRequest(this.action, this.method, this._buildRequestHeaders(), this._buildFormData())
                .catch(error => {
                    console.debug("AJAX: Form not sent successfully. Reason:", error.message);
                    return this.onConnectionError(error);
                })
                .then(data => {
                    console.debug("AJAX: Form successfully sent. Response:", data);
                    return this.onResponse(data);
                })
                .finally(() => {
                    return this.onFinalize();
                });
        });
    }

    /**
     * Handler for the initialization of the form submission process.
     * Called during the capture phase of the `submit` event.
     * @returns {Promise<void>}
     */
    async onSubmit() {}

    /**
     * Handler for receiving a response from the server.
     * If the server response is a JSON object with "success: false",
     * displays form errors.
     * @param {*} data - Response data from the server.
     * @returns {Promise<void>}
     */
    async onResponse(data) {
        if (data?.success === false) {
            await this.onValidationError(data);
        } else {
            this.hideErrors();
            await this.onSuccess(data);
        }
    }

    /**
     * Method called just before a request is sent to the server.
     * @returns {Promise<void>}
     */
    async onBeforeSend() {}

    /**
     * Handler for receiving a successful response from the server.
     * @param {*} data - Response data from the server.
     * @returns {Promise<void>}
     */
    async onSuccess(data) {}

    /**
     * Handler for receiving form validation errors.
     * Called when the server response indicates that there are validation errors
     * in the submitted form data (when the `success` property in the server response
     * is `false`).
     * @param {*} data - Response data containing form errors.
     * @returns {Promise<void>}
     */
    async onValidationError(data) {
        this.displayErrors(data.errors);
    }

    /**
     * Handler for errors that occur during form submission.
     * @param {AjaxError} error - The reason for the connection error.
     * @returns {Promise<void>}
     */
    async onConnectionError(error) {
        displayToast({
            message: error.message,
            code: error.code
        });
    }

    /**
     * Method called after form submission, regardless of success.
     * @returns {Promise<void>}
     */
    async onFinalize() {
        CSRF.removeCookie();
        this.status = STATUS.READY;
    }

    /**
     * Builds an object with headers for the AJAX request.
     * @returns {Object} - Headers for the request.
     */
    _buildRequestHeaders() {
        return {};
    }

    /**
     * Builds an object with data to be sent with the form.
     * @returns {FormData} - Form data to be sent.
     */
    _buildFormData() {
        return new FormData(this.formElement);
    }

    /**
     * Sends form data via AJAX.
     * @param {string} url - The URL for the AJAX request.
     * @param {string} method - The HTTP method for the AJAX request.
     * @param {Object} headers - Headers for the AJAX request.
     * @param {Object} data - Data to be sent with the request.
     * @returns {Promise<Object>} - A promise that resolves to the server response.
     */
    async sendRequest(url, method, headers, data) {
        await this.onBeforeSend();

        const response = await fetch(url, {
            method: method,
            referrerPolicy: "origin-when-cross-origin",
            headers: headers,
            body: data
        });

        if (!response.ok) {
            throw new AjaxError(response.statusText, response.status);
        }

        const contentType = response.headers.get("Content-Type");
        if (contentType && contentType.includes("application/json")) {
            return response.json();
        } else {
            return response.text();
        }
    }
}

export class AjaxError extends Error {
    constructor(message, code) {
        super(message);
        this.name = this.constructor.name;
        this.message = message;
        this.code = code;
    }
}
