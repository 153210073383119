import XClass from "data-xclass";
import { FormField } from "bem/common.fields/form-field/form-field.js";

import "./textarea-form-field.pcss";

/**
 * Класс для поля формы с элементом <textarea>.
 */
export class TextareaField extends FormField {
    get Defaults() {
        return Object.assign(super.Defaults, {
            controlSelector: "textarea"
        });
    }

    constructor(element, options) {
        super(element, options);

        this.control = this.root.querySelector(this.options.controlSelector);

        this._updateDisabledState();
        this._updateRequiredState();
    }

    get name() {
        return this.control.name;
    }

    get value() {
        return this.control.value;
    }

    get disabled() {
        return this.control.disabled;
    }

    set disabled(value) {
        this.control.disabled = value;
    }

    get required() {
        return this.control.required;
    }

    set required(value) {
        this.control.required = value;
    }

    /**
     * Синхронизация с наличием/отсутствием атрибута disabled
     * на элементе <textarea>.
     * @private
     */
    _updateDisabledState() {
        this.root.classList.toggle(this.options.disabledClassName, this.disabled);
    }

    /**
     * Синхронизация с наличием/отсутствием атрибута required
     * на элементе <textarea>.
     * @private
     */
    _updateRequiredState() {
        this.root.classList.toggle(this.options.requiredClassName, this.required);
    }
}

// ==========================================================
//  Автоматическое создание экземпляров класса TextareaField
// ==========================================================
XClass.register("textarea-field", {
    onRegister: function () {
        // наблюдатель за изменением атрибута disabled
        this.observer = new MutationObserver(mutationsList => {
            for (const mutation of mutationsList) {
                if (mutation.type === "attributes") {
                    const widgetRoot = XClass.findClosest(mutation.target, "textarea-field");
                    const fieldInstance = widgetRoot && FormField.getInstance(widgetRoot);
                    if (fieldInstance) {
                        switch (mutation.attributeName) {
                            case "disabled":
                                fieldInstance._updateDisabledState();
                                break;
                            case "required":
                                fieldInstance._updateRequiredState();
                                break;
                        }
                    }
                }
            }
        });
    },

    init: function (element) {
        const instance = new TextareaField(element, {
            controlSelector: ".textarea-form-field__control"
        });

        // добавление <input> элемента в перечень отслеживания
        // изменений атрибутов disabled и required.
        this.observer.observe(instance.control, {
            attributes: true,
            attributeFilter: ["disabled", "required"]
        });
    },

    destroy: function (element) {
        const fieldInstance = FormField.getInstance(element);
        if (fieldInstance) {
            fieldInstance.destroy();
        }
    }
});
