// Список допустимых полей в записях корзины.
// Свойства, которые не входят в этот список, будут отброшены.
// Используется только при добавлении товаров в localStorage.
const CART_RECORD_ALLOWED_FIELDS = ["token", "quantity"];

/**
 * Проверяет и форматирует запись о элементе корзины.
 * Используется только при добавлении товаров в localStorage.
 * При выводе страницы корзины не используется.
 *
 * @param {Object} record
 * @return {Object}
 */
export function formatCartRecord(record) {
    // Фильтрация полей переданного объекта.
    const filteredData = {};
    for (const key in record) {
        if (CART_RECORD_ALLOWED_FIELDS.includes(key)) {
            filteredData[key] = record[key];
        }
    }

    // Базовые проверки корректности записи.

    try {
        filteredData.token = formatToken(filteredData.token);
        filteredData.quantity = formatQuantity(filteredData.quantity);
    } catch (e) {
        if (e instanceof Error) {
            throw new Error(`Invalid card record: ${e.message}`);
        }
        throw e;
    }

    return filteredData;
}

/**
 * Форматирование токена.
 *
 * @param {string} value
 * @return {string}
 */
export function formatToken(value) {
    if (!value) {
        throw new Error("Token required");
    } else if (typeof value !== "string") {
        throw new Error("Token must be a string");
    }

    return value;
}

/**
 * Форматирование количества товара.
 *
 * @param {string|number} value
 * @return {number}
 */
export function formatQuantity(value) {
    let quantity;

    if (!["string", "number"].includes(typeof value)) {
        throw new Error("Quantity must be a string or number");
    } else if (typeof value === "string") {
        quantity = parseInt(value, 10);
        if (isNaN(quantity) || quantity % 1 !== 0) {
            throw new Error("Quantity must by an integer");
        }
    } else {
        quantity = value;
    }

    if (quantity <= 0) {
        throw new Error("Quantity must by a positive integer");
    }

    return quantity;
}
