import Cookies from "js-cookie";
import XClass from "data-xclass";
import "normalize.css/normalize.css";
import "css/utilities/reset.pcss";

//===============
//  Fonts
//===============
import "fonts/Raleway/font.css";
import "fonts/RalewayItalic/font.css";
import "fonts/PlayfairDisplay/font.css";
import "fonts/PlayfairDisplayItalic/font.css";
import "fonts/Italiana/font.css";

//===============
//  Components
//===============

import "components/accordion/accordion.js";
import "components/cart/cart.js";
import "components/coupons/coupons.js";
import "components/editor/editor.js";
import "components/iframe/iframe.js";

import "js/components/forms/csrf.js";
import "js/components/forms/reCaptcha.js";
import "js/components/forms/utm.js";
import "js/components/in-view.js";

import "css/components/forms/errors.pcss";

// Swiper
import "swiper/css/bundle";
import "components/swiper/swiper.pcss";

//===============
//  BEM
//===============

// Tools
import "bem/common.blocks/aspect-ratio-box/aspect-ratio-box.js";
import "bem/common.blocks/container/container.js";
import "bem/common.blocks/image-card/image-card.js";
import "bem/common.blocks/infinite-scroll/infinite-scroll.js";
import "bem/common.blocks/lazy-box/lazy-box.js";

// Atomic blocks
import "bem/common.blocks/activities-slogan/activities-slogan.js";
import "bem/common.blocks/activity/activity.js";
import "bem/common.blocks/activity-list/activity-list.js";
import "bem/common.blocks/advantage/advantage.js";
import "bem/common.blocks/advantages/advantages.js";
import "bem/common.blocks/amenity/amenity.js";
import "bem/common.blocks/amenity-list/amenity-list.js";
import "bem/common.blocks/article-footer/article-footer.js";
import "bem/common.blocks/article-list/article-list.js";
import "bem/common.blocks/article-topic/article-topic.js";
import "bem/common.blocks/award/award.js";
import "bem/common.blocks/breadcrumbs/breadcrumbs.js";
import "bem/common.blocks/book-block/book-block.js";
import "bem/common.blocks/book-popup/book-popup.js";
import "bem/common.blocks/button/button.js";
import "bem/common.blocks/cart-counter/cart-counter.js";
import "bem/common.blocks/cart-product/cart-product.js";
import "bem/common.blocks/cart-product-list/cart-product-list.js";
import "bem/common.blocks/cart-summary/cart-summary.js";
import "bem/common.blocks/category-list/category-list.js";
import "bem/common.blocks/checkbox/checkbox.js";
import "bem/common.blocks/checkout-summary/checkout-summary.js";
import "bem/common.blocks/coffee-page-links/coffee-page-links.js";
import "bem/common.blocks/coffee-page-link/coffee-page-link.js";
import "bem/common.blocks/coffee-price-list/coffee-price-list.js";
import "bem/common.blocks/delivery-item/delivery-item.js";
import "bem/common.blocks/developer/developer.js";
import "bem/common.blocks/empty-cart/empty-cart.js";
import "bem/common.blocks/error-list/error-list.js";
import "bem/common.blocks/experience/experience.js";
import "bem/common.blocks/flowers/flowers.js";
import "bem/common.blocks/footer-menu/footer-menu.js";
import "bem/common.blocks/footer-legals/footer-legals.js";
import "bem/common.blocks/form-success-overlay/form-success-overlay.js";
import "bem/common.blocks/grecaptcha-hint/grecaptcha-hint.js";
import "bem/common.blocks/guestbook-list/guestbook-list.js";
import "bem/common.blocks/hamburger/hamburger.js";
import "bem/common.blocks/header-contact/header-contact.js";
import "bem/common.blocks/header-contact-list/header-contact-list.js";
import "bem/common.blocks/heading/heading.js";
import "bem/common.blocks/home-service/home-service.js";
import "bem/common.blocks/inkeepers-tagline/inkeepers-tagline.js";
import "bem/common.blocks/inner-menu/inner-menu.js";
import "bem/common.blocks/loyalty-form-block/loyalty-form-block.js";
import "bem/common.blocks/main-menu/main-menu.js";
import "bem/common.blocks/main-submenu/main-submenu.js";
import "bem/common.blocks/mobile-menu/mobile-menu.js";
import "bem/common.blocks/mobile-submenu/mobile-submenu.js";
import "bem/common.blocks/opening/opening.js";
import "bem/common.blocks/opening-list/opening-list.js";
import "bem/common.blocks/page/page.js";
import "bem/common.blocks/partner/partner.js";
import "bem/common.blocks/partner-list/partner-list.js";
import "bem/common.blocks/photo-gallery-block/photo-gallery-block.js";
import "bem/common.blocks/photo-gallery-list/photo-gallery-list.js";
import "bem/common.blocks/preloader/preloader.js";
import "bem/common.blocks/product/product.js";
import "bem/common.blocks/product-details/product-details.js";
import "bem/common.blocks/product-list/product-list.js";
import "bem/common.blocks/product-gallery/product-gallery.js";
import "bem/common.blocks/radiobox/radiobox.js";
import "bem/common.blocks/related-products-block/related-products-block.js";
import "bem/common.blocks/resort/resort.js";
import "bem/common.blocks/resorts-list/resorts-list.js";
import "bem/common.blocks/review/review.js";
import "bem/common.blocks/room/room.js";
import "bem/common.blocks/room-gallery/room-gallery.js";
import "bem/common.blocks/room-tagline/room-tagline.js";
import "bem/common.blocks/rooms-list/rooms-list.js";
import "bem/common.blocks/season-link/season-link.js";
import "bem/common.blocks/season-links/season-links.js";
import "bem/common.blocks/share/share.js";
import "bem/common.blocks/shop-category/shop-category.js";
import "bem/common.blocks/shop-category-list/shop-category-list.js";
import "bem/common.blocks/shuttle-services/shuttle-services.js";
import "bem/common.blocks/social-media/social-media.js";
import "bem/common.blocks/special-notices/special-notices.js";
import "bem/common.blocks/staff-list/staff-list.js";
import "bem/common.blocks/staff/staff.js";
import "bem/common.blocks/star-rating/star-rating.js";
import "bem/common.blocks/testimonial/testimonial.js";
import "bem/common.blocks/testimonial-list/testimonial-list.js";
import "bem/common.blocks/thank-you/thank-you.js";
import "bem/common.blocks/triple-card/triple-card.js";
import "bem/common.blocks/triple-cards/triple-cards.js";

// Fields
import "bem/common.fields/checkbox-form-field/checkbox-form-field.js";
import "bem/common.fields/checkbox-option/checkbox-option.js";
import "bem/common.fields/checkbox-select-form-field/checkbox-select-form-field.js";
import "bem/common.fields/delivery-field/delivery-field.js";
import "bem/common.fields/numeric-form-field/numeric-form-field.js";
import "bem/common.fields/radiobox-option/radiobox-option.js";
import "bem/common.fields/radiobox-select-form-field/radiobox-select-form-field.js";
import "bem/common.fields/recaptcha-form-field/recaptcha-form-field.js";
import "bem/common.fields/select-form-field/select-form-field.js";
import "bem/common.fields/text-form-field/text-form-field.js";
import "bem/common.fields/textarea-form-field/textarea-form-field.js";

// Forms
import "bem/common.forms/checkout-form/checkout-form.js";
import "bem/common.forms/contact-form/contact-form.js";
import "bem/common.forms/coupon-form/coupon-form.js";
import "bem/common.forms/loyalty-card-form/loyalty-card-form.js";
import "bem/common.forms/payment-form/payment-form.js";

// Composite blocks
import "bem/common.blocks/awards-block/awards-block.js";
import "bem/common.blocks/blog-block/blog-block.js";
import "bem/common.blocks/contact-block/contact-block.js";
import "bem/common.blocks/contact-line/contact-line.js";
import "bem/common.blocks/coupon-code/coupon-code.js";
import "bem/common.blocks/footer/footer.js";
import "bem/common.blocks/giant-text-block/giant-text-block.js";
import "bem/common.blocks/guestbook/guestbook.js";
import "bem/common.blocks/hero/hero.js";
import "bem/common.blocks/header/header.js";
import "bem/common.blocks/home-services-block/home-services-block.js";
import "bem/common.blocks/map-block/map-block.js";
import "bem/common.blocks/share-block/share-block.js";
import "bem/common.blocks/side-panel/side-panel.js";
import "bem/common.blocks/reviews-block/reviews-block.js";
import "bem/common.blocks/rooms-block/rooms-block.js";
import "bem/common.blocks/tabs/tabs.js";

//===============
//  Utilities
//===============

import "css/utilities/background.pcss";
import "css/utilities/overflow-hidden.pcss";
import "css/utilities/spaces.pcss";
import "css/utilities/visually-hidden.pcss";

//===============
//  Application
//===============

// Fonts
import "./fonts.js";

// Styles
import "css/themes.pcss";
import "css/app.pcss";

// Pages
import "bem/common.pages/amenities-page/amenities-page.js";
import "bem/common.pages/blog-article-page/blog-article-page.js";
import "bem/common.pages/cart-page/cart-page.js";
import "bem/common.pages/category-page/category-page.js";
import "bem/common.pages/checkout-page/checkout-page.js";
import "bem/common.pages/coffee-beans-page/coffee-beans-page.js";
import "bem/common.pages/coffee-roasting-page/coffee-roasting-page.js";
import "bem/common.pages/coffee-shop-page/coffee-shop-page.js";
import "bem/common.pages/contacts-page/contacts-page.js";
import "bem/common.pages/contacts-thank-you-page/contacts-thank-you-page.js";
import "bem/common.pages/error-page/error-page.js";
import "bem/common.pages/getting-here-page/getting-here-page.js";
import "bem/common.pages/innkeepers-page/innkeepers-page.js";
import "bem/common.pages/payment-page/payment-page.js";
import "bem/common.pages/product-page/product-page.js";
import "bem/common.pages/room-page/room-page.js";
import "bem/common.pages/royal-spa-page/royal-spa-page.js";
import "bem/common.pages/season-page/season-page.js";
import "bem/common.pages/welcome-addons-page/welcome-addons-page.js";

window.addEventListener("DOMContentLoaded", () => {
    XClass.start();
});

// Включение плавного скролла страницы, но так, чтобы он
// не срабатывал при загрузке страницы.
window.addEventListener("load", () => {
    document.documentElement.style.scrollBehavior = "smooth";
})

export default {
    Cookies,
    XClass
};
