/*
 * Добавление на форму скрытой submit-кнопки с именем "replay",
 * чтобы использовать её в методе requestSubmit() и отличать событие,
 * инициированное пользователем, от событий, инициированных скриптом.
 */

import XClass from "data-xclass";

/**
 * Создание replay-кнопки и добавление её к форме.
 *
 * @param {HTMLFormElement} form
 * @param {string} [suffix]
 * @return {HTMLElement}
 */
export function addReplayButton(form, suffix) {
    let button = findReplayButton(form, suffix);
    if (!button) {
        button = document.createElement("button");
        button.type = "submit";
        button.name = suffix ? `replay-${suffix}` : "replay";
        button.hidden = true;
        XClass.mutateDOM(() => {
            form.prepend(button);
        });
    }
    return button;
}

/**
 * Удаление replay-кнопки.
 *
 * @param {HTMLFormElement} form
 * @param {string} [suffix]
 */
export function removeReplayButton(form, suffix) {
    let button = findReplayButton(form, suffix);
    if (button) {
        XClass.mutateDOM(() => {
            button.remove();
        });
    }
}

/**
 * Поиск replay-кнопки на форме.
 *
 * @param {HTMLElement} formElement
 * @param {string} [suffix]
 * @returns {HTMLElement|null}
 */
export function findReplayButton(formElement, suffix) {
    const buttonName = suffix ? `replay-${suffix}` : "replay";
    return formElement.querySelector(`button[name="${buttonName}"]`);
}

/**
 * Проверка, что событие submit было инициализировано скрытой кнопкой.
 *
 * @param {SubmitEvent} event
 * @param {string} [suffix]
 * @returns {boolean}
 */
export function isReplayEvent(event, suffix) {
    if (typeof event.submitter?.name !== "string") {
        return false;
    }

    if (suffix) {
        return event.submitter.name === `replay-${suffix}`;
    } else {
        return event.submitter.name.startsWith("replay");
    }
}

XClass.register("replay-button", {
    init: function (element) {
        // Создание replay-кнопки по умолчанию.
        addReplayButton(element);
    },
    destroy: function (element) {
        // Удаление replay-кнопки по умолчанию.
        removeReplayButton(element);
    }
});
