import XClass from "data-xclass";
import { FieldOption } from "js/abstracts/fieldOption.js";
import { FormField } from "bem/common.fields/form-field/form-field.js";

import "./radiobox-select-form-field.pcss";

/**
 * Класс для поля с группой радиобоксов.
 */
export class RadioSelectField extends FormField {
    get Defaults() {
        return Object.assign(super.Defaults, {
            optionSelector: ".field-option",
            listSelector: ".radio-select__list"
        });
    }

    constructor(element, options) {
        super(element, options);

        this.list = this.root.querySelector(this.options.listSelector);

        this._addEventListeners();
    }

    /**
     * @returns {FieldOption[]}
     */
    get choiceOptions() {
        return Array.from(this.list.querySelectorAll(this.options.optionSelector))
            .map(optionElement => {
                return FieldOption.getInstance(optionElement);
            })
            .filter(Boolean);
    }

    /**
     * @returns {FieldOption}
     */
    get checkedOption() {
        return this.choiceOptions.find(optionInstance => {
            return optionInstance.checked;
        });
    }

    get name() {
        const firstOption = this.choiceOptions[0];
        return (firstOption && firstOption.name) || super.name;
    }

    get value() {
        const checkedOption = this.checkedOption;
        return checkedOption && checkedOption.value;
    }

    get disabled() {
        return super.disabled;
    }

    set disabled(value) {
        super.disabled = value;
        this.choiceOptions.forEach(optionInstance => {
            optionInstance.disabled = value;
        });
    }

    get required() {
        return super.required;
    }

    set required(value) {
        super.required = value;
        this.choiceOptions.forEach(optionInstance => {
            optionInstance.required = value;
        });
    }

    get invalid() {
        return super.invalid;
    }

    set invalid(value) {
        super.invalid = value;
        this.choiceOptions.forEach(optionInstance => {
            optionInstance.invalid = value;
        });
    }

    _addEventListeners() {
        this.on(this.root, "input,form-reset", () => {
            this.choiceOptions.forEach(optionInstance => {
                optionInstance._updateCheckedState();
            });
        });
    }
}

// =============================================================
//  Автоматическое создание экземпляров класса RadioSelectField
// =============================================================
XClass.register("radiobox-select-field", {
    init: function (element) {
        new RadioSelectField(element, {
            optionSelector: ".radiobox-option",
            listSelector: ".radiobox-select-form-field__list"
        });
    },

    destroy: function (element) {
        const fieldInstance = FormField.getInstance(element);
        if (fieldInstance) {
            fieldInstance.destroy();
        }
    }
});
