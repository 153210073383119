import "./toast.pcss";

const GAP = 10;

/**
 * Display a toast message on the page.
 *
 * @param {object} options - The options object.
 * @param {string} options.message - The text message to be displayed in the toast.
 * @param {number} [options.code] - An optional error code to be included in the toast message.
 * @param {string} [options.theme] - The theme of the toast, default is "error". Supports "error" and "success".
 * @param {number} [options.timeout] - The duration in milliseconds before the toast automatically disappears.
 */
export function displayToast({ message, code, theme = "error", timeout = 5000 }) {
    const errorContainer = document.createElement("div");
    errorContainer.classList.add("toast");
    errorContainer.classList.add(`toast--${theme}`);
    if (code) {
        errorContainer.innerText = `Error ${code}: ${message}`;
    } else {
        errorContainer.innerText = message;
    }
    errorContainer.setAttribute("role", "alert");
    errorContainer.setAttribute("aria-live", "assertive");
    errorContainer.setAttribute("aria-atomic", "true");
    document.body.append(errorContainer);

    let offset = 0;
    const currentErrors = Array.from(document.querySelectorAll(`.toast`));
    currentErrors.reverse().forEach(error => {
        error.style.translate = `0 ${-offset}px`;
        offset += error.scrollHeight + GAP;
    });

    requestAnimationFrame(() => {
        errorContainer.classList.add("toast--visible");
    });

    setTimeout(() => {
        errorContainer.classList.remove("toast--visible");
        errorContainer.addEventListener(
            "transitionend",
            () => {
                errorContainer.remove();
            },
            { once: true }
        );
    }, timeout);
}
