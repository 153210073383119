import { fetchSummaryData, renderSummary } from "bem/common.pages/checkout-page/checkout-page.js";
// import { displayToast } from "components/toast/toast.js";

import "./payment-page.pcss";

const paymentPage = document.querySelector(".payment-page");
if (paymentPage) {
    fetchSummaryData(paymentPage.dataset.uuid).then(renderSummary);

    // Показ ошибки, пришедшей из payment.
    const url = new URL(window.location.href);
    const errorMessage = url.searchParams.get("error");
    if (errorMessage) {
        // displayToast({
        //     message: errorMessage
        // });

        url.searchParams.delete("error");
        history.replaceState(null, null, url.toString());
    }
}
