import { CartStorage } from "./storage.js";

import "./add-to-cart.js";

// Синхронизация счётчика товаров с реальным количеством товаров в localStorage.
const cart = new CartStorage();
cart.syncWithCounter();

// for test purposes
window.CartStorage = CartStorage;
