import "./product-gallery.pcss";

const productGalleries = document.querySelectorAll(".product-gallery");
productGalleries.forEach(block => {
    const swiperRoot = block.querySelector(".product-gallery__swiper");
    const previewSwiperRoot = block.querySelector(".product-gallery__preview-swiper");

    const loadSwiper = import(/* webpackChunkName: "swiper" */ "components/swiper/swiper.js");
    const loadFancybox = import(/* webpackChunkName: "fancybox" */ "components/fancybox/fancybox.js");
    Promise.all([loadSwiper, loadFancybox]).then(([swiper, fancybox]) => {
        const gallerySwiper = new swiper.Swiper(swiperRoot, {
            grabCursor: true,
            effect: "fade",
            modules: [swiper.EffectFade],
            speed: 500,
            longSwipesRatio: 0.1
        }).on("click", swiper => {
            const fancyboxItems = swiper.slides.map(slide => {
                return {
                    src: slide.dataset.fancyboxSrc,
                    thumb: slide.dataset.fancyboxThumb,
                    selected: slide === swiper.clickedSlide
                };
            });

            new fancybox.Fancybox(fancyboxItems, {
                startIndex: fancyboxItems.findIndex(obj => obj.selected),
                Carousel: {
                    transition: "crossfade"
                }
            });
        });

        const previewSwiper = new swiper.Swiper(previewSwiperRoot, {
            slidesPerView: "auto",
            grabCursor: true,
            speed: 500,
            modules: [swiper.FreeMode],
            freeMode: {
                enabled: true,
                momentumBounceRatio: 0.5,
                sticky: true
            }
        }).on("click", swiper => {
            if (swiper.clickedSlide) {
                swiper.slides.forEach(slide => {
                    slide.classList.toggle("active", slide === swiper.clickedSlide);
                });
                gallerySwiper.slideTo(swiper.clickedIndex);
            }
        });

        gallerySwiper.on("slideChange", swiper => {
            previewSwiper.slideTo(swiper.activeIndex);
            previewSwiper.slides.forEach((slide, index) => {
                slide.classList.toggle("active", index === swiper.activeIndex);
            });
        });
    });
});
