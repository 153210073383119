import InfiniteScroll from "infinite-scroll";
import XClass from "data-xclass";

import "./infinite-scroll.pcss";

const LINK_SELECTOR = ".infinite-scroll__link";

XClass.register("infinite-scroll", {
    init: function (element) {
        let target;
        if (typeof element.dataset.target === "undefined") {
            target = element.previousElementSibling;
        } else {
            target = document.querySelector(element.dataset.target);
        }
        if (!target) {
            throw new Error("target required");
        }

        const append = element.dataset.append;
        if (typeof append === "undefined") {
            throw new Error("Option required: append");
        }

        const buttonMode = !element.hidden;

        const options = {
            path: function () {
                const currentLink = element.querySelector(LINK_SELECTOR);
                return currentLink && currentLink.href;
            },
            append: append,
            checkLastPage: LINK_SELECTOR,
            history: false
        };

        if (buttonMode) {
            options.scrollThreshold = false;
        }

        const infScroll = new InfiniteScroll(target, options);

        // Перенос адреса со ссылки на новой странице на ссылку в документе.
        infScroll.on("load", function (body) {
            const currentLink = element.querySelector(LINK_SELECTOR);
            const nextLink = body.querySelector(LINK_SELECTOR);
            if (nextLink) {
                currentLink.href = nextLink.href;
            } else {
                currentLink.hidden = true;
            }
        });

        if (buttonMode) {
            infScroll
                .on("request", function () {
                    const currentLink = element.querySelector(LINK_SELECTOR);
                    if (currentLink && currentLink.tagName === "BUTTON") {
                        currentLink.disabled = true;
                    }
                })
                .on("load", function () {
                    const currentLink = element.querySelector(LINK_SELECTOR);
                    if (currentLink && currentLink.tagName === "BUTTON") {
                        currentLink.disabled = false;
                    }
                })
                .on("error", function () {
                    const currentLink = element.querySelector(LINK_SELECTOR);
                    if (currentLink && currentLink.tagName === "BUTTON") {
                        currentLink.disabled = false;
                    }
                })
                .on("last", function () {
                    element.hidden = true;
                });

            element.addEventListener("click", event => {
                if (event.target.closest(LINK_SELECTOR)) {
                    event.preventDefault();
                    event.stopPropagation();

                    infScroll.loadNextPage();
                }
            });
        }
    }
});
