import EventEmitter from "eventemitter3";
import XClass from "data-xclass";
import subscribe from "subscribe-event";
import { displayToast } from "components/toast/toast.js";
import { setValue as setCounterValue } from "bem/common.blocks/cart-counter/cart-counter.js";
import * as api from "./api.js";

import "./add-to-cart.pcss";

/**
 * При быстром добавлении большого количества товаров в корзину
 * (или при медленном соединении) есть проблема, связанная
 * с отображением количества товаров в корзине.
 *
 * Предположим, что пользователь нажал на кнопку добавления товара
 * три раза. Но из-за сетевых задержек результат параллельного
 * выполнения запросов может оказаться неверен:
 *
 *    ┌───────────────────────────┐   ┌────────────────────┐  ┌───┐
 * ───┤ addToCart()               ├───┤ getCartItemCount() ├──┤ 3 │
 *    └───────────────────────────┘   └────────────────────┘  └───┘
 *      ┌──────────────────┐   ┌───────────────────────┐  ┌───┐
 * ─────┤ addToCart()      ├───┤ getCartItemCount()    ├──┤ 2 │
 *      └──────────────────┘   └───────────────────────┘  └───┘
 *        ┌─────────────┐    ┌───────────────────────────────┐  ┌───┐
 * ───────┤ addToCart() ├────┤ getCartItemCount()            ├──┤ 2 │
 *        └─────────────┘    └───────────────────────────────┘  └───┘
 *
 * В данной ситуации счётчик отобразит сначала цифру 2, потом цифру 3,
 * а потом снова 2.
 *
 * С помощью ссылки `backendPromise` мы заставим запросы к бэкенду
 * выполняться строго последовательно.
 *
 * @type {Promise<number>}
 */
let backendPromise = Promise.resolve();

/**
 * Обновление счётчика должно происходить после того, как завершились
 * все текущие запросы и анимации. Для отслеживания этого события будет
 * использоваться промис `totalPromise`.
 *
 * @type {Promise<void>}
 */
let totalPromise = Promise.resolve();

/**
 * Анимация полёта иконки от точки A к B через точку C.
 *
 * Пусть AB - прямая от точки клика до иконки корзины в шапке.
 * Выберем угол alpha от 10 до 90 градусов, на который отклонимся
 * от вектора AB. Выбрав требуемый радиус (длину AC1 или AC2),
 * получим пару точек С1 и C2, из которых выберем случайную.
 *
 *         C1
 *         |
 * A ---------------------- B
 *        |
 *        C2
 *
 * @param {HTMLElement} icon
 * @return {Promise<void>}
 */
class FlyingIcon extends EventEmitter {
    get Defaults() {
        return {
            targetSelector: ".cart-counter",
            iconOffset: [-4, -4],
            iconClasses: ["flying-icon"],
            iconVisibleClass: "flying-icon--visible",
            minAngle: 10, // Мин. угол отклонения от AB для точки C.
            maxAngle: 90, // Макс. угол отклонения от AB для точки C.
            offsetDistance: 150, // Раcстояние AC.
            offsetDuration: 600, // Время полёта из A в C.
            animationSpeed: 1200, // Скорость полёта (px/sec).
            opacityTransitionDuration: 200 // Время анимации прозрачности иконки.
        };
    }

    constructor(event, options) {
        super();
        this.event = event;
        this.options = Object.assign({}, this.Defaults, options);

        this.createAnimation();
    }

    /**
     * Возвращает координаты точки начала полёта.
     *
     * @return {number[]}
     */
    get pointA() {
        return [this.event.pageX, this.event.pageY];
    }

    /**
     * Возвращает координаты точки окончания полёта.
     *
     * @return {number[]}
     */
    get pointB() {
        const targetBox = Array.from(document.querySelectorAll(this.options.targetSelector))
            .map(target => {
                return target.getBoundingClientRect();
            })
            .find(targetBox => {
                return targetBox.width > 0;
            });

        if (!targetBox) {
            throw new Error("target not found");
        }

        const bX = targetBox.left + window.scrollX + this.options.iconOffset[0];
        const bY = targetBox.top + window.scrollY + this.options.iconOffset[1];
        return [bX, bY];
    }

    /**
     * Возвращает промис, который разрешится после окончания анимации.
     *
     * @return {Promise<void>}
     */
    getPromise() {
        return this.promise;
    }

    /**
     * Создание иконки, которая будет анимироваться.
     *
     * @return {HTMLDivElement}
     */
    createIcon() {
        const icon = document.createElement("div");
        icon.insertAdjacentHTML("afterbegin", '<svg viewBox="0 0 20 20"><use href="#cart"></use></svg>');
        icon.classList.add(...this.options.iconClasses);
        const [aX, aY] = this.pointA;
        icon.style.left = `${aX}px`;
        icon.style.top = `${aY}px`;
        document.body.append(icon);

        requestAnimationFrame(() => {
            icon.classList.add(this.options.iconVisibleClass);
        });
        return icon;
    }

    /**
     * Создание объектов анимации.
     */
    createAnimation() {
        const [aX, aY] = this.pointA;
        const [bX, bY] = this.pointB;

        // Вычисление смещения точки B относительно A.
        const dxAB = bX - aX;
        const dyAB = bY - aY;

        // Если расстояние AB короткое - анимации не будет.
        const distance = Math.sqrt(dxAB ** 2 + dyAB ** 2);
        if (distance < 180) {
            throw new Error("target too close");
        }

        // Угол вектора AB в радианах.
        const angleAB = Math.atan2(dyAB, dxAB);

        // Угол между AC (AC1 или AC2) и AB в радианах.
        const angle = this.options.minAngle + Math.random() * (this.options.maxAngle - this.options.minAngle);
        const angleBAC = angle * (Math.PI / 180);

        // Выбираем случайным образом одну из двух точек (C1 или C2)
        // и вычисляем её поляный угол.
        const angleAC = Math.random() >= 0.5 ? angleAB + angleBAC : angleAB - angleBAC;

        // Координаты точки C относительно точки A.
        const distanceAC = this.options.offsetDistance;
        const dxAC = distanceAC * Math.cos(angleAC);
        const dyAC = distanceAC * Math.sin(angleAC);

        this.icon = this.createIcon();

        // Анимация отклонения к точке C.
        this.animationA = this.icon.animate([{ transform: `translate(${dxAC}px, ${dyAC}px)` }], {
            duration: this.options.offsetDuration,
            fill: "forwards",
            easing: "ease-out"
        });
        this.animationA.persist();

        // Анимация перемещения к финишу.
        // Когда кнопка находится далеко от целевой точки, анимация B
        // получается слишком быстрой. Поэтому для анимации B мы фиксируем
        // не время, а примерную скорость полёта иконки.
        const durationB = (distance / this.options.animationSpeed) * 1000;
        this.animationB = this.icon.animate([{ transform: `translate(${dxAB - dxAC}px, ${dyAB - dyAC}px)` }], {
            duration: durationB,
            fill: "forwards",
            easing: "ease-in",
            composite: "add"
        });
        this.animationB.pause();

        // Запуск анимации B на середине анимации A.
        const startTimeB = this.options.offsetDuration * 0.5;
        this.animationBTimer = setTimeout(() => {
            this.animationB.play();
        }, startTimeB);

        // Скрываем иконку на подлёте к цели.
        this.hideIconTimer = setTimeout(
            () => {
                this.icon.classList.remove(this.options.iconVisibleClass);
            },
            startTimeB + durationB - (this.options.opacityTransitionDuration * 3) / 4
        );

        // Промис всей анимации.
        this.promise = new Promise((resolve, reject) => {
            this.finishTimer = setTimeout(
                () => {
                    resolve();
                },
                startTimeB + durationB + this.options.opacityTransitionDuration / 4
            );

            // Если анимация была прервана, промис тоже прерывается.
            this.on("abort", () => reject());
        }).finally(() => {
            // Удаляем иконку после того, как она исчезнет.
            this.icon.remove();
            this.icon = null;
        });
    }

    /**
     * Прерывание анимации.
     */
    abort() {
        this.animationA.cancel();
        this.animationB.cancel();
        clearTimeout(this.animationBTimer);
        clearTimeout(this.hideIconTimer);
        clearTimeout(this.finishTimer);
        this.emit("abort");

        if (this.icon) {
            this.icon.classList.remove(this.options.iconVisibleClass);
            this.icon.remove();
            this.icon = null;
        }
    }
}

class AddToCart {
    constructor(event) {
        this.event = event;
        this.token = event.currentTarget.dataset.token;
        this.quantity = event.currentTarget.dataset.quantity;
    }

    start() {
        let animation;

        try {
            animation = new FlyingIcon(this.event);
        } catch (err) {
            animation = null;
        }

        // Создание такой цепочки промисов, что неважно, как завершился
        // промис backendPromise - внутренний промис будет выполнен
        // строго после него. При этом результатом выполнения цепочки
        // будет резльтат внутреннего промиса (чего не было бы при
        // использовании простого finally()).
        backendPromise = Promise.allSettled([backendPromise]).then(() => {
            return this.createBackendRequests();
        });

        // Если запросы к бэкенду провалятся, нам нужен таймер,
        // который будет ожидать некоторое время прежде чем
        // прервать анимацию.
        const minAnimationTimePromise = new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, 500);
        });

        // Если бэкенд был успешен, новый промис сразу вернёт результат.
        // Но если бэкенд зафейлился, мы дождёмся завершения таймера,
        // после чего прокинем ошибку
        const backendTimerPromise = backendPromise.catch(exception => {
            return minAnimationTimePromise.then(() => {
                animation?.abort();
                displayToast({
                    message: exception.message,
                    code: exception.code
                });
                throw exception;
            });
        });

        const promises = [totalPromise, backendTimerPromise];

        if (animation !== null) {
            promises.push(animation.getPromise());
        }

        // Обновляем счётчик, когда все текущие запросы и анимации завершились.
        totalPromise = Promise.allSettled(promises).then(([_, backendResult]) => {
            if (backendResult.status === "fulfilled") {
                setCounterValue(backendResult.value);
            }
        });
    }

    /**
     * Создание промиса обращения к бэкенду.
     *
     * @return {Promise<number>}
     */
    createBackendRequests() {
        let quantity;
        let quantityField = document.querySelector(this.quantity);
        if (quantityField) {
            // data-quantity хранит селектор поля.
            quantity = quantityField.value;
        } else {
            // data-quantity хранит значение.
            quantity = parseInt(this.quantity);
        }

        return api.addToCart(this.token, quantity).then(() => {
            if (quantityField) {
                quantityField.value = 1;
                quantityField.dispatchEvent(
                    new CustomEvent("input", {
                        bubbles: true,
                        cancelable: true
                    })
                );
            }
            return api.getCartItemCount();
        });
    }
}

XClass.register("add-to-cart", {
    init: function (element) {
        element.__unsubCartCounter = subscribe(element, "click", event => {
            const instance = new AddToCart(event);
            instance.start();
        });
    },
    destroy: function (element) {
        if (element.__unsubCartCounter) {
            element.__unsubCartCounter();
            element.__unsubCartCounter = null;
        }
    }
});
