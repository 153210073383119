import XClass from "data-xclass";
import { throttle } from "lodash-es";
import { BaseComponent } from "js/abstracts/baseComponent.js";

import "./accordion.pcss";

class Accordion extends BaseComponent {
    get Defaults() {
        return {
            exclusiveAttribute: "data-accordion-exclusive",
            sectionSelector: "[data-accordion-section]",
            activeSectionClass: "accordion-active",
            toggleSelector: "[data-accordion-toggle]",
            contentSelector: "[data-accordion-content]"
        };
    }

    constructor(root, options) {
        super(options);

        this.root = root;
        this.exclusive = root.hasAttribute(this.options.exclusiveAttribute);
        this.on(this.root, "click", this.onClick.bind(this));
        this.updateActivePanels();

        window.addEventListener(
            "resize",
            throttle(() => {
                this.updateActivePanels();
            }, 100)
        );
    }

    /**
     * Получение всез секций аккордиона.
     *
     * @return {HTMLElement[]}
     */
    getSections() {
        return Array.from(this.root.querySelectorAll(this.options.sectionSelector));
    }

    /**
     * Метод проверки, раскрыт ли контент указанной секции.
     *
     * @param {HTMLElement} section
     * @return {boolean}
     */
    isActiveSection(section) {
        return section.classList.contains(this.options.activeSectionClass);
    }

    /**
     * Раскрытие контента указанной секции.
     *
     * @param {HTMLElement} section
     */
    openSection(section) {
        const content = section.querySelector(this.options.contentSelector);
        content.style.maxHeight = content.scrollHeight + "px";
        section.classList.add(this.options.activeSectionClass);

        section.dispatchEvent(new CustomEvent("accordion.open"), {
            bubbles: true
        });
    }

    /**
     * Закрытие контента указанной секции.
     *
     * @param {HTMLElement} section
     */
    closeSection(section) {
        const content = section.querySelector(this.options.contentSelector);
        content.style.maxHeight = null;
        section.classList.remove(this.options.activeSectionClass);

        section.dispatchEvent(new CustomEvent("accordion.close"), {
            bubbles: true
        });
    }

    onClick(event) {
        const toggleElement = event.target.closest(this.options.toggleSelector);
        if (!toggleElement) {
            return;
        }

        const section = toggleElement.closest(this.options.sectionSelector);
        if (!section) {
            return;
        }

        if (this.isActiveSection(section)) {
            this.closeSection(section);
        } else {
            if (this.exclusive) {
                this.getSections().forEach(otherSection => {
                    if (otherSection !== section) {
                        this.closeSection(otherSection);
                    }
                });
            }

            this.openSection(section);
        }
    }

    updateActivePanels() {
        const activeSections = Array.from(this.root.querySelectorAll(`.${this.options.activeSectionClass}`));

        const activeContentElements = activeSections
            .map(section => {
                return section.querySelector(this.options.contentSelector);
            })
            .filter(Boolean);

        const activeContentScrollHeights = activeContentElements.map(body => {
            return body.scrollHeight;
        });

        window.queueMicrotask(() => {
            activeContentElements.forEach((content, index) => {
                content.style.maxHeight = activeContentScrollHeights[index] + "px";
            });
        });
    }
}

XClass.register("accordion", {
    init: function (element) {
        element._accordion = new Accordion(element);
    },
    destroy: function (element) {
        element._accordion?.destroy();
        element._accordion = null;
    }
});
