import XClass from "data-xclass";

import "./editor.pcss";
import "./editor--default.pcss";

const collageGalleries = document.querySelectorAll(".ce-collage-a, .ce-collage-b, .ce-collage-c, .ce-gallery-pair");
collageGalleries.forEach(collage => {
    collage.addEventListener("click", event => {
        const currentLink = event.target.closest("a[data-thumb]");
        if (currentLink) {
            event.preventDefault();

            const imageLinks = collage.querySelectorAll("a[data-thumb]");
            const fancyboxItems = Array.from(imageLinks).map(imageLink => {
                return {
                    src: imageLink.href,
                    thumb: imageLink.dataset.thumb,
                    selected: imageLink === currentLink
                };
            });

            import(/* webpackChunkName: "fancybox" */ "components/fancybox/fancybox.js").then(module => {
                new module.Fancybox(fancyboxItems, {
                    startIndex: fancyboxItems.findIndex(obj => obj.selected),
                    Carousel: {
                        transition: "crossfade"
                    }
                });
            });
        }
    });
});

// =================================
//  Автоматическое создание галереи
// =================================
XClass.register("editor-gallery", {
    init: function (element) {
        const block = element.closest(".ce-gallery");

        const loadSwiper = import(/* webpackChunkName: "swiper" */ "components/swiper/swiper.js");
        const loadFancybox = import(/* webpackChunkName: "fancybox" */ "components/fancybox/fancybox.js");
        Promise.all([loadSwiper, loadFancybox]).then(([swiper, fancybox]) => {
            element._swiperInstance = new swiper.Swiper(element, {
                autoHeight: true,
                observer: true,
                threshold: 10,
                spaceBetween: 20,
                longSwipesRatio: 0.1,
                modules: [swiper.Navigation, swiper.Pagination],
                navigation: {
                    prevEl: block.querySelector(".ce-gallery__button-prev"),
                    nextEl: block.querySelector(".ce-gallery__button-next")
                },
                pagination: {
                    dynamicBullets: true,
                    el: block.querySelector(".ce-gallery__pagination")
                }
            }).on("click", function (swiper, event) {
                const target = event.target;
                const item = target.closest(".ce-gallery__item");
                if (item && item.classList.contains("ce-gallery__item--image")) {
                    const imageItems = item.parentElement.querySelectorAll(".ce-gallery__item--image");
                    const fancyboxItems = Array.from(imageItems).map(imageItem => {
                        return {
                            src: imageItem.dataset.gallerySrc,
                            thumb: imageItem.dataset.galleryThumb,
                            selected: imageItem === item
                        };
                    });

                    new fancybox.Fancybox(fancyboxItems, {
                        startIndex: fancyboxItems.findIndex(obj => obj.selected),
                        Carousel: {
                            transition: "crossfade"
                        }
                    });
                }
            });
        });
    },
    destroy: function (element) {
        const swiper = element._swiperInstance;
        if (swiper) {
            swiper.destroy();
        }
    }
});
