import * as cartCounter from "bem/common.blocks/cart-counter/cart-counter.js";
import * as utils from "./utils.js";

/**
 * Класс, отвечающий за чтение и запись данных корзины в localStorage.
 */
export class CartStorage {
    static KEY = "cart";

    constructor() {
        /** @type: {Object[]} */
        this.items = [];

        this.loadData();
        this.mergeItems();
    }

    /**
     * Имя ключа localStorage, хранящего данные корзины.
     *
     * @return {string}
     */
    get key() {
        return this.constructor.KEY;
    }

    get totalQuantity() {
        let totalQuantity = 0;
        this.items.forEach(item => {
            totalQuantity += item.quantity;
        });
        return totalQuantity;
    }

    /**
     * Загрузка данных корзины из localStorage.
     */
    loadData() {
        const rawData = localStorage.getItem(this.key);
        if (rawData === null) {
            // Сброс корзины и счётчика, если в localStorage пусто.
            this.reset();
            return;
        }

        let parsedData;
        try {
            parsedData = JSON.parse(rawData);
        } catch (e) {
            // Если в localStorage записано нечто, не являющееся
            // корректым JSON, его необходимо удалить, чтобы оно
            // не мешало наполнять корзину.
            console.error("Error parsing JSON:", e.message);
            this.reset();
            return;
        }

        this.items.length = 0;
        if (Array.isArray(parsedData.items)) {
            // Переносим записи из localStorage во внутренний массив,
            // попутно форматируя их и отбрасывая некорректные.
            parsedData.items.forEach(record => {
                try {
                    const item = utils.formatCartRecord(record);
                    this.items.push(item);
                } catch (e) {
                    console.error(e.message);
                }
            });
        }
    }

    /**
     * Объединение записей корзины с идентичными токенами,
     * складывая значения поля `quantity`.
     */
    mergeItems() {
        const pkMap = new Map();

        let index = 0;
        while (index < this.items.length) {
            const item = this.items[index];
            const token = item["token"];

            if (pkMap.has(token)) {
                // Если токен уже существует, добавляем значение
                // поля quantity к существующей записи.
                pkMap.get(token).quantity += item.quantity;

                // Удаляем текущую запись из массива,
                // так как ее значения уже объединены.
                this.items.splice(index, 1);
            } else {
                // Иначе, сохраняем ссылку на текущую запись в карту.
                pkMap.set(token, item);
                index++;
            }
        }
    }

    /**
     * Если значение счётчика товаров расходится с фактическим
     * количеством товаров в корзине - значение счётчика обновляется.
     */
    syncWithCounter() {
        const totalQuantity = this.totalQuantity;
        if (totalQuantity !== cartCounter.getValue()) {
            console.info(
                `Mismatch between total quantity in the cart (${totalQuantity}) ` +
                    `and the counter value (${cartCounter.getValue()}). Updating ` +
                    `the counter value.`
            );
            cartCounter.setValue(totalQuantity);
        }
    }

    /**
     * Добавление элементав корзину.
     * Если элемент с идентичным токеном уже существует, то у него
     * просто увеличится значение quantity на величину, переданную
     * во входном объекте.
     *
     * Внимание! После вызова метода необходимо вызвать метод `save()`.
     *
     * @param {Object} record
     */
    addRecord(record) {
        const item = utils.formatCartRecord(record);
        const token = item["token"];

        // Находим индекс элемента с таким же токеном.
        const storedItemIndex = this.items.findIndex(cartItem => {
            return cartItem["token"] === token;
        });

        if (storedItemIndex >= 0) {
            const storedItem = this.items[storedItemIndex];
            storedItem.quantity += record.quantity;

            // Удаление записи, если в поле quantity получилось
            // не число, ноль или отрицательное число.
            if (typeof storedItem.quantity !== "number" || isNaN(storedItem.quantity) || storedItem.quantity <= 0) {
                this.items.splice(storedItemIndex, 1);
            }
        } else {
            this.items.push(record);
        }
    }

    /**
     * Удаление из корзины товара по его токену.
     *
     * Внимание! После вызова метода необходимо вызвать метод `save()`.
     *
     * @param {string} token
     * @return {boolean}
     */
    removeByToken(token) {
        token = utils.formatToken(token);

        // Находим индекс элемента с таким же токеном.
        const storedItemIndex = this.items.findIndex(cartItem => {
            return cartItem["token"] === token;
        });

        if (storedItemIndex >= 0) {
            this.items.splice(storedItemIndex, 1);
            return true;
        }

        return false;
    }

    /**
     * Установка количества определённого товара в корзине.
     *
     * Внимание! После вызова метода необходимо вызвать метод `save()`.
     *
     * @param {string} token
     * @param {number} quantity
     * @return {boolean}
     */
    setQuantity(token, quantity) {
        token = utils.formatToken(token);
        quantity = utils.formatQuantity(quantity);

        // Находим индекс элемента с таким же токеном.
        const storedItemIndex = this.items.findIndex(cartItem => {
            return cartItem["token"] === token;
        });

        if (storedItemIndex >= 0) {
            const storedItem = this.items[storedItemIndex];
            storedItem.quantity = quantity;
            return true;
        }

        return false;
    }

    /**
     * Сброс корзины в начальное состояние.
     * Вместе с корзиной очищается и счётчик товаров в корзине.
     */
    reset() {
        localStorage.removeItem(this.key);
        this.items.length = 0;
        cartCounter.setValue(null);
    }

    /**
     * Преобразование корзины в объект, пригодный для JSON-сериализации.
     *
     * @return {Object}
     */
    asObject() {
        return {
            items: this.items
        };
    }

    /**
     * Сохранение корзины в localStorage.
     * Этот метод не обновляет счётчик товаров по причине того,
     * что это должно происходить после анимации.
     */
    save() {
        localStorage.setItem(this.key, JSON.stringify(this.asObject()));
    }
}
