/**
 * Класс, отвечающий за чтение и запись данных купона в localStorage.
 */
export class CouponStorage {
    static KEY = "couponCode";

    /**
     * Имя ключа localStorage, хранящего данные купона.
     *
     * @return {string}
     */
    get key() {
        return this.constructor.KEY;
    }

    /**
     * Получение кода активного купона.
     *
     * @return {string|null}
     */
    get code() {
        return localStorage.getItem(this.key);
    }

    /**
     * Установка кода активного купона.
     *
     * @param {string|null} value
     */
    set code(value) {
        if (value === null) {
            localStorage.removeItem(this.key);
        } else if (typeof value === "string") {
            localStorage.setItem(this.key, value);

            // Пользовательское событие, вызываемое при установке купона.
            document.dispatchEvent(
                new CustomEvent("coupon", {
                    bubbles: true,
                    cancelable: true
                })
            );
        }
    }

    /**
     * Сброс купона.
     */
    reset() {
        this.code = null;
    }
}
