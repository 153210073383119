/**
 * Вспомогательная функция для добавления / удаления CSS-классов
 * на DOM-элемент.
 *
 * @param {HTMLElement | HTMLElement[]} target
 * @param {string | string[]} classList
 * @param {boolean} condition
 */
const _toggleClass = (target, classList, condition) => {
    if (!target || !classList) {
        return;
    }

    if (typeof classList === "string") {
        classList = classList.split(/\s+/).filter(Boolean);
    }

    classList.forEach(className => {
        if (Array.isArray(target)) {
            target.forEach(element => {
                condition ? element.classList.add(className) : element.classList.remove(className);
            });
        } else {
            condition ? target.classList.add(className) : target.classList.remove(className);
        }
    });
};

/**
 * Вспомогательная функция для добавления CSS-классов на DOM-элемент.
 *
 * @param {HTMLElement | HTMLElement[]} target
 * @param {string | string[]} classList
 */
export const addClass = (target, classList) => {
    _toggleClass(target, classList, true);
};

/**
 * Вспомогательная функция для удаления CSS-классов на DOM-элемент.
 *
 * @param {HTMLElement | HTMLElement[]} target
 * @param {string | string[]} classList
 */
export const removeClass = (target, classList) => {
    _toggleClass(target, classList, false);
};
