import EventEmitter from "eventemitter3";
import { BaseComponent } from "js/abstracts/baseComponent.js";

/**
 * Base class for tracking animations.
 */
export class TrackerBase extends BaseComponent {
    constructor(options) {
        super(options);
        this._emitter = new EventEmitter();
    }

    /**
     * Add event listener for animation completion event.
     *
     * @param {Function} listener - Function to be called when animation completes.
     */
    attachHandler(listener) {
        this._emitter.addListener("animationComplete", listener);
    }

    /**
     * Emit animation completion event.
     */
    animationComplete() {
        this._emitter.emit("animationComplete");
    }

    /**
     * Start tracking animation completion.
     *
     * @abstract
     */
    start() {}

    /**
     * Stop tracking animation completion.
     *
     * @abstract
     */
    stop() {}
}

/**
 * Tracker for CSS transitions.
 */
export class TransitionTracker extends TrackerBase {
    get Defaults() {
        return {
            getElement: function () {
                return this.popup.root;
            }
        };
    }

    start() {
        const element = this.options.getElement.call(this);

        this.on(element, "transitionend", event => {
            if (event.target === element) {
                this.animationComplete();
            }
        });
    }

    end() {
        const element = this.options.getElement.call(this);

        this.off(element, "transitionend");
    }
}

/**
 * Tracker for CSS animations.
 */
export class AnimationTracker extends TrackerBase {
    get Defaults() {
        return {
            getElement: function () {
                return this.popup.root;
            }
        };
    }

    start() {
        const element = this.options.getElement.call(this);

        this.on(element, "animationend", event => {
            if (event.target === element) {
                this.animationComplete();
            }
        });
    }

    end() {
        const element = this.options.getElement.call(this);

        this.off(element, "animationend");
    }
}

/**
 * Tracker for timeouts.
 */
export class TimeoutTracker extends TrackerBase {
    get Defaults() {
        return {
            openingTimeout: 300,
            closingTimeout: 300
        };
    }

    start(opening) {
        if (opening) {
            this._timer = setTimeout(() => {
                this.animationComplete();
            }, this.options.openingTimeout);
        } else {
            this._timer = setTimeout(() => {
                this.animationComplete();
            }, this.options.closingTimeout);
        }
    }

    end() {
        if (this._timer) {
            clearTimeout(this._timer);
            this._timer = null;
        }
    }
}
