import { CartStorage } from "./storage.js";
import { CouponStorage } from "components/coupons/storage.js";

/**
 * Добавление товара в корзину.
 *
 * @param {string} token
 * @param {number} [quantity]
 * @return {Promise<void>}
 */
export function addToCart(token, quantity) {
    quantity = quantity ? quantity : 1;

    return fetch(window.ajax_views.cart.add_product, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            token,
            quantity
        })
    })
        .then(response => {
            if (!response.ok) {
                throw new CartError(response.statusText, response.status);
            }
            return response.json();
        })
        .then(data => {
            if (data.success !== true) {
                throw new CartError(data.message, data.code);
            }

            // Добавление товара в корзину.
            const cart = new CartStorage();
            cart.addRecord(data.object);
            cart.save();
        });
}

/**
 * Получение количества товаров в корзине.
 *
 * @return {Promise<number>}
 */
export function getCartItemCount() {
    const cart = new CartStorage();
    return Promise.resolve(cart.totalQuantity);
}

/**
 * Отправляет содержимое корзины из localStorage на бэкенд.
 * Возвращает проверенные и отформатированные данные.
 *
 * @param {AbortController} controller
 * @return {Promise<object>}
 */
export function fetchValidatedCart(controller) {
    const cart = new CartStorage();
    const data = cart.asObject();

    const coupon = new CouponStorage();
    data.coupon = coupon.code;

    return fetch(window.ajax_views.cart.get_data, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data),
        signal: controller.signal
    })
        .then(response => {
            if (!response.ok) {
                throw new CartError(response.statusText, response.status);
            }
            return response.json();
        })
        .then(data => {
            if (data.success !== true) {
                throw new CartError(data.message, data.code);
            }
            return data;
        });
}

export class CartError extends Error {
    constructor(message, code) {
        super(message);
        this.name = this.constructor.name;
        this.message = message;
        this.code = code;
    }
}
