import XClass from "data-xclass";
import { RadioSelectField } from "bem/common.fields/radiobox-select-form-field/radiobox-select-form-field.js";
import { FormField } from "bem/common.fields/form-field/form-field.js";

import "./delivery-field.pcss";

class DeliveryField extends RadioSelectField {}

// ==========================================================
//  Автоматическое создание экземпляров класса DeliveryField
// ==========================================================
XClass.register("delivery-field", {
    init: function (element) {
        new DeliveryField(element, {
            listSelector: ".delivery-field__list",
            optionSelector: ".radiobox-option"
        });
    },

    destroy: function (element) {
        const fieldInstance = FormField.getInstance(element);
        if (fieldInstance) {
            fieldInstance.destroy();
        }
    }
});
