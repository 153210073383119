/*
 * Модуль, добавляющий Google reCAPTCHA к форме.
 *
 * Поддерживается два варианта капчи:
 * 1) reCAPTCHA v2 Checkbox.
 * 2) Невидимая reCAPTCHA v3.
 *
 * Чтобы применить к форме, необходимо добавить атрибут
 * `data-xclass="recaptcha-v3-form"` (для v3), либо `data-xclass="recaptcha-v2-form"`.
 *
 * reCAPTCHA v3:
 *  Токен вставляется в <input type="hidden"> с именем `captcha`. Если такого поля
 *  нет - оно будет создано автоматически. Имя поля можно изменить, указав его в артибуте
 *  `data-recaptcha-field-name`:
 *    <form data-xclass="recaptcha-v3-form" data-recaptcha-field-name="recaptcha">
 *      <input type="hidden" name="recaptcha">
 *      ...
 *    </form>
 *
 *  Т.к. токены reCAPTCHA v3 одноразовые, необходимо инвалидировать токен сразу после
 *  его использования. По умолчанию это происходит автоматически при отправке формы.
 *  Это поведение можно отключить, установив атрибут "data-recaptcha-no-invalidate".
 */

/* global grecaptcha */

import XClass from "data-xclass";
import { reCaptchaV2Form } from "js/abstracts/forms/reCAPTCHA/v2.js";
import { reCaptchaV3Form } from "js/abstracts/forms/reCAPTCHA/v3.js";

/**
 * Сброс поля reCAPTCHA.
 * Работает для любой версии reCAPTCHA.
 */
export function reset(formElement) {
    const formInstance = formElement._recaptchaForm;
    if (formInstance instanceof reCaptchaV2Form) {
        formInstance.resetField();
    } else {
        formInstance.invalidateToken();
    }
}

XClass.register("recaptcha-v3-form", {
    dependencies: ["replay-button"],
    init: async function (element) {
        element._recaptchaForm = new reCaptchaV3Form(element);
    },
    destroy: function (element) {
        if (element._recaptchaForm) {
            element._recaptchaForm.destroy();
            delete element._recaptchaForm;
        }
    }
});

XClass.register("recaptcha-v2-form", {
    init: async function (element) {
        element._recaptchaForm = new reCaptchaV2Form(element);
    },
    destroy: function (element) {
        if (element._recaptchaForm) {
            element._recaptchaForm.destroy();
            delete element._recaptchaForm;
        }
    }
});
