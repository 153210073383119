import XClass from "data-xclass";
import { FormField } from "bem/common.fields/form-field/form-field.js";

import "./product-details.pcss";

let variationsField;
const variationSelect = document.getElementById("id_variation");
if (variationSelect) {
    document.addEventListener("xclass:initialized", () => {
        initVariations();
    });
}

function initVariations() {
    const widgetRoot = XClass.findClosest(variationSelect, "select-field");
    variationsField = widgetRoot && FormField.getInstance(widgetRoot);
    if (!variationsField) {
        return;
    }

    variationSelect.addEventListener("change", event => {
        applyVariation();
    });

    applyVariation();
}

/**
 * Отображение информации о текущей вариации товара.
 */
function applyVariation() {
    const currentChoiceValue = variationsField._choices.getValue();
    const variationProperties = currentChoiceValue.customProperties;

    const priceNode = document.querySelector(".product-details__price");
    priceNode && (priceNode.textContent = variationProperties.price.repr);

    const buttonNode = document.querySelector(".product-details__button");
    buttonNode && buttonNode.setAttribute("data-token", variationProperties.token);

    const swiperNode = document.querySelector(".product-gallery__swiper");
    const swiperInstance = swiperNode && swiperNode.swiper;
    if (swiperInstance && variationProperties.gallerySlideIndex) {
        swiperInstance.slideTo(variationProperties.gallerySlideIndex - 1);
    }
}
