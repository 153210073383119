import "./coffee-page-link.pcss";

const coffeeDescriptions = document.querySelectorAll(".coffee-page-link__description");
coffeeDescriptions.forEach(description => {
    const block = description.closest(".coffee-page-link");
    const descriptionHeight = `${description.scrollHeight}px`;

    block.addEventListener("mouseenter", () => {
        description.style.height = descriptionHeight;
        description.classList.remove("coffee-page-link__description--hidden");
    });

    block.addEventListener("mouseleave", () => {
        description.style.height = "";
        description.classList.add("coffee-page-link__description--hidden");
    });
});
