import subscribe from "subscribe-event";
import EventEmitter from "eventemitter3";
import datepicker from "components/datepicker/datepicker.js";

import "./book-block.pcss";

const events = new EventEmitter();

class BookingRange {
    constructor(block, config) {
        this.block = block;
        this.config = config;

        this.checkInLabel = block.querySelector(".check-in");
        this.checkOutLabel = block.querySelector(".check-out");

        this._minDate = new Date(config["MinBookingDate"]);
        this._maxDate = new Date(config["MaxBookingDate"]);
        this.checkInDate = this.checkInMinDate;
        this.checkOutDate = this.checkOutMinDate;

        this.initFields();
    }

    set checkInDate(value) {
        if (this._checkInDate !== value) {
            this._checkInDate = value;
            events.emit("changeCheckIn");
        }
    }

    get checkInDate() {
        return this._checkInDate;
    }

    set checkOutDate(value) {
        if (this._checkOutDate !== value) {
            this._checkOutDate = value;
            events.emit("changeCheckOut");
        }
    }

    get checkOutDate() {
        return this._checkOutDate;
    }

    get checkInMinDate() {
        return this._minDate;
    }

    get checkInMaxDate() {
        return this._maxDate;
    }

    get checkOutMinDate() {
        const minDate = new Date(this.checkInDate.getTime());
        minDate.setDate(minDate.getDate() + this.config["DefaultLengthOfStay"]);
        return new Date(Math.min(minDate.getTime(), this._maxDate.getTime()));
    }

    get checkOutMaxDate() {
        const maxDate = new Date(this.checkInDate.getTime());
        maxDate.setDate(maxDate.getDate() + this.config["MaxLengthOfStay"]);
        return new Date(Math.min(maxDate.getTime(), this._maxDate.getTime()));
    }

    initFields() {
        this.checkInDatepicker = datepicker(this.checkInLabel, {
            position: "c",
            minDate: this.checkInMinDate,
            maxDate: this.checkInMaxDate,
            dateSelected: this.checkInMinDate,
            onSelect: (instance, date) => {
                if (date) {
                    this.checkInDate = date;
                }
            }
        });

        this.checkOutDatepicker = datepicker(this.checkOutLabel, {
            minDate: this.checkOutMinDate,
            maxDate: this.checkOutMaxDate,
            dateSelected: this.checkOutMinDate,
            onSelect: (instance, date) => {
                if (date) {
                    this.checkOutDate = date;
                }
            }
        });

        events.on("changeCheckIn", () => {
            this.updateCheckInLabel();
            this.checkInDatepicker.setDate(this.checkInDate, true);

            this.checkOutDatepicker.setMin();
            this.checkOutDatepicker.setMax();

            const selectedCheckOutDate = this.checkOutDatepicker.dateSelected;
            if (selectedCheckOutDate < this.checkOutMinDate || selectedCheckOutDate > this.checkOutMaxDate) {
                this.checkOutDate = this.checkOutMinDate;
            }

            this.checkOutDatepicker.setMin(this.checkOutMinDate);
            this.checkOutDatepicker.setMax(this.checkOutMaxDate);
        });

        events.on("changeCheckOut", () => {
            this.updateCheckOutLabel();
            this.checkOutDatepicker.setDate(this.checkOutDate, true);
        });
    }

    _formatDate(date) {
        const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`;
    }

    updateCheckInLabel() {
        this.checkInLabel.innerText = this._formatDate(this.checkInDate);
    }

    updateCheckOutLabel() {
        this.checkOutLabel.innerText = this._formatDate(this.checkOutDate);
    }
}

class PopupField {
    static LABEL_SELECTOR = ".label";

    constructor(block, config) {
        this.block = block;
        this.config = config;

        this.label = block.querySelector(this.constructor.LABEL_SELECTOR);
        this.popup = this.label.parentElement.querySelector(".book-popup");

        this.label.addEventListener("click", () => {
            this.showPopup();
        });
    }

    showPopup() {
        if (!this.popup.hidden) {
            return;
        }
        this.popup.hidden = false;

        setTimeout(() => {
            this._unsubscribeClick = subscribe(document, "click", event => {
                if (!this.popup.contains(event.target) && !this.label.contains(event.target)) {
                    this.hidePopup();
                }
            });
        });
    }

    hidePopup() {
        this.popup.hidden = true;
        if (this._unsubscribeClick) {
            this._unsubscribeClick();
            this._unsubscribeClick = null;
        }
    }
}

class Nights extends PopupField {
    static LABEL_SELECTOR = ".nights";

    constructor(block, config) {
        super(block, config);

        this.nightsInput = this.popup.querySelector("input");

        events.on("changeNights", () => {
            this.updateLabel();
        });

        this.initField();
    }

    get value() {
        return parseInt(this._value);
    }

    set value(value) {
        this.nightsInput.value = value;
        if (this._value !== value) {
            this._value = value;
            events.emit("changeNights");
        }
    }

    initField() {
        const min = parseInt(this.config["DefaultLengthOfStay"]) || 1;
        const max = parseInt(this.config["MaxLengthOfStay"]);

        this.nightsInput.min = min;
        this.nightsInput.max = max;
        this.value = min;

        this.nightsInput.addEventListener("input", () => {
            const value = parseInt(this.nightsInput.value);
            this.value = Math.max(min, Math.min(value, max));
        });
        this.nightsInput.addEventListener("keypress", event => {
            if (event.key === "Enter") {
                this.hidePopup();
            }
        });
    }

    updateLabel() {
        const title = this.value === 1 ? "Night" : "Nights";
        this.label.innerHTML = `${this.value} <span>${title}</span>`;
    }
}

class Rooms extends PopupField {
    static LABEL_SELECTOR = ".rooms";

    constructor(block, config) {
        super(block, config);

        this.roomsInput = this.popup.querySelector("input");

        events.on("changeRooms", () => {
            this.updateLabel();
        });

        this.initField();
    }

    get value() {
        return parseInt(this._value);
    }

    set value(value) {
        this.roomsInput.value = value;
        if (this._value !== value) {
            this._value = value;
            events.emit("changeRooms");
        }
    }

    initField() {
        const min = parseInt(this.config["DefaultRoom"]) || 1;
        const max = parseInt(this.config["MaxRooms"]);

        this.roomsInput.min = min;
        this.roomsInput.max = max;
        this.value = min;

        this.roomsInput.addEventListener("input", () => {
            const value = parseInt(this.roomsInput.value);
            this.value = Math.max(min, Math.min(value, max));
        });
        this.roomsInput.addEventListener("keypress", event => {
            if (event.key === "Enter") {
                this.hidePopup();
            }
        });
    }

    updateLabel() {
        const title = this.value === 1 ? this.config["UnitTerm"] : this.config["UnitsTerm"];
        this.label.innerHTML = `${this.value} <span>${title}</span>`;
    }
}

class People extends PopupField {
    static LABEL_SELECTOR = ".people";
    static GUEST_TYPE_MAP = {
        adults: "a06d0bae-6ece-447a-bd84-38aa3d2c8837",
        children: "ee37491f-a25e-46db-be97-1c994e78eb00",
        infant: "f26630ec-f371-4d6c-903f-64fdae6f5eaa"
    };

    constructor(block, config) {
        super(block, config);

        this.guestTypes = Object.fromEntries(
            this.config["GuestTypes"].map(obj => {
                const alias = Object.entries(this.constructor.GUEST_TYPE_MAP).find(([name, uid]) => {
                    return uid === obj["Id"];
                });
                return [alias[0], obj];
            })
        );

        this.adultsInput = this.popup.querySelector(
            `input[data-rez-id="${this.constructor.GUEST_TYPE_MAP["adults"]}"]`
        );
        this.childrenInput = this.popup.querySelector(
            `input[data-rez-id="${this.constructor.GUEST_TYPE_MAP["children"]}"]`
        );
        this.infantInput = this.popup.querySelector(
            `input[data-rez-id="${this.constructor.GUEST_TYPE_MAP["infant"]}"]`
        );

        events.on("changeAdults", () => {
            this.updateLabel();
        });

        events.on("changeChildren", () => {
            this.updateLabel();
        });

        this.initFields();
    }

    get adults() {
        return parseInt(this._adults);
    }

    set adults(value) {
        this.adultsInput.value = value;
        if (this._adults !== value) {
            this._adults = value;
            events.emit("changeAdults");
        }
    }

    get children() {
        return parseInt(this._children);
    }

    set children(value) {
        this.childrenInput.value = value;
        if (this._children !== value) {
            this._children = value;
            events.emit("changeChildren");
        }
    }

    get infant() {
        return parseInt(this._infant);
    }

    set infant(value) {
        this.infantInput.value = value;
        if (this._infant !== value) {
            this._infant = value;
            events.emit("changeInfant");
        }
    }

    initFields() {
        // adults
        const adultsConfig = this.guestTypes["adults"];
        const adultsMin = parseInt(adultsConfig["MinNumberOfGuests"]) || 0;
        const adultsMax = parseInt(adultsConfig["MaxNumberOfGuests"]);

        this.adultsInput.min = adultsMin;
        this.adultsInput.max = adultsMax;
        this.adults = adultsConfig["DefaultNumberOfGuests"];

        this.adultsInput.addEventListener("input", () => {
            const value = parseInt(this.adultsInput.value);
            this.adults = Math.max(adultsMin, Math.min(value, adultsMax));
        });
        this.adultsInput.addEventListener("keypress", event => {
            if (event.key === "Enter") {
                this.childrenInput.focus();
            }
        });

        // children
        const childrenConfig = this.guestTypes["children"];
        const childrenMin = parseInt(childrenConfig["MinNumberOfGuests"]) || 0;
        const childrenMax = parseInt(childrenConfig["MaxNumberOfGuests"]);

        this.childrenInput.min = childrenMin;
        this.childrenInput.max = childrenMax;
        this.children = childrenConfig["DefaultNumberOfGuests"];

        this.childrenInput.addEventListener("input", () => {
            const value = parseInt(this.childrenInput.value);
            this.children = Math.max(childrenMin, Math.min(value, childrenMax));
        });
        this.childrenInput.addEventListener("keypress", event => {
            if (event.key === "Enter") {
                this.infantInput.focus();
            }
        });

        // infant
        const infantConfig = this.guestTypes["infant"];
        const infantMin = parseInt(infantConfig["MinNumberOfGuests"]) || 0;
        const infantMax = parseInt(infantConfig["MaxNumberOfGuests"]);

        this.infantInput.min = infantMin;
        this.infantInput.max = infantMax;
        this.infant = infantConfig["DefaultNumberOfGuests"];

        this.infantInput.addEventListener("input", () => {
            const value = parseInt(this.infantInput.value);
            this.infant = Math.max(infantMin, Math.min(value, infantMax));
        });
        this.infantInput.addEventListener("keypress", event => {
            if (event.key === "Enter") {
                this.hidePopup();
            }
        });
    }

    updateLabel() {
        const adultTitle = this.adults === 1 ? "Adult" : "Adults";
        const childrenTitle = this.children === 1 ? "Children" : "Children";
        this.label.innerText = `${this.adults} ${adultTitle} • ${this.children} ${childrenTitle}`;
    }
}

class Booking {
    constructor(block, config) {
        this.block = block;
        this.config = config;
        this.button = block.querySelector(".book-block__button");
    }

    init() {
        this.button && this.button.classList.remove("button--disabled");
        const buttonTextElement = this.button && this.button.querySelector(".button__text");
        buttonTextElement && (buttonTextElement.innerText = "BOOK NOW");

        this.bookingRange = new BookingRange(this.block, this.config);
        this.nights = new Nights(this.block, this.config);
        this.rooms = new Rooms(this.block, this.config);
        this.people = new People(this.block, this.config);

        events.on("changeCheckIn", () => {
            let diff = this.bookingRange.checkOutDate - this.bookingRange.checkInDate;
            diff = Math.ceil(Math.abs(diff) / (1000 * 60 * 60 * 24));
            this.nights.value = diff;
            this.updateLink();
        });

        events.on("changeCheckOut", () => {
            let diff = this.bookingRange.checkOutDate - this.bookingRange.checkInDate;
            diff = Math.ceil(Math.abs(diff) / (1000 * 60 * 60 * 24));
            this.nights.value = diff;
            this.updateLink();
        });

        events.on("changeNights", () => {
            const checkOutDate = new Date(this.bookingRange.checkInDate.getTime());
            checkOutDate.setDate(checkOutDate.getDate() + this.nights.value);
            this.bookingRange.checkOutDate = checkOutDate;
            this.updateLink();
        });

        events.on("changeRooms", () => {
            this.updateLink();
        });

        events.on("changeAdults", () => {
            this.updateLink();
        });

        events.on("changeChildren", () => {
            this.updateLink();
        });

        events.on("changeInfant", () => {
            this.updateLink();
        });

        this.block.classList.add("book-block--initialized");

        this.updateLink();

        // Переключение между мобильной и десктопной версией
        const matchMedia = window.matchMedia("(min-width: 700px)");
        matchMedia.addEventListener("change", this.matchMediaHandler.bind(this));
        this.matchMediaHandler(matchMedia);
    }

    _formatDate(date) {
        const offset = date.getTimezoneOffset();
        date = new Date(date.getTime() - offset * 60 * 1000);
        return date.toISOString().split("T")[0];
    }

    updateLink() {
        let link =
            "https://guest.rezstream.com/external/frisco-inn-on-galena?" +
            "promoCode=&showRates=false&showInlineCalendars=false";

        link += `&arrival=${this._formatDate(this.bookingRange.checkInDate)}`;
        link += `&departure=${this._formatDate(this.bookingRange.checkOutDate)}`;
        link += `&rooms=${this.rooms.value}`;

        Object.entries(this.people.constructor.GUEST_TYPE_MAP).forEach(([name, uid], index) => {
            link += `&SelectedGuests[${index}].Id=${uid}`;
            link += `&SelectedGuests[${index}].NumberOfGuests=${this.people[name]}`;
        });

        this.button.href = link;
    }

    _goMobile() {
        this.bookingRange.checkInDatepicker.position = { centered: 1 };
        this.bookingRange.checkOutDatepicker.position = { centered: 1 };
        this.bookingRange.checkInDatepicker.calendarContainer.removeAttribute("style");
        this.bookingRange.checkOutDatepicker.calendarContainer.removeAttribute("style");
    }

    _goDesktop() {
        this.bookingRange.checkInDatepicker.position = { bottom: 1, left: 1 };
        this.bookingRange.checkOutDatepicker.position = { bottom: 1, left: 1 };
        this.bookingRange.checkInDatepicker.calendarContainer.classList.remove("qs-centered");
        this.bookingRange.checkOutDatepicker.calendarContainer.classList.remove("qs-centered");
    }

    matchMediaHandler(event) {
        if (event.matches) {
            this._goDesktop();
        } else {
            this._goMobile();
        }
    }
}

const block = document.querySelector(".book-block");
if (block) {
    const script = document.createElement("script");
    script.src = "/rez/options?callback=initBookBlock";
    document.head.append(script);

    window.initBookBlock = function (config) {
        const booking = new Booking(block, config);
        booking.init();
    };
}
