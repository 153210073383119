import { throttle } from "lodash-es";

import "./inner-menu.pcss";

/**
 * Поиск последнего элемента меню в каждой строке.
 * @param menu
 */
function updateInnerMenu(menu) {
    const items = menu.querySelectorAll(".inner-menu__item");
    const itemBounds = Array.from(items).map(item => {
        return item.getBoundingClientRect();
    });

    let maxTop = NaN;
    const lastItems = [];
    items.forEach((item, index) => {
        const itemTop = itemBounds[index].top;
        if (isNaN(maxTop)) {
            maxTop = itemTop;
        } else if (itemTop > maxTop) {
            lastItems.push(item.previousElementSibling);
            maxTop = itemTop;
        }
    });

    items.forEach(item => {
        item.classList.toggle("inner-menu__item--last", lastItems.includes(item));
    });
}

function updateAllInnerMenus() {
    document.querySelectorAll(".inner-menu").forEach(updateInnerMenu);
}

updateAllInnerMenus();

window.addEventListener(
    "resize",
    throttle(() => {
        updateAllInnerMenus();
    }, 100)
);
