import XClass from "data-xclass";
import { FieldOption } from "js/abstracts/fieldOption.js";

import "./checkbox-option.pcss";

class CheckboxOption extends FieldOption {
    _onSpacePressed(event) {
        this.toggle();
    }
}

// ===========================================================
//  Автоматическое создание экземпляров класса CheckboxOption
// ===========================================================
XClass.register("checkbox-option", {
    onRegister: function () {
        // наблюдатель за изменением атрибута disabled на <input> элементе
        this.observer = new MutationObserver(mutationsList => {
            for (const mutation of mutationsList) {
                if (mutation.type === "attributes") {
                    const widgetRoot = XClass.findClosest(mutation.target, "checkbox-option");
                    const optionInstance = widgetRoot && FieldOption.getInstance(widgetRoot);
                    if (optionInstance) {
                        switch (mutation.attributeName) {
                            case "disabled":
                                optionInstance._updateDisabledState();
                                break;
                            case "required":
                                optionInstance._updateRequiredState();
                                break;
                        }
                    }
                }
            }
        });
    },

    init: function (element) {
        const instance = new CheckboxOption(element, {
            controlSelector: ".checkbox-option__control",
            iconSelector: ".checkbox-option__icon",
            iconCheckedClassName: "checkbox--checked",
            iconDisabledClassName: "checkbox--disabled",
            disabledClassName: "checkbox-option--disabled",
            requiredClassName: "checkbox-option--required",
            invalidClassName: "checkbox-option--invalid"
        });

        // добавление <input> элемента в перечень отслеживания
        // изменений атрибутов disabled и required.
        this.observer.observe(instance.control, {
            attributes: true,
            attributeFilter: ["disabled", "required"]
        });
    },

    destroy: function (element) {
        const optionInstance = FieldOption.getInstance(element);
        if (optionInstance) {
            optionInstance.destroy();
        }
    }
});
