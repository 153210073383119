import "./hero.pcss";

const video = document.querySelector(".hero__video");
if (video && window.innerWidth >= 1024) {
    video.addEventListener("canplaythrough", () => {
        video.classList.add("hero__video--playing");
        video.play();
    });

    video.addEventListener("ended", () => {
        video.classList.remove("hero__video--playing");
    });
}
