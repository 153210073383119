import "./triple-cards.pcss";

const tripleCards = document.querySelector(".triple-cards");
if (tripleCards) {
    const swiperRoot = tripleCards.querySelector(".swiper");
    import(/* webpackChunkName: "swiper" */ "components/swiper/swiper.js").then(module => {
        new module.Swiper(swiperRoot, {
            grabCursor: true,
            speed: 600,
            spaceBetween: 20,
            longSwipesRatio: 0.1,
            modules: [module.FreeMode],
            breakpoints: {
                0: {
                    slidesPerView: 1.6,
                    centeredSlides: true,
                    initialSlide: 1,
                    freeMode: {
                        enabled: true,
                        momentumBounceRatio: 0.5,
                        sticky: true
                    }
                },
                640: {
                    slidesPerView: "auto",
                    centeredSlides: true,
                    initialSlide: 1,
                    freeMode: {
                        enabled: true,
                        momentumBounceRatio: 0.5,
                        sticky: true
                    }
                },
                1000: {
                    slidesPerView: 3
                }
            }
        });
    });
}
