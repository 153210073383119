import "./room-gallery.pcss";

const roomGalleries = document.querySelectorAll(".room-gallery");
roomGalleries.forEach(block => {
    const swiperRoot = block.querySelector(".swiper");

    const loadSwiper = import(/* webpackChunkName: "swiper" */ "components/swiper/swiper.js");
    const loadFancybox = import(/* webpackChunkName: "fancybox" */ "components/fancybox/fancybox.js");
    Promise.all([loadSwiper, loadFancybox]).then(([swiper, fancybox]) => {
        new swiper.Swiper(swiperRoot, {
            loop: true,
            slidesPerView: "auto",
            centeredSlides: true,
            autoHeight: true,
            grabCursor: true,
            effect: "coverflow",
            coverflowEffect: {
                rotate: 10,
                stretch: -20,
                depth: 140,
                modifier: 1,
                slideShadows: false
            },
            speed: 600,
            spaceBetween: 25,
            longSwipesRatio: 0.1,
            breakpoints: {
                600: {
                    spaceBetween: 40
                },
                800: {
                    spaceBetween: 65
                },
                1000: {
                    spaceBetween: 100
                }
            },
            modules: [swiper.Navigation, swiper.Pagination, swiper.EffectCoverflow],
            navigation: {
                prevEl: block.querySelector(".room-gallery__button-prev"),
                nextEl: block.querySelector(".room-gallery__button-next")
            },
            pagination: {
                dynamicBullets: true,
                el: block.querySelector(".room-gallery__pagination")
            }
        }).on("click", function (swiper, event) {
            const target = event.target;
            const item = target.closest(".room-gallery__item");
            if (item) {
                const imageItems = item.parentElement.querySelectorAll(".room-gallery__item");
                const fancyboxItems = Array.from(imageItems).map(imageItem => {
                    return {
                        src: imageItem.dataset.gallerySrc,
                        thumb: imageItem.dataset.galleryThumb,
                        selected: imageItem === item
                    };
                });

                new fancybox.Fancybox(fancyboxItems, {
                    startIndex: fancyboxItems.findIndex(obj => obj.selected),
                    Carousel: {
                        transition: "crossfade"
                    }
                });
            }
        });
    });
});
