import XClass from "data-xclass";
import { AjaxFormBase } from "js/abstracts/forms/ajaxFormBase.js";

import "./loyalty-card-form.pcss";

export class LoyaltyCardForm extends AjaxFormBase {
    async onBeforeSend() {
        this.getFields().forEach(field => {
            field.disabled = true;
        });

        const submitButton = this.formElement.querySelector('[type="submit"]:not([hidden])');
        if (submitButton) {
            submitButton.disabled = true;
        }
    }

    async onFinalize() {
        await super.onFinalize();

        setTimeout(() => {
            this.getFields().forEach(field => {
                field.disabled = false;
            });

            const submitButton = this.formElement.querySelector('[type="submit"]:not([hidden])');
            if (submitButton) {
                submitButton.disabled = false;
            }
        }, 500);
    }

    async onSuccess(data) {
        const overlay = this.formElement.querySelector(".form-success-overlay");
        overlay.classList.add("form-success-overlay--visible");

        setTimeout(() => {
            this.formElement.reset();
        }, 500);

        setTimeout(() => {
            overlay.classList.remove("form-success-overlay--visible");
        }, 8000);
    }
}

XClass.register("loyalty-card-form", {
    dependencies: ["csrf-form", "recaptcha-v2-form"],
    init: function (element) {
        element._loyaltyCardForm = new LoyaltyCardForm(element);
    },
    destroy: function (element) {
        if (element._loyaltyCardForm) {
            element._loyaltyCardForm.destroy();
            delete element._loyaltyCardForm;
        }
    }
});
