import "./reviews-block.pcss";

const reviewsBlocks = document.querySelectorAll(".reviews-block");
reviewsBlocks.forEach(block => {
    const swiperRoot = block.querySelector(".swiper");
    import(/* webpackChunkName: "swiper" */ "components/swiper/swiper.js").then(module => {
        new module.Swiper(swiperRoot, {
            autoHeight: true,
            grabCursor: true,
            spaceBetween: 40,
            modules: [module.Navigation],
            navigation: {
                prevEl: block.querySelector(".reviews-block__button-prev"),
                nextEl: block.querySelector(".reviews-block__button-next")
            }
        });
    });
});
