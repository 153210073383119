import subscribe from "subscribe-event";

import "./main-submenu.pcss";

const header = document.querySelector(".header");
const mainMenu = header.querySelector(".main-menu");
const menuItems = mainMenu.querySelectorAll(".main-menu__link");
let activeItem = null;
let closeSubmenuTimerHandler = null;
let headerInitialClassName = null;
let unsubscribeMouseLeave = null;

/**
 * Запланировать закрытие подменю.
 * @param {number} timeout
 */
function scheduleCloseSubmenu(timeout = 200) {
    cancelCloseSubmenu();

    if (activeItem) {
        // Сохранение ссылки на активное меню в локальной переменной.
        const item = activeItem;
        closeSubmenuTimerHandler = setTimeout(() => {
            closeSubmenu(item);
            closeSubmenuTimerHandler = null;
        }, timeout);
    }
}

/**
 * Отмена запланированного закрытия подменю.
 */
function cancelCloseSubmenu() {
    if (closeSubmenuTimerHandler) {
        clearTimeout(closeSubmenuTimerHandler);
        closeSubmenuTimerHandler = null;
    }
}

/**
 * Показ подменю указанного элемента.
 * @param {HTMLElement} item
 */
function openSubmenu(item) {
    const submenuId = item.getAttribute("aria-controls");
    if (!submenuId) {
        console.warn(`Item ${item} can't be activated`);
        return;
    }

    const submenu = document.getElementById(submenuId);
    if (!submenu) {
        console.warn(`Submenu ${submenuId} not found`);
        return;
    }

    const replace = activeItem !== null;
    if (replace) {
        if (activeItem === item) {
            return;
        }

        // Мгновенная замена одного подменю другим.
        closeSubmenu(activeItem, true);
    }

    headerInitialClassName = header.className;
    header.classList.add("header--dark");
    submenu.hidden = false;

    // Анимируем появление подменю, если оно открывается впервые.
    if (replace) {
        submenu.classList.add("main-submenu--visible");
    } else {
        requestAnimationFrame(() => {
            submenu.classList.add("main-submenu--visible");
        });
    }

    activeItem = item;

    // Допускаем кратковременный выход за пределы header
    // без закрытия подменю.
    unsubscribeMouseLeave = subscribe(header, "mouseleave", () => {
        scheduleCloseSubmenu();
    });
}

/**
 * Закрытие подменю.
 * @param {HTMLElement} item
 * @param {boolean} replace
 */
function closeSubmenu(item, replace = false) {
    const submenuId = item.getAttribute("aria-controls");
    if (!submenuId) {
        console.warn(`Item ${item} can't be activated`);
        return;
    }

    const submenu = document.getElementById(submenuId);
    if (!submenu) {
        console.warn(`Submenu ${submenuId} not found`);
        return;
    }

    header.className = headerInitialClassName;

    // Если подменю закрывается совсем - его закрытие анимируется.
    // Если одно подменю заменяется другим - закрытие происходит мгновенно.
    if (replace) {
        submenu.hidden = true;
    } else {
        submenu.addEventListener(
            "transitionend",
            () => {
                if (activeItem !== item) {
                    submenu.hidden = true;
                }
            },
            { once: true }
        );
    }
    submenu.classList.remove("main-submenu--visible");

    activeItem = null;

    if (typeof unsubscribeMouseLeave === "function") {
        unsubscribeMouseLeave();
        unsubscribeMouseLeave = null;
    }
}

menuItems.forEach(item => {
    item.addEventListener("mouseenter", event => {
        const item = event.target;
        if (item.hasAttribute("aria-controls")) {
            openSubmenu(event.target);
        } else {
            scheduleCloseSubmenu();
        }
    });

    item.addEventListener("mouseleave", event => {
        const item = event.target;
        if (!item.hasAttribute("aria-controls")) {
            cancelCloseSubmenu();
        }
    });
});

// При быстром возврате к элементу header
// отменяем запланированное закрытие подменю.
header.addEventListener("mouseenter", () => {
    cancelCloseSubmenu();
});
