import { throttle } from "lodash-es";
import { Draggable } from "js/abstracts/draggable.js";

import "./home-services-block.pcss";

window.Draggable = Draggable;

class HomeServicesMover extends Draggable {
    constructor(root, options) {
        super(root, options);

        // Смещение таблицы на момент начала движения.
        this.initialScrollLeft = null;

        // DOM-элементы
        this.table = this.root.querySelector(".home-services-block__table");
        this.scrollBar = this.root.parentElement.querySelector(".home-services-block__scrollbar");
        this.scrollBarDrag = this.scrollBar.querySelector(".home-services-block__scrollbar-drag");
    }

    get scrollLeft() {
        const offsetMatch = /\(([-.\d]+)/.exec(this.table.style.transform);
        return offsetMatch ? -parseFloat(offsetMatch[1]) : 0;
    }

    set scrollLeft(value) {
        const maxScrollLeft = this.table.scrollWidth - this.table.clientWidth;
        if (maxScrollLeft <= 0) {
            value = 0;
        } else {
            value = Math.max(0, Math.min(value, maxScrollLeft));
        }

        this.table.style.transform = `translate(${-value}px, 0px)`;
    }

    canStartDrag(dx, dy) {
        if (Math.abs(dy) > Math.abs(dx)) {
            return false;
        }
    }

    onDragStart() {
        super.onDragStart();
        this.initialScrollLeft = this.scrollLeft;
    }

    onDrag(dx, dy) {
        super.onDrag(dx, dy);

        this.scrollLeft = this.initialScrollLeft - dx;
        this.updateScrollbarPosition();
    }

    /**
     * Приведение положения скроллбара в соответствие со смещением таблицы.
     */
    updateScrollbarPosition() {
        const tableClientWidth = this.table.clientWidth;
        const tableScrollWidth = this.table.scrollWidth;
        const maxScrollLeft = tableScrollWidth - tableClientWidth;

        // Скрытие скроллбара, если элемент не скроллится по горизонтали.
        this.scrollBar.toggleAttribute("hidden", maxScrollLeft <= 0);

        // Обновление величины сдвига. Гарантирует, что она будет
        // в допустимых пределах.
        this.scrollLeft = this.scrollLeft;

        const scrollBarOffset = (100 * this.scrollLeft) / tableClientWidth;
        this.scrollBarDrag.style.width = 100 * Math.min(tableClientWidth / tableScrollWidth, 1) + "%";
        this.scrollBarDrag.style.transform = `translate(${scrollBarOffset}%, 0px)`;
    }
}

const homeServicesBlocks = document.querySelectorAll(".home-services-block");
if (homeServicesBlocks.length) {
    homeServicesBlocks.forEach(block => {
        const slider = block.querySelector(".home-services-block__slider");
        const table = slider.querySelector(".home-services-block__table");
        const services = Array.from(table.children);

        const draggable = new HomeServicesMover(slider);
        draggable.updateScrollbarPosition();
        block.__draggable = draggable;

        // анимация сервисов
        services.forEach((service, index) => {
            service.style.viewTransitionName = `service-${index}`;
        });

        // кнопки категорий
        const categoryList = block.querySelector(".home-services-block__categories");
        categoryList.addEventListener("click", event => {
            const button = event.target.closest(".home-services-block__category");
            if (!button) {
                return;
            }

            const categories = categoryList.querySelectorAll(".home-services-block__category");
            categories.forEach(categoryButton => {
                categoryButton.classList.toggle("home-services-block__category--active", button === categoryButton);
                categoryButton.classList.toggle("button--outline", button !== categoryButton);
            });

            const activeCategory = button.dataset.category || "";

            const displayCategory = () => {
                services.forEach(child => {
                    const childCategory = child.dataset.category;
                    child.toggleAttribute("hidden", activeCategory && childCategory !== activeCategory);
                });
                draggable.updateScrollbarPosition();
            };

            if (document.startViewTransition) {
                document.startViewTransition(() => displayCategory());
            } else {
                displayCategory();
            }
        });
    });

    window.addEventListener(
        "resize",
        throttle(() => {
            homeServicesBlocks.forEach(block => {
                const draggable = block.__draggable;
                draggable && draggable.updateScrollbarPosition();
            });
        }, 100)
    );
}
