import XClass from "data-xclass";
import { AjaxFormBase } from "js/abstracts/forms/ajaxFormBase.js";
import { CartStorage } from "components/cart/storage.js";
import { CouponStorage } from "components/coupons/storage.js";

import "./checkout-form.pcss";

export class CheckoutForm extends AjaxFormBase {
    constructor(formElement, options) {
        super(formElement, options);

        this._animationTimer = null;
        window.queueMicrotask(() => {
            this.syncBilingAddressState();
        });

        this.on(this.formElement, "change", () => {
            this.syncBilingAddressState();
        });
    }

    /**
     * Синхронизация состояния показа блока Billing Address
     * с состоянием чекбокса.
     */
    syncBilingAddressState() {
        const checkboxInstance = this.getFields().find(field => {
            return field.name === "shipping-billing_same";
        });
        if (!checkboxInstance) {
            console.warn("Checkbox not found");
            return;
        }

        const targetId = checkboxInstance.control.dataset.target;
        const target = targetId && document.getElementById(targetId);
        if (!target) {
            console.warn("Target not found");
            return;
        }

        if (this._animationTimer) {
            clearTimeout(this._animationTimer);
        }

        if (checkboxInstance.checked) {
            target.classList.remove("checkout-form__collapse--opening");
            target.classList.remove("checkout-form__collapse--opened");
            this._animationTimer = setTimeout(() => {
                target.hidden = true;
            }, 500);
        } else {
            target.hidden = false;
            requestAnimationFrame(() => {
                target.classList.add("checkout-form__collapse--opening");
                this._animationTimer = setTimeout(() => {
                    target.classList.remove("checkout-form__collapse--opening");
                    target.classList.add("checkout-form__collapse--opened");
                }, 500);
            });
        }
    }

    async onBeforeSend() {
        this.getFields().forEach(field => {
            field.disabled = true;
        });

        const submitButton = document.querySelector('[type="submit"][name="checkout"]');
        if (submitButton) {
            submitButton.disabled = true;
        }
    }

    async onFinalize() {
        await super.onFinalize();

        this.getFields().forEach(field => {
            field.disabled = false;
        });

        const submitButton = document.querySelector('[type="submit"][name="checkout"]');
        if (submitButton) {
            submitButton.disabled = false;
        }
    }

    async onSuccess(data) {
        // Clear cart
        const cart = new CartStorage();
        cart.reset();

        // Clear coupon
        const coupon = new CouponStorage();
        coupon.reset();

        location.href = data.successUrl;
    }
}

XClass.register("checkout-form", {
    dependencies: ["csrf-form"],
    init: function (element) {
        element._checkoutForm = new CheckoutForm(element);
    },
    destroy: function (element) {
        if (element._checkoutForm) {
            element._checkoutForm.destroy();
            delete element._checkoutForm;
        }
    }
});
