/* global Shift4 */

import XClass from "data-xclass";
import { FormBase } from "js/abstracts/forms/formBase.js";
import {
    addReplayButton,
    findReplayButton,
    isReplayEvent,
    removeReplayButton
} from "js/components/forms/replayButton.js";

import "./payment-form.pcss";

export class PaymentForm extends FormBase {
    constructor(formElement, options) {
        super(formElement, options);

        addReplayButton(this.formElement, "shift4");
        this._initShift4();
    }

    destroy() {
        super.destroy();
        removeReplayButton(this.formElement, "shift4");
    }

    /**
     * @see: https://dev.shift4.com/examples/custom-form-with-3d-secure
     * @private
     */
    _initShift4() {
        const publicKey = this.formElement.dataset.publicKey;
        if (!publicKey) {
            throw new Error("Public key required");
        }

        this.shift4 = Shift4(publicKey);
        const shift4components = this.shift4.createComponentGroup().automount(this.formElement);

        this.on(this.formElement, "submit", event => {
            if (isReplayEvent(event, "shift4")) {
                // Submit the form with its default action
                return;
            }

            // Prevent the form from submitting default action.
            event.preventDefault();

            // Hide form errors.
            this.errors = [];
            this.invalid = false;

            // Disable form submit button to prevent repeatable submits.
            disablePaymentButton();

            // Send card data to Shift4
            this.shift4
                .createToken(shift4components)
                .then(token => {
                    let paymentData;

                    try {
                        paymentData = JSON.parse(document.getElementById("payment-data").textContent);
                    } catch (e) {
                        throw e;
                    }

                    const merchantAccountProcessor = document.getElementById("merchant-account-processor-id");
                    const merchantAccountProcessorId = merchantAccountProcessor ? merchantAccountProcessor.value : null;

                    // Open frame with 3-D Secure process
                    return this.shift4
                        .verifyThreeDSecure({
                            amount: paymentData.amount,
                            currency: paymentData.currency,
                            card: token.id,
                            merchantAccountId: merchantAccountProcessorId
                        })
                        .then(() => {
                            // Append token to the form so that it will be send to server
                            const tokenField = document.createElement("input");
                            tokenField.type = "hidden";
                            tokenField.name = "tokenId";
                            tokenField.value = token.id;
                            this.formElement.append(tokenField);

                            const requireEnrolledCardField = document.createElement("input");
                            requireEnrolledCardField.type = "hidden";
                            requireEnrolledCardField.name = "requireEnrolledCard";
                            requireEnrolledCardField.value = "false";
                            this.formElement.append(requireEnrolledCardField);

                            const requireSuccessfulLiabilityShiftField = document.createElement("input");
                            requireSuccessfulLiabilityShiftField.type = "hidden";
                            requireSuccessfulLiabilityShiftField.name = "requireSuccessfulLiabilityShift";
                            requireSuccessfulLiabilityShiftField.value = "true";
                            this.formElement.append(requireSuccessfulLiabilityShiftField);

                            const merchantAccountProcessorIdField = document.createElement("input");
                            merchantAccountProcessorIdField.type = "hidden";
                            merchantAccountProcessorIdField.name = "merchantAccountProcessorId";
                            merchantAccountProcessorIdField.value = merchantAccountProcessorId;
                            this.formElement.append(merchantAccountProcessorIdField);

                            // Submit the form with its default action
                            const replayButton = findReplayButton(this.formElement, "shift4");
                            this.formElement.requestSubmit(replayButton);
                        });
                })
                .catch(error => {
                    this.invalid = true;
                    this.errors = [error.message];

                    enablePaymentButton();
                });
        });
    }
}

/**
 * Отключение кнопки отправки формы в блоке Summary.
 */
function disablePaymentButton() {
    const paymentButton = document.querySelector('[type="submit"][name="payment"]');
    paymentButton && (paymentButton.disabled = true);
}

/**
 * Включение кнопки отправки формы в блоке Summary.
 */
function enablePaymentButton() {
    const paymentButton = document.querySelector('[type="submit"][name="payment"]');
    paymentButton && (paymentButton.disabled = false);
}

XClass.register("payment-form", {
    dependencies: ["csrf-form"],
    init: function (element) {
        element._paymentForm = new PaymentForm(element);
    },
    destroy: function (element) {
        if (element._paymentForm) {
            element._paymentForm.destroy();
            delete element._paymentForm;
        }
    }
});
