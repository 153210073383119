import XClass from "data-xclass";
import { AjaxFormBase } from "js/abstracts/forms/ajaxFormBase.js";
import { CouponStorage } from "components/coupons/storage.js";
import { displayToast } from "components/toast/toast.js";

export class CouponForm extends AjaxFormBase {
    constructor(formElement, options) {
        super(formElement, options);

        this.storage = new CouponStorage();

        // Вставка промокода из localStorage в форму
        if (this.storage.code) {
            const couponField = formElement.querySelector(".coupon-code__input");
            couponField && (couponField.value = this.storage.code);
        }
    }

    async onBeforeSend() {
        this.getFields().forEach(field => {
            field.disabled = true;
        });

        const submitButton = this.formElement.querySelector('[type="submit"]:not([hidden])');
        if (submitButton) {
            submitButton.disabled = true;
        }
    }

    async onFinalize() {
        await super.onFinalize();

        this.getFields().forEach(field => {
            field.disabled = false;
        });

        const submitButton = this.formElement.querySelector('[type="submit"]:not([hidden])');
        if (submitButton) {
            submitButton.disabled = false;
        }
    }

    async onSuccess(data) {
        this.storage.code = data.code;
    }

    async onValidationError(data) {
        this.getFields().forEach(field => {
            field.invalid = true;
        });

        displayToast({
            message: data.message
        });
    }
}

XClass.register("coupon-form", {
    init: function (element) {
        element._couponForm = new CouponForm(element);
    },
    destroy: function (element) {
        if (element._couponForm) {
            element._couponForm.destroy();
            delete element._couponForm;
        }
    }
});
