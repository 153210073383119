import XClass from "data-xclass";
import { FormField } from "bem/common.fields/form-field/form-field.js";

import "./checkbox-form-field.pcss";

/**
 * Класс для поля формы с чекбоксом.
 */
export class CheckboxField extends FormField {
    get Defaults() {
        return Object.assign(super.Defaults, {
            controlSelector: "input",
            iconSelector: ".checkbox",
            iconCheckedClassName: "checkbox--checked",
            iconDisabledClassName: "checkbox--disabled"
        });
    }

    constructor(element, options) {
        super(element, options);

        this.control = this.root.querySelector(this.options.controlSelector);
        this.icon = this.root.querySelector(this.options.iconSelector);

        this._addEventListeners();
        this._updateDisabledState();
        this._updateRequiredState();
        this._updateCheckedState();
    }

    get name() {
        return this.control.name;
    }

    get value() {
        return this.control.value;
    }

    get disabled() {
        return this.control.disabled;
    }

    set disabled(value) {
        this.control.disabled = value;
    }

    get required() {
        return this.control.required;
    }

    set required(value) {
        this.control.required = value;
    }

    /**
     * Возвращает true, если чекбокс активен.
     * @returns {boolean}
     */
    get checked() {
        return this.control.checked;
    }

    /**
     * @param {boolean} value
     */
    set checked(value) {
        if (typeof value === "undefined") {
            throw new Error("value required");
        }

        if (this.disabled) {
            return;
        }

        const initialValue = this.control.checked;
        this.control.checked = Boolean(value);

        this.control.dispatchEvent(
            new Event("input", {
                bubbles: true,
                cancelable: true
            })
        );

        if (this.control.checked !== initialValue) {
            this.control.dispatchEvent(
                new Event("change", {
                    bubbles: true,
                    cancelable: true
                })
            );
        }
    }

    /**
     * Меняет состояние чекбокса (checked) на противоположное.
     */
    toggle() {
        this.checked = !this.checked;
    }

    _addEventListeners() {
        this.on(this.root, "input,form-reset", () => {
            this._updateCheckedState();
        });

        this.on(this.root, "keydown", event => {
            if (event.code === "Space") {
                this.toggle();
                event.stopPropagation();
                event.preventDefault();
            }
        });
    }

    /**
     * Синхронизация с наличием/отсутствием атрибута disabled
     * на элементе <input type="checkbox">.
     * @private
     */
    _updateDisabledState() {
        this.root.classList.toggle(this.options.disabledClassName, this.disabled);
        this.icon.classList.toggle(this.options.iconDisabledClassName, this.disabled);
    }

    /**
     * Синхронизация с наличием/отсутствием атрибута required
     * на элементе <input type="checkbox">.
     * @private
     */
    _updateRequiredState() {
        this.root.classList.toggle(this.options.requiredClassName, this.required);
    }

    /**
     * Синхронизация с наличием/отсутствием атрибута checked
     * на элементе <input type="checkbox">.
     * @private
     */
    _updateCheckedState() {
        this.icon.classList.toggle(this.options.iconCheckedClassName, this.checked);
    }
}

// ==========================================================
//  Автоматическое создание экземпляров класса CheckboxField
// ==========================================================
XClass.register("checkbox-field", {
    onRegister: function () {
        // наблюдатель за изменением атрибута disabled на <input> элементе
        this.observer = new MutationObserver(mutationsList => {
            for (const mutation of mutationsList) {
                if (mutation.type === "attributes") {
                    const widgetRoot = XClass.findClosest(mutation.target, "checkbox-field");
                    const fieldInstance = widgetRoot && FormField.getInstance(widgetRoot);
                    if (fieldInstance) {
                        switch (mutation.attributeName) {
                            case "disabled":
                                fieldInstance._updateDisabledState();
                                break;
                            case "required":
                                fieldInstance._updateRequiredState();
                                break;
                        }
                    }
                }
            }
        });
    },

    init: function (element) {
        const instance = new CheckboxField(element, {
            controlSelector: ".checkbox-form-field__control",
            iconSelector: ".checkbox-form-field__icon"
        });

        // добавление <input> элемента в перечень отслеживания
        // изменений атрибутов disabled и required.
        this.observer.observe(instance.control, {
            attributes: true,
            attributeFilter: ["disabled", "required"]
        });
    },

    destroy: function (element) {
        const fieldInstance = FormField.getInstance(element);
        if (fieldInstance) {
            fieldInstance.destroy();
        }
    }
});
