import XClass from "data-xclass";
import { FormField } from "bem/common.fields/form-field/form-field.js";

import "./numeric-form-field.pcss";

/**
 * Класс для поля формы с элементом <input type="number">.
 */
export class NumericField extends FormField {
    get Defaults() {
        return Object.assign(super.Defaults, {
            controlSelector: "input"
        });
    }

    constructor(element, options) {
        super(element, options);

        this.control = this.root.querySelector(this.options.controlSelector);

        this._addButtons();
        this._updateDisabledState();
        this._updateRequiredState();
    }

    destroy() {
        super.destroy();
        this._removeButtons();
    }

    get name() {
        return this.control.name;
    }

    get value() {
        return this.control.value;
    }

    get disabled() {
        return this.control.disabled;
    }

    set disabled(value) {
        this.control.disabled = value;
    }

    get required() {
        return this.control.required;
    }

    set required(value) {
        this.control.required = value;
    }

    _addButtons() {
        const leftButton = document.createElement("button");
        leftButton.type = "button";
        leftButton.classList.add("numeric-form-field__button", "numeric-form-field__button--left");
        this.control.insertAdjacentElement("beforebegin", leftButton);
        leftButton.insertAdjacentHTML(
            "afterbegin",
            '<svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="#151515" stroke-linecap="round" viewBox="0 0 12 12"><path d="M2 6h8"/></svg>'
        );
        leftButton.addEventListener("click", () => {
            if (this.disabled) {
                return;
            }

            const minValue = parseInt(this.control.min);
            const maxValue = parseInt(this.control.max);
            const initialValue = parseInt(this.control.value);

            let value = parseInt(this.control.value) - 1;
            if (!isNaN(minValue)) {
                value = Math.max(value, minValue);
            }
            if (!isNaN(maxValue)) {
                value = Math.min(value, maxValue);
            }

            if (initialValue === value) {
                return;
            }

            this.control.value = value;
            this.control.dispatchEvent(
                new CustomEvent("input", {
                    bubbles: true,
                    cancelable: true
                })
            );
            this.control.dispatchEvent(
                new CustomEvent("change", {
                    bubbles: true,
                    cancelable: true
                })
            );
        });

        const rightButton = document.createElement("button");
        rightButton.type = "button";
        rightButton.classList.add("numeric-form-field__button", "numeric-form-field__button--right");
        this.control.insertAdjacentElement("afterend", rightButton);
        rightButton.insertAdjacentHTML(
            "afterbegin",
            '<svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="#151515" stroke-linecap="round" viewBox="0 0 12 12"><path d="M6 2v8M2 6h8"/></svg>'
        );
        rightButton.addEventListener("click", () => {
            if (this.disabled) {
                return;
            }

            const minValue = parseInt(this.control.min);
            const maxValue = parseInt(this.control.max);
            const initialValue = parseInt(this.control.value);

            let value = parseInt(this.control.value) + 1;
            if (!isNaN(minValue)) {
                value = Math.max(value, minValue);
            }
            if (!isNaN(maxValue)) {
                value = Math.min(value, maxValue);
            }

            if (initialValue === value) {
                return;
            }

            this.control.value = value;
            this.control.dispatchEvent(
                new CustomEvent("input", {
                    bubbles: true,
                    cancelable: true
                })
            );
            this.control.dispatchEvent(
                new CustomEvent("change", {
                    bubbles: true,
                    cancelable: true
                })
            );
        });
    }

    _removeButtons() {
        const buttons = this.control.parentElement.querySelectorAll("numeric-form-field__button");
        buttons.forEach(button => {
            button.remove();
        });
    }

    /**
     * Синхронизация с наличием/отсутствием атрибута disabled
     * на элементе <input type="text">.
     * @private
     */
    _updateDisabledState() {
        this.root.classList.toggle(this.options.disabledClassName, this.disabled);
    }

    /**
     * Синхронизация с наличием/отсутствием атрибута required
     * на элементе <input type="text">.
     * @private
     */
    _updateRequiredState() {
        this.root.classList.toggle(this.options.requiredClassName, this.required);
    }
}

// =========================================================
//  Автоматическое создание экземпляров класса NumericField
// =========================================================
XClass.register("numeric-field", {
    onRegister: function () {
        // наблюдатель за изменением атрибута disabled
        this.observer = new MutationObserver(mutationsList => {
            for (const mutation of mutationsList) {
                if (mutation.type === "attributes") {
                    const widgetRoot = XClass.findClosest(mutation.target, "numeric-field");
                    const fieldInstance = widgetRoot && FormField.getInstance(widgetRoot);
                    if (fieldInstance) {
                        switch (mutation.attributeName) {
                            case "disabled":
                                fieldInstance._updateDisabledState();
                                break;
                            case "required":
                                fieldInstance._updateRequiredState();
                                break;
                        }
                    }
                }
            }
        });
    },

    init: function (element) {
        const instance = new NumericField(element, {
            controlSelector: ".numeric-form-field__control"
        });

        // добавление <input> элемента в перечень отслеживания
        // изменений атрибутов disabled и required.
        this.observer.observe(instance.control, {
            attributes: true,
            attributeFilter: ["disabled", "required"]
        });
    },

    destroy: function (element) {
        const fieldInstance = FormField.getInstance(element);
        if (fieldInstance) {
            fieldInstance.destroy();
        }
    }
});
