import "./advantages.pcss";

const advantages = document.querySelectorAll(".advantages");
advantages.forEach(block => {
    const swiperRoot = block.querySelector(".swiper");
    import(/* webpackChunkName: "swiper" */ "components/swiper/swiper.js").then(module => {
        new module.Swiper(swiperRoot, {
            loop: true,
            loopAdditionalSlides: 1,
            grabCursor: true,
            spaceBetween: 32,
            speed: 500,
            modules: [module.Navigation, module.FreeMode],
            freeMode: {
                enabled: true,
                momentumBounceRatio: 0.5,
                sticky: true
            },
            navigation: {
                prevEl: block.querySelector(".advantages__button-prev"),
                nextEl: block.querySelector(".advantages__button-next")
            },
            breakpoints: {
                800: {
                    slidesPerView: 2
                },
                1100: {
                    slidesPerView: 3
                }
            }
        });
    });
});
