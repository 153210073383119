import TabHook from "js/utilities/tabHook.js";

import "./mobile-menu.pcss";

/** @type {HTMLElement|null} */
let activeSubmenu = null;

/** @type {TabHook|null} */
let activeSubmenuHook = null;

const mobileMenu = document.querySelector(".mobile-menu");
if (mobileMenu) {
    mobileMenu.addEventListener("click", event => {
        const openSubmenuButton = event.target.closest(".mobile-menu__open-button");
        if (openSubmenuButton) {
            openSubmenu(openSubmenuButton.nextElementSibling);
            return;
        }

        const closeSubmenuButton = event.target.closest(".mobile-submenu__close-button");
        if (closeSubmenuButton) {
            closeSubmenu();
        }
    });

    document.addEventListener("keydown", event => {
        if (event.key === "Escape" || event.key === "Esc") {
            if (activeSubmenu) {
                event.preventDefault();
                event.stopPropagation();
                event.stopImmediatePropagation();
                closeSubmenu();
            }
        }
    });
}

function openSubmenu(submenu) {
    if (activeSubmenu) {
        if (activeSubmenu === submenu) {
            return;
        }

        closeSubmenu();
    }

    activeSubmenu = submenu;
    activeSubmenu.hidden = false;
    activeSubmenuHook = new TabHook(activeSubmenu);
    requestAnimationFrame(() => {
        activeSubmenu.classList.add("mobile-submenu--active");
    });
}

function closeSubmenu() {
    if (!activeSubmenu) {
        return;
    }

    activeSubmenu.classList.remove("mobile-submenu--active");
    activeSubmenu.addEventListener(
        "transitionend",
        () => {
            activeSubmenuHook.destroy();
            activeSubmenuHook = null;
            activeSubmenu.hidden = true;
            activeSubmenu = null;
        },
        { once: true }
    );
}
